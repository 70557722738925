<template>
  <div class="welcome-content-wrapper">
    <div class="welcome-content">
      <div v-if="userExists" class="user-avatar" @click="goToProfile">
        <img
          class="rounded"
          src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
          alt="user avatar"
        />
      </div>
      <div class="welcome">
        <div v-if="userExists" class="hi">Hi, {{ username }}</div>
        <div class="caption">
          Listen to your favourite songs with
          <span class="app-name">MusicApp</span>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

export default {
  name: "welcome content",
  data() {
    return {
      username: null,
    };
  },
  methods: {
    getUserDetails() {
      if (this.$store.getters["auth/user_exists"]) {
        this.username = this.$store.getters["user/user"].username;
      }
    },
    goToProfile() {
      this.$router.push({ name: "profile" });
    },

    // Send to Home.vue which is the parent component
    doAction(item) {
      this.$emit("do-action", item)
    }
  },
  computed: {
    userExists() {
      return this.$store.getters["auth/user_exists"];
    },
  },
  mounted() {
    this.getUserDetails();
  },
};
</script>

<style scoped>
.welcome-content {
  display: flex;
  gap: 1em;
  /* padding-top: 70px; */
  margin-bottom: 50px;
}

.welcome-content > * {
  width: 100%;
}

.user-avatar {
  width: 80px;
  height: 80px;
  cursor: pointer;
}

.user-avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.welcome-content .welcome {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.welcome-content .welcome {
  color: var(--color);
}

.welcome-content .welcome .hi {
  font-size: 20px;
  text-transform: uppercase;
}

.welcome-content .welcome .caption {
  font-weight: bold;
  font-size: 30px;
}

@media (max-width: 857px) {
  .welcome-content .welcome .caption {
    font-size: 24px;
  }
}

@media (max-width: 714px) {
  .welcome-content .welcome .hi {
    font-size: 16px;
  }

  .welcome-content .welcome .caption {
    font-size: 20px;
  }

  .welcome-content {
    flex-wrap: wrap;
  }
}
</style>