import { createApp } from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'animate.css';
import axios from 'axios';
import '@mdi/font/css/materialdesignicons.css';
import 'vuetify/styles';
import { createVuetify } from 'vuetify';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
import './assets/styles/global.css';
import { useMusicPlayer } from './hooks/useMusicPlayer';
//import apiServices from '@/services/apiServices'; // Import centralized API service
//import Cookies from 'js-cookie';
import { apiClient1, updateCsrfToken } from './axios-setup'

// Vuetify setup
const vuetify = createVuetify({
    components,
    directives,
});

// Initialize the app
const app = createApp(App)
    .use(store)
    .use(router)
    .use(vuetify);


app.config.globalProperties.$useMusicPlayer = useMusicPlayer;
// Make apiClient1 and axios available throughout your application
// app.config.globalProperties.$axios = axios; // Global axios might be needed for non-API requests
// app.config.globalProperties.$apiClient1 = apiClient1; // Custom API calls

app.mount("#app")
// updateCsrfToken().then(() => {
//     console.log("CSRF token ready for use.");
//     app.mount('#app');
// }).catch(error => {
//     console.error('Failed to update CSRF token:', error);
//     // store.commit("auth/SET_ERROR", error);
//     // router.push("/error")
//     // Consider redirecting the user to an error page or showing a full-screen error message =>
// });