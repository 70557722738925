import apiServices from "@/services/apiServices"

export default {
    namespaced: true,
    state: {
        musics: [],
        activeMusic: { id: 0, music: '' },
        isPlay: false,
        activePlaylist: [],
        singleSong: [],
        menuChange: "",
        singleSongStatus: false,
        activeBuffer: null,
        musicController: null,
        dataToStream: null,
    },
    mutations: {
        setMusics: (state, musics) => {
            // console.log("Set Musics");
            state.musics = musics.reverse();
            // console.log("state musics", state.musics);
        },
        setCurrentMusic: (state, newMusic) => {
            console.log("active music is: ", newMusic.name, newMusic.id);
            state.activeMusic = newMusic
        },
        updatePlayStatus: (state, status) => {
            state.isPlay = status
        },
        updatePlaylist: (state, playlist) => {
            console.log("playlists: ", playlist);
            state.activePlaylist = playlist
        },
        setMenuChange: (state, menu) => {
            // console.log("mutations=> menu: ", menu);
            state.menuChange = menu
            // console.log("set change=> menu: ", state.menuChange);
        },
        setSingleSongStatus: (state, status) => {
            state.singleSongStatus = status;
        },
        setSingleSong: (state, song) => {
            state.singleSong = song;
        },
        setActiveBuffer: (state, buffer) => {
            state.activeBuffer = buffer
        },
        setMusicController: (state, controller) => (state.musicController = controller),
        setDataToStream: (state, data) => (state.dataToStream = data),
    },
    actions: {
        async getMusicList({ commit }) {
            const songs = await apiServices.get("/song/songs");

            if (songs.data != null) {
                for (let i = 0; i < songs.data.length; i++) {
                    if (songs.data[i].image_file != null) {
                        const byteCharacters = atob(songs.data[i].image_file);
                        const byteNumbers = new Array(byteCharacters.length);
                        for (let i = 0; i < byteCharacters.length; i++) {
                            byteNumbers[i] = byteCharacters.charCodeAt(i);
                        }
                        const byteArray = new Uint8Array(byteNumbers);
                        songs.data[i].image_file = URL.createObjectURL(new Blob([byteArray], { type: 'image/jpeg' }));
                    }
                    else {
                        songs.data[i].image_file = "https://cdn.pixabay.com/photo/2015/01/20/13/13/samsung-605439_960_720.jpg"
                    }
                }
            }
            
            commit("setMusics", songs.data)
            // console.log("fetched songs");
        },
    },
    getters: {
        musics_list: state => state.musics
    }
}