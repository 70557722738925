<template>
  <div class="expanded-page-wrapper animate__animated">
    <div class="expanded-container">
      <div class="close" @click="this.$emit('close-page')">
        <i class="bi bi-x-circle-fill"></i>
      </div>
      <div class="row">
        <div class="col-md-6">
          <img
            class="song-thumbnail"
            src="https://cdn.pixabay.com/photo/2015/01/20/13/13/samsung-605439_1280.jpg"
            alt=""
          />
        </div>
        <div class="col-md-6">
          <div class="song-details">
            <h5>{{ songName }}</h5>
            <p v-if="songArtists != ''">{{ artists }}</p>
            <p v-else>Artist</p>

            <div class="song-list">
              <MusicListItem
                v-for="song in songs"
                :key="song.id"
                :songName="song.name"
                @play-song="playSong(song.id)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MusicListItem from "./MusicListItem.vue";
import MusicPlayerBottom from "./MusicPlayerBottom.vue";

export default {
  name: "MusicPageExpanded",
  data() {
    return {
      pageShown: this.show,
      songs: [],
      isMobileView: true,
    };
  },
  props: {
    songName: {
      type: String,
      default: "Song Name",
    },
    songArtists: {
      type: String,
      default: "Artist",
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    async playSong(id) {
      await this.$store.dispatch("song/getSong", id);
    },
  },
  computed: {
    songs() {
      return this.$store.getters["song/allSongs"];
    },
    artists() {
      return this.songArtists.map((artist) => artist.name).join(",");
    },
  },
  components: {
    MusicListItem,
    MusicPlayerBottom,
  },
};
</script>

<style scoped>
.expanded-page-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  background: #89e1df;
  padding: 25px;
  height: 100vh;
}

.expanded-container {
  position: relative;
  max-width: 900px;
  margin: auto;
  border-radius: 50px;
  background: #89e1df;
  box-shadow: 20px 20px 60px #74bfbe, -20px -20px 60px #9effff;
  padding: 70px;
  margin-top: 80px;
}

.expanded-container .close {
  position: absolute;
  top: 2%;
  left: 5%;
  display: none;
}

.expanded-container .close i {
  font-size: 20px;
  color: black;
  cursor: pointer;
}

.expanded-container .song-thumbnail {
  width: 300px;
  height: 300px;
  object-fit: cover;
  border-radius: 15px;
}

.song-list {
  width: 100%;
  height: 200px;
  background: #e4ffff8f;
  border-radius: 15px;
  overflow-y: scroll;
}

.song-list::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.song-list::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
.song-list::-webkit-scrollbar-thumb {
  background: var(--dominant_color);
  border-radius: 10px;
}

/* Handle on hover */
.song-list::-webkit-scrollbar-thumb:hover {
  background: var(--support_color);
}

.expanded-page-wrapper[aria-expanded="true"] {
  opacity: 1;
  transition: opacity 0.4s ease-in-out;
}

.expanded-page-wrapper[aria-expanded="false"] {
  opacity: 0;
  transition: opacity 0.4s ease-in-out;
}

@media screen and (max-width: 992px) {
  .expanded-page-wrapper {
    padding: 10px;
  }

  .expanded-container {
    padding: 35px;
    border-radius: 25px;
  }

  .expanded-container .song-thumbnail {
    width: 100%;
    height: 250px;
  }

  .expanded-container .close {
    display: block;
  }
}
</style>