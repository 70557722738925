<template>
  <div class="search-component">
    <div class="input-field">
      <!-- <input v-model="searchQuery" @keyup.enter="search" placeholder="Search for songs, albums, artists...">
      <button @click="search">Search</button> -->
      <input v-model="searchQuery" @keyup.enter="goToResultPage" placeholder="Search for songs, albums, artists...">
      <i v-if="searchNotEmpty" class="bi bi-x-lg text-dark clickable" @click="clearSearch"></i>
      <!-- <button @click="search">Search</button> -->
    </div>

    <!-- <div class="filters">
      <button @click="toggleFilter('songs')" class="bttn">Songs</button>
      <button @click="toggleFilter('albums')" class="bttn">Albums</button>
      <button @click="toggleFilter('artists')" class="bttn">Artists</button>
      <button @click="toggleFilter('playlists')" class="bttn">Playlists</button>
    </div> -->

    <div class="search-list-div" v-if="searchResult">

      <!-- Show search query keyword -->
      <template v-if="searchResult && searchQuery.length <= 26">
        <SearchQueryComponent :query="searchQuery" @click="goToResultPage" />
      </template>

      <div v-if="searchResults && searchResults.length > 0">
        <!-- <ul>
          <li v-for="(result, index) in filteredResults" :key="index">
            {{ result.name }} ({{ result.type }})
          </li>
        </ul> -->
        <!-- <v-list v-for="result in filteredResults" :key="result.id" class="ps-4 pe-4 py-3 m-2 clickable search-list-item"
          @click="clickedListItem(result)">
          {{ result.name }} <small>({{ result.type }})</small>
        </v-list> -->
        <div v-for="result in filteredResults" :key="result.id" class="result-item">
          <template v-if="result.type === 'songs'">
            <SongComponent @click="clickedSongItem(result)" :song="result" />
          </template>
          <template v-if="result.type === 'albums'">
            <AlbumComponent @click="clickedListItem(result)" :album="result" />
          </template>
          <template v-if="result.type === 'playlists' && userExists && !adminExists && result.user_id === userId">
            <PlaylistComponent @click="clickedListItem(result)" :playlist="result" />
          </template>
          <template v-if="result.type === 'artists'">
            <ArtistComponent @click="clickedListItem(result)" :artist="result" />
          </template>
        </div>
      </div>
    </div>

  </div>

</template>

<script>
import apiServices from '@/services/apiServices';
import SongComponent from "@/components/SearchResult/SongComponent.vue"
import AlbumComponent from "@/components/SearchResult/AlbumComponent.vue"
import ArtistComponent from "@/components/SearchResult/ArtistComponent.vue"
import PlaylistComponent from "@/components/SearchResult/PlaylistComponent.vue"
import SearchQueryComponent from "@/components/SearchResult/SearchQueryComponent.vue";
import musicMixins from '@/mixins/musicMixins';


export default {
  name: "Search",
  mixins: [musicMixins],
  components: {
    SongComponent,
    AlbumComponent,
    ArtistComponent,
    PlaylistComponent,
    SearchQueryComponent,
  },
  data() {
    return {
      searchQuery: '',
      searchResults: [],
      showArtists: true,
      showPlaylists: true,
      showSongs: true,
      showAlbums: true,
      activeButton: true,
      searchResult: false,
      searchNotEmpty: false,
    }
  },
  computed: {
    // filteredResults() {
    //   if (this.currentFilter === 'all') { // if it is all then all search results will show  (unique )
    //     return this.searchResults;
    //   } else {
    //     return this.searchResults.filter(result => result.type === this.currentFilter);
    //   }
    // }

    filteredResults() {
      return this.searchResults.filter(result => {
        if (result.type === 'artists' && !this.showArtists) return false;
        if (result.type === 'playlists' && !this.showPlaylists) return false;
        if (result.type === 'songs' && !this.showSongs) return false;
        if (result.type === 'albums' && !this.showAlbums) return false;
        return true;
      });
    },

    userExists() {
      return this.$store.getters["auth/user_exists"];
    },
    adminExists() {
      return this.$store.getters["auth/admin_exists"];
    },
    userId() {
      return this.$store.getters["user/user"].id
    }

  },
  methods: {
    // async search() {
    //   if (!this.searchQuery) return;

    //   // Replace '/api/search' with the actual endpoint you have for searching
    //   // it is correct /api/v1/search/search
    //   // const response = await apiServices.get(`/search/search2`, {
    //   //   params: { query: this.searchQuery }
    //   // });

    //   const response = await apiServices.get(`/search/search2?query=${this.searchQuery}`);

    //   console.log("search resonse: ", response);
    //   this.searchResults = response.data.map(item => ({
    //     // Assuming your API returns items with a type and name, adjust as necessary
    //     name: item.name,
    //     type: item.type // 'songs', 'albums', 'artists', 'playlists'
    //   }));
    // },
    // filterResults(filter) {
    //   this.currentFilter = filter;
    // }

    // async search() {
    //   if (!this.searchQuery) return;
    //   const response = await apiServices.get(`/search/search2?query=${this.searchQuery}`);
    //   console.log("search response: ", response);
    //   this.searchResults = response.data.map(item => ({
    //     name: item.name,
    //     type: item.type
    //   }));
    // },

    songClicked() {
      // Hide the search result div
      this.searchResult = false;
    },

    clickedListItem(result) {
      // console.log("result: ", result);
      // complete the query string by replacing it with the result.name
      this.searchQuery = result.name;
      let name = null;
      // direct to search result vue
      if (result.type == "albums") {
        // go to album details page
        name = this.adminExists ? "album-details-admin" : "album-details-user"
      }
      if (result.type == "playlists") {
        // Go to playlist details page
        name = "playlists-details-user"
      }
      if (result.type == "artists") {
        // Go to artists details page
        name = this.adminExists ? "artist-details-admin" : "artists-details-user";
      }

      // Direct user to that page
      this.$router.push({
        name: name,
        params: {
          id: result.id
        }
      });
    },

    clickedSongItem(result) {
      if (this.adminExists) {
        // if admin
        this.$router.push({
          name: "all tracks details",
          params: {
            id: result.id,
          },
        });
      }
      else {
        this.selectMusic(result);
        this.setPlayList('single');
        this.songClicked();
      }
    },

    goToResultPage() {
      // Go to search result page
      if (this.searchQuery != "") {
        this.$router.push({
          name: "result",
          query: {
            search_query: this.searchQuery
          }
        });
      }
    },

    clearSearch() {
      this.searchQuery = "";
    },

    async search(query) {
      if (!query) return;
      const response = await apiServices.get(`/search/search2?query=${query}`);
      // console.log("search response: ", response);
      // this.searchResults = response.data.map(item => ({
      //   id: item.id,
      //   name: item.name,
      //   type: item.type
      // }));

      this.searchResults = response.data.map(item => {
        if (item.type == "songs") {
          return {
            id: item.id,
            name: item.name,
            type: item.type,
            artists: item.artists,
            duration: item.duration,
            genre: item.genre,
            release_date: item.release_date
          }
        }
        else if (item.type == "playlists") {
          return {
            id: item.id,
            name: item.name,
            type: item.type,
            description: item.description,
            user_id: item.user_id
          }
        }
        else if (item.type == "albums") {
          return {
            id: item.id,
            name: item.name,
            type: item.type,
            image: item.image,
            avg_rating: item.rating
          }
        }
        else if (item.type == "artists") {
          return {
            id: item.id,
            name: item.name,
            type: item.type,
            genre: item.genre,
            bio: item.bio
          }
        }
        else {
          return {
            id: item.id,
            name: item.name,
            type: item.type
          }
        }
      });
    },

    // toggleFilter(filterType) {
    //   if (filterType === 'artists') {
    //     this.showArtists = !this.showArtists;
    //   } else if (filterType === 'playlists') {
    //     this.showPlaylists = !this.showPlaylists;
    //   }
    // }

  },
  watch: {
    searchQuery(query) {
      if (query == "") {
        this.searchResult = false
        this.searchNotEmpty = false;
      }
      else {
        this.searchResult = true;
        this.searchNotEmpty = true;
      }
      this.search(query)
    },
    $route() {
      // If route changes then
      // Hide search list
      this.searchResult = false;
    }
  }
}
</script>

<style scoped>
/* .search-component {
  max-width: 500px;
  width: 100%;
} */

.search-component .input-field {
  /* Add styles here */
  display: block;
  width: 500px;
  height: 34px;
  outline: none;
  border: none;
  border-radius: 50px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  background: white;
  justify-content: space-between;
  padding-inline: 15px;
}

/* .input-field {
  position: relative;
  display: flex;
  align-items: center;
  
} */

.input-field input {
  outline: none;
  padding-inline: 10px;
  width: 100%;
}

.search-component {
  position: relative
}

.search-list-div {
  position: absolute;
  width: 100%;
  top: 110%;
  background: white;
  color: black;
  z-index: 10;
  border-radius: 10px;
  max-height: 300px;
  overflow-y: scroll;
  padding: 7px 15px;
}

.search-list-div::-webkit-scrollbar {
  display: none;
}

.search-list-item:hover {
  background-color: rgb(237, 237, 237);
  border-radius: 15px
}

@media (max-width: 580px) {
  .search-component .input-field {
    max-width: 400px;
  }
}

@media (max-width: 460px) {
  .search-component .input-field {
    max-width: 350px;
  }
}

@media (max-width: 430px) {
  .search-component .input-field {
    max-width: 300px;
  }
}
</style>