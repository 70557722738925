import ratingServices from "@/services/ratingServices";

// rating.js
export default {
    namespaced: true,
    state: {
        // rating state here
    },
    mutations: {
        // rating mutations here
    },
    actions: {
        // rating actions here
        async rateSong({ commit }, payload) {
            try {
                await ratingServices.rateSong(payload);
                return {
                    success: true,
                    message: "Thank you for rating the song"
                }
            } catch (error) {
                console.log("rateSong error: ", error);
                return {
                    success: false,
                    message: "Failed to rate the song",
                    error: error
                }
            }

        },

        async rateAlbum({ }, payload) {
            try {
                await ratingServices.rateAlbum(payload);
                return {
                    success: true,
                    message: "Thank you for rating the album"
                }
            } catch (error) {
                console.log("rateSong error: ", error);
                return {
                    success: false,
                    message: "Failed to rate the song",
                    error: error
                }
            }
        },

        async getSongRating({ commit }, songId) {
            try {
                console.log("songId: ", songId);
                let ratingData = await ratingServices.getSongRatings(songId);
                console.log(ratingData);
                return ratingData.data
            } catch (error) {
                console.log("getSongRating error: ", error);
                return null
            }
        },

        // Get number of ratings done for a particular song
        async getNumberOfRatingsForASong({ }, songId) {
            try {
                let songRatings = await ratingServices.getAllSongRatings();
                console.log("song ratings", songRatings);
                if (songRatings.data != "") {
                    // console.log(songRatings.data.filter(data => data.song_id == songId).length);
                    return songRatings.data.filter(data => data.song_id == songId).length;
                }
                else {
                    return null
                }
            } catch (error) {
                console.log("getNumberOfRatingsForASong error: ", error);
                return null
            }
        },


        async getAverageSongRating({ }, songId) {
            try {
                // console.log("songId: ", songId);
                let ratingData = await ratingServices.getAverageSongRating(songId);
                // console.log(ratingData);
                return ratingData.data
            } catch (error) {
                console.log("getSongRating error: ", error);
                return null
            }
        },

        async getAlbumAverageRating({ }, albumId) {
            try {
                console.log("albumId: ", albumId);
                let ratingData = await ratingServices.getAverageAlbumRating(albumId);
                console.log(ratingData);
                return ratingData.data
            } catch (error) {
                console.log("getAverageAlbumRating error: ", error);
                return null
            }
        },

        // Get number of ratings done for a particular album
        async getNumberOfRatingsForAnAlbum({ }, albumId) {
            try {
                let albumRating = await ratingServices.getAllAlbumRatings();
                console.log("album ratings", albumRating);
                if (albumRating.data != "") {
                    // console.log(songRatings.data.filter(data => data.song_id == songId).length);
                    return albumRating.data.filter(data => data.album_id == albumId).length;
                }
                else {
                    return null
                }
            } catch (error) {
                console.log("getNumberOfRatingsForAnAlbum error: ", error);
                return null
            }
        },

        async getAlbumRating({ }, albumId) {
            try {
                console.log("albumId: ", albumId);
                let ratingData = await ratingServices.getAlbumRatings(albumId);
                console.log(ratingData);
                return ratingData.data
            } catch (error) {
                console.log("getAlbumRating error: ", error);
                return null
            }
        }
    },
    getters: {
        // rating getters here
    }
};