<template>
    <div class="home-list" v-if="userExists && playlists != ''">
        <div class="d-flex align-items-center justify-content-between">
            <h3 class="heading-3 text-capitalize mb-5">playlists</h3>
            <v-chip class="see-all-chip" @click="$router.push({ name: 'playlists-user' })">
                see all
            </v-chip>
        </div>
        <vue-horizontal ref="horizontal" class="horizontal" :button="true">
            <div class="playlist-items" v-for="playlist in playlists" :key="playlist.id">
                <PlaylistCard :title="playlist.name" @clicked="viewDetails(playlist.id)" />
            </div>
        </vue-horizontal>
    </div>
</template>

<script>
import PlaylistCard from "@/components/Music/PlaylistCard.vue";
import VueHorizontal from 'vue-horizontal';

export default {
    name: "PlaylistsList",
    components: {
        PlaylistCard,
        VueHorizontal
    },
    data() {
        return {
            playlists: [],
        }
    },
    methods: {
        async getPlaylists() {
            if(this.$store.getters["auth/user_exists"]) {
                await this.$store.dispatch("playlist/getListOfPlayLists");
                this.playlists = this.$store.getters["playlist/all_playlists"].slice(0, 10);
            }
        },
        viewDetails(playlistId) {
            this.$router.push({
                name: "playlists-details-user",
                params: {
                    id: playlistId,
                },
            });
        },
    },
    computed: {
        userExists() {
            return this.$store.getters["auth/user_exists"];
        }
    },
    mounted() {
        this.getPlaylists();
    }
}
</script>

<style scoped>
.playlist-items > * {
    min-width: var(--list-item-width);
    margin-right: 10px;
}
</style>