// services/songService.js
import httpService from './httpService';
import apiServices from './apiServices';

const songEndpoint = '/song/songs';

export default {
    // Get all songs
    getAllSongs() {
        return httpService.get(`${songEndpoint}`);
    },

    // Get a single song by ID
    async getSongById(songId) {
        return await apiServices.get(`${songEndpoint}/${songId}`);;
    },

    // Create a new song with file
    createSongWithFile(songData) {
        return httpService.post(songEndpoint, songData);
    },

    // Update a song
    updateSong(songId, songData) {
        return httpService.put(`${songEndpoint}/${songId}`, songData);
    },

    // Delete a song
    deleteSong(songId) {
        return httpService.delete(`${songEndpoint}/${songId}`);
    },

    // Stream lyrics file of a song
    streamLyrics(songId) {
        return httpService.get(`${songEndpoint}/lyrics/${songId}`);
    },

    // Update lyrics file of a song
    updateLyrics(songId, payload) {
        return apiServices.put(`${songEndpoint}/lyrics/${songId}`, payload)
    },

    // Stream song file
    streamSongFile(songId) {
        return httpService.get(`${songEndpoint}/file/${songId}`);
    },

    // Download song file
    downloadSongFile(songId) {
        return httpService.get(`${songEndpoint}/download/${songId}`);
    },

    // Flag a song
    flagSong(payload) {
        return apiServices.post(`${songEndpoint}/${payload.id}/flag`, payload.data);
    },

    // Unflag a song
    unflagSong(songId) {
        return httpService.post(`${songEndpoint}/${songId}/unflag`);
    },

    // Search songs
    searchSongs(searchQuery) {
        return httpService.get(`${songEndpoint}/search?query=${searchQuery}`);
    },

    // Filter songs by genre
    filterSongsByGenre(genreName) {
        return httpService.get(`${songEndpoint}/genre/${genreName}`);
    },

    // Get songs by artist
    getSongsByArtist(artistName) {
        return httpService.get(`${songEndpoint}/artist/${artistName}`);
    },

    // Additional song-related API calls can be added here as needed
};