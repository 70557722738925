// services/httpService.js
import axios from 'axios';

const http = axios.create({
    baseURL: 'http://localhost:5000/api',
    withCredentials: false,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    },
});

function setAuthToken(token) {
    http.defaults.headers.common['Authorization'] = `Bearer ${token}`;
}

function removeAuthToken() {
    delete http.defaults.headers.common['Authorization'];
}

export default {
    get: http.get,
    post: http.post,
    put: http.put,
    delete: http.delete,
    setAuthToken,
    removeAuthToken,
};