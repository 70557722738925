<template>
  <div class="latest-songs-wrapper home-list">
    <h3 class="heading-3 text-capitalize mb-5">latest songs</h3>
    <div class="latest-songs-container">
      <MusicListItem v-for="(song, index) in latestSongs" :key="index" :song="song"
        @set-playlist="setPlayList('latestsongs')" @actions="doAction" />
    </div>
  </div>

</template>

<script>
import MusicListItem from "../Music/MusicListItem.vue";
import musicMixins from "@/mixins/musicMixins";

export default {
  name: "latest songs",
  mixins: [musicMixins],
  components: {
    MusicListItem,
  },
  computed: {
    latestSongs() {
      return this.getListOfLatestSongs()
    }
  },
  methods: {

    // Send to WelcomeContent.vue
    doAction(item) {
      this.$emit("do-action", item)
    }
  }
  // created() {
  //   this.getListOfMusic();
  // },
};
</script>

<style scoped>
.latest-songs-wrapper {
  width: 100%;
}

.latest-songs-container {
  display: flex;
  flex-direction: column;
  gap: 1em;
  flex-wrap: wrap;
  height: calc(300px - 2em);
  overflow: hidden;
}

.latest-songs-container:hover {
  overflow-x: scroll;
}

.latest-songs-container>* {
  width: 40%;
}

@media (max-width: 1399.98px) {
  .latest-songs-container {
    overflow: hidden;
  }

  .latest-songs-container>* {
    width: 50%;
  }
}

@media screen and (max-width: 992px) {
  .latest-songs-container>* {
    width: 70%;
  }

  .latest-songs-container {
    overflow-x: scroll;
  }
}

@media screen and (max-width: 498px) {
  .latest-songs-container>* {
    width: 100%;
  }
}
</style>