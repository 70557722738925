<template>
    <div class="playlists" v-if="!showNewPlaylistForm">
        <h5>Playlists</h5>
        <Alert :duration="alertDuration" ref="playlistAlert" @completed="onCompleted" />
        <div class="mt-4">
            <div style="max-height: 200px; min-height: 100px; overflow-y: scroll;">
                <div v-for="playlist in playlists" :key="playlist.id"
                    class="d-flex align-items-center clickable playlist-items m-1" @click="saveToPlaylist(playlist.id)">
                    <div class="d-flex align-items-center gap-3">
                        <img :src="image" class="rounded object-cover" width="50" height="50" alt="playlist thumbnail">
                        <div class="text-capitalize">{{ playlist.name }}</div>
                    </div>
                </div>
            </div>
            <div class="mt-5 d-flex justify-content-end">
                <button class="bttn dominant" @click="showNewPlaylistForm = true"><i class="bi bi-plus-lg"></i> new
                    playlist</button>
            </div>

        </div>
    </div>

    <!-- Add new playlist -->
    <div class="new-playlist" v-if="showNewPlaylistForm">
        <div>
            <Alert :duration="alertDuration" ref="alert" @completed="onCompletedAddingNewPlaylist" />

            <form @submit="addPlaylist" novalidate>
                <dl class="row">
                    <dt class="col-sm-3">title</dt>
                    <dd class="col-sm-9">
                        <div class="input-control">
                            <input id="username" class="form-control m-0"
                                :class="v$.details.name.$error ? 'error-field' : ''" type="text"
                                v-model="state.details.name" placeholder="Enter playlist title" />
                            <span class="field-error" v-if="v$.details.name.$error">
                                {{ v$.details.name.$errors[0].$message }}
                            </span>
                        </div>
                    </dd>
                </dl>

                <dl class="row">
                    <dt class="col-sm-3">description</dt>
                    <dd class="col-sm-9">
                        <div class="input-control">
                            <textarea id="username" class="form-control m-0"
                                :class="v$.details.description.$error ? 'error-field' : ''" type="text"
                                v-model="state.details.description" placeholder="Enter description" />
                            <span class="field-error" v-if="v$.details.description.$error">
                                {{ v$.details.description.$errors[0].$message }}
                            </span>
                        </div>
                    </dd>
                </dl>

                <div class="mt-5 d-flex align-items-center justify-content-end gap-1">
                    <button class="bttn" type="button" @click="showNewPlaylistForm = false">cancel</button>
                    <SubmitButton buttonText="add" :load="loadButton" />
                </div>
            </form>
        </div>
    </div>

</template>

<script>
import Alert from "@/components/Miscellaneous/Alert.vue";
import SubmitButton from "@/components/Miscellaneous/SubmitButton.vue";
import useValidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { reactive, computed } from "vue";
import songActionsMixins from "@/mixins/songActionsMixins";

export default {
    name: "save to playlist",
    components: {
        Alert,
        SubmitButton
    },
    mixins: [songActionsMixins],
    data() {
        return {
            image: "https://cdn.pixabay.com/photo/2017/11/10/04/47/image-2935360_1280.png",
            showModal: false,
            alertDuration: 3,
            loadButton: false,
            showNewPlaylistForm: false,
        }
    },
    setup() {
        const state = reactive({
            details: {
                name: "",
                description: "",
            },
        });
        const rules = computed(() => {
            return {
                details: {
                    name: { required },
                    description: { required },
                },
            };
        });

        const v$ = useValidate(rules, state);

        return { state, v$ };
    },
    methods: {
        async getPlaylists() {
            await this.$store.dispatch("playlist/getListOfPlayLists");
        },
        async saveToPlaylist(playlist_id) {
            await this.$store.dispatch(
                "playlist/getListOfSongsInPlaylist",
                playlist_id
            );
            // Songs in playlist
            let songs = this.$store.getters["playlist/all_playlist_songs"];

            let songIds = songs.map((song) => song.id)

            if (songIds.includes(this.songIdToBeSavedToPlaylist)) {
                this.$refs.playlistAlert.show("error", "song already exist in the selected playlist")
            }
            else {
                let payload = {
                    data: {
                        id: [this.songIdToBeSavedToPlaylist]
                    },
                    playlist_id: playlist_id
                }

                // Send song ids
                let response = await this.$store.dispatch(
                    "playlist/addSongToPlaylist",
                    payload
                );
                if (response.success) {
                    console.log("added song to playlist");
                    this.$refs.playlistAlert.show("success", response.message)
                } else {
                    console.log("failed to add song to playlist");
                    this.$refs.playlistAlert.show("error", response.message)

                }
            }
        },
        async addPlaylist(e) {
            e.preventDefault();
            this.v$.$validate(); // Check all input fields

            let data = {
                name: this.state.details.name,
                description: this.state.details.description,
            };

            if (!this.v$.$error) {
                this.loadButton = true;
                let response = await this.$store.dispatch("playlist/addPlayList", data);

                if (response.success) {
                    this.$refs.alert.show("success", response.message);
                    this.getPlaylists();
                    this.state.details.name = "";
                    this.state.details.description = "";
                    this.v$.$reset();
                } else {
                    this.$refs.alert.show("error", response.error);
                }
            }
        },
        onCompleted(status) {
            if (status) {
                this.$emit('added-song', '')
            }
        },
        onCompletedAddingNewPlaylist(status) {
            if(status) {
                this.showNewPlaylistForm = false
            }
        }
    },
    computed: {
        playlists() {
            return this.$store.getters["playlist/all_playlists"];
        }
    },
    async mounted() {
        await this.getPlaylists()
    },
}
</script>

<style scoped>
.playlist-items:hover {
    background-color: rgb(244, 244, 244);
}
</style>