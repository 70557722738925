<template>
    <div class="playlist-details search-item">

        <div class="d-flex gap-3">
            <div>
                <img class="search-item-image" src="https://cdn.pixabay.com/photo/2017/08/06/12/08/headphones-2591890_960_720.jpg"
                    alt="">
            </div>
            <div>
                <div class="text-truncate search-item-heading text-capitalize">
                    {{ playlist.name }}
                </div>
                <div class="d-flex align-items-center text-capitalize text-truncate" style="max-width: 500px;">
                    <span class="search-item-sub-heading">{{ playlist.type }}</span>
                    <span><i class="bi bi-dot"></i></span>
                    <span class="search-item-sub-heading" v-if="playlist.description != null">{{ playlist.description
                        }}</span>
                    <span class="search-item-sub-heading" v-else>no description</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        playlist: Object
    }
}
</script>