<template>
    <div class="album-details search-item">
        <div class="d-flex gap-3">
            <div>
                <img v-if="album.image!=null" class="search-item-image" :src="album.image" alt="">
                <img v-else class="search-item-image" src="https://cdn.pixabay.com/photo/2017/11/06/15/52/blank-vinyl-record-jacket-2924018_1280.jpg" alt="">
            </div>
            <div>
                <div class="text-truncate search-item-heading text-capitalize">
                    {{ album.name }}
                </div>
                <div class="d-flex align-items-center text-capitalize text-truncate" style="max-width: 500px;">
                    <span class="search-item-sub-heading">{{ album.type }}</span>
                    <span><i class="bi bi-dot"></i></span>
                    <span class="search-item-sub-heading" v-if="adminExists && album.genre != null">{{ album.genre }}</span>
                    <span><i class="bi bi-dot" v-if="adminExists && album.genre != null"></i></span>
                    <span class="search-item-sub-heading d-flex gap-1 align-items-center">
                        <div>
                            <v-rating v-model="rating" color="yellow-darken-3" size="x-small" density="compact"
                                half-increments readonly></v-rating>
                        </div>
                        <div>{{ rating }}/5</div>
                    </span>
                </div>
            </div>
        </div>

        <!-- <img v-if="album.image_file" :src="getImageUrl(album.image_file)" alt="Album cover"> -->
        <!-- Populate with songs and other details -->
    </div>
</template>

<script>
export default {
    props: {
        album: Object
    },
    computed: {
        rating() {
            return this.album.avg_rating == null ? 0 : this.album.avg_rating
        },
        adminExists() {
            return this.$store.getters["auth/admin_exists"]
        }
    },
    methods: {
        getImageUrl(filename) {
            return `/path/to/images/${filename}`; // Update with the correct path
        }
    }
}
</script>