// Api services
import apiServices from "@/services/apiServices";

let _path = "/artist"

// artist.js
export default {
    namespaced: true,
    state: {
        // artist state here
        artists: [],
        artist: {}
    },
    mutations: {
        // artist mutations here
        SET_ARTISTS: (state, artists) => (state.artists = artists),
        SET_ARTIST: (state, artist) => (state.artist = artist),
        ADD_ARTIST: (state, newArtist) => state.artists.push(newArtist),
        UPDATE_ARTIST: (state, artistData) => {
            const index = state.artists.findIndex(artist => artist.id === artistData.id);
            if (index !== -1) {
                state.artists.splice(index, 1, artistData)
            }
        },
        DELETE_ARTIST: (state, id) => (state.artists = state.artists.filter(artist => artist.id !== id))
    },
    actions: {
        // artist actions here

        // Get list of all artists
        async getArtists({ commit }) {
            const artists = await apiServices.get(_path + "/artists");
            commit("SET_ARTISTS", artists.data)
        },

        // Get an artisit
        async getArtist({ commit }, id) {
            const artist = await apiServices.get(_path + "/artists/" + id);
            console.log("artists: ", artist);
            commit("SET_ARTIST", artist.data)
        },

        // Get artist by name
        async getArtistByName({ commit }, name) {
            const artist = await apiServices.get(_path + "/artists/" + name);
            commit("SET_ARTIST", artist.data)
        },

        // Search artists
        async searchArtists({ commit }, query) {
            const artists = await apiServices.get(_path + "/artists/search/" + query)
            commit("SET_ARTISTS", artists.data)
        },

        // Add artist
        async addArtist({ commit }, payload) {
            try {
                const response = await apiServices.post(_path + "/artists", payload)
                commit("ADD_ARTIST", { name: payload.name, id: response.data.id })
                return {
                    success: true,
                    message: "Added artist successfully"
                }
            } catch (error) {
                console.log(error);
                return {
                    success: false,
                    message: "Internal error",
                    error: error
                }
            }
        },

        // Update artist
        async updateArtist({ commit }, payload) {

            console.log("payload: ", payload);
            try {
                await apiServices.put(_path + "/artists/" + payload.id, payload)
                commit("UPDATE_ARTIST", payload);
                return {
                    success: true,
                    message: "Updated artist successfully"
                }
            } catch (error) {
                console.log(error);
                return {
                    success: false,
                    message: "Internal error",
                    error: error
                }
            }
        },

        // Delete artist
        async deleteArtist({ commit }, id) {
            try {

                console.log(id);
                await apiServices.delete(_path + "/artists/" + id)
                commit("DELETE_ARTIST", id)
                return {
                    success: true,
                    message: "Deleted artist successfully"
                }
            } catch (error) {
                console.log(error);
                return {
                    success: false,
                    message: "Internal error",
                    error: error
                }
            }
        }
    },
    getters: {
        // artist getters here
        getAllArtists: state => state.artists,
        getArtist: state => state.artist
    }
};