<template>

  <!-- This is the main music controller -->
  <!-- Here you can see music player for both webview and mobileview -->
  <!-- when a user clicks the play button then a song will be selected and get the url of that song and it is used to play the song -->
  <!-- here we use mixins, a feature of vue to reuse functions -->
  <MusicPlayerWebView v-if="showMusicPlayer" :songTitle="songTitle" :songArtists="songArtists"
    :playingStatus="getMusicPlayingStatus" :song="activeMusic" :playlist="activePlaylist" :songProgress="songProgress"
    :songUpdatedTime="songUpdatedTime" :songDuration="songDuration" @toggle-play="playSong" @prev-song="playPrevSong"
    @next-song="playNextSong" @shuffle-song="shuffleSong" @repeat-song="repeatSong" @seek-to="seekTo"
    @change-volume="changeVolume" @song-ended="onSongEnd" :songLoaded="songLoaded" ref="musicplayerwebview" />

  <MusicPlayerMobileView v-if="showMusicPlayer" :songTitle="songTitle" :songArtists="songArtists"
    :playingStatus="getMusicPlayingStatus" :song="activeMusic" :playlist="activePlaylist" :songProgress="songProgress"
    :songUpdatedTime="songUpdatedTime" :songDuration="songDuration" @toggle-play="playSong" @prev-song="playPrevSong"
    @next-song="playNextSong" @shuffle-song="shuffleSong" @repeat-song="repeatSong" @seek-to="seekTo"
    @change-volume="changeVolume" @song-ended="onSongEnd" :songLoaded="songLoaded" ref="musicplayerwebview" />

</template>

<script>
import musicMixins from "@/mixins/musicMixins";
import MusicPlayerWebView from "./MusicPlayerWebView.vue";
import MusicPlayerMobileView from "./MusicPlayerMobileView.vue";

export default {
  name: "music player",
  mixins: [musicMixins],
  components: {
    MusicPlayerWebView,
    MusicPlayerMobileView
  },
  data() {
    /*
    Music Status Code : Meanings
    -1:do nothing!
    1:Play music
    */
    return {
      playedASong: false,
      isPlay: false,
      musicStatusCode: 0,
      shuffle: false,
      repeat: false,
      songEndedCode: 0,
      player: null,
      chunkSize: 4096
    };
  },
  methods: {
    playActiveSong() {
      // this.$useMusicPlayer().playSong(this.activeMusic.id);
      // this.$useMusicPlayer().playSong()
      // console.log("playing");
      // this.player.playSong();
      // Update play status
      // this.$store.dispatch("music/playSong", "play")
      this.getMusicController.playSong();
      this.updatePlayStatus(true);
    },
    pauseActiveSong() {
      // console.log("pausing song");
      // this.player.stopSong();
      // this.getMusicController.stopSong();
      this.getMusicController.stopSong();
      // this.$useMusicPlayer().stopSong();
      this.updatePlayStatus(false);
      // this.updatePlayStatus(false);
    },
    playSong() {
      // If the song is playing then pause and vice versa
      // console.log("playing: ", this.isPlaying)
      this.isPlaying ? this.pauseActiveSong() : this.playActiveSong();
    },
    async playPrevSong() {
      // this.pauseActiveSong();
      // // await this.$useMusicPlayer().loadNextSong();
      // this.player.prevSong();
      if (this.$useMusicPlayer().songLoaded.value) {
        if (this.$useMusicPlayer().isPlaying.value) {
          this.pauseActiveSong();
        }
        let prevSong = this.$useMusicPlayer().prevSong();
        if (prevSong == null) {
          this.pauseActiveSong();
          this.playActiveSong();
        }
        else {
          this.selectMusic(prevSong);
        }
      }
    },
    async playNextSong() {

      // this.pauseActiveSong();
      // // await this.$useMusicPlayer().loadNextSong();
      // let nextSong = this.player.nextSong();
      // this.$store.commit("music/setCurrentMusic", nextSong);
      if (this.$useMusicPlayer().songLoaded.value) {
        if (this.$useMusicPlayer().isPlaying.value) {
          this.pauseActiveSong();
        }
        let nextSong = this.$useMusicPlayer().nextSong();
        console.log("next song: ", nextSong);
        if (nextSong == null) {
          this.pauseActiveSong();
          this.playActiveSong();
        }
        else {
          this.selectMusic(nextSong);
        }
      }
    },
    shuffleSong(status) {
      // Logic: cannot enable both shuffle and repeat at the same time so if on enabling shuffle, if repeat is already enabled then disable it and viceversa
      if (this.repeat) this.repeat = false;
      this.shuffle = status;
    },
    repeatSong(status) {
      // Logic: cannot enable both shuffle and repeat at the same time so if on enabling shuffle, if repeat is already enabled then disable it and viceversa
      if (this.shuffle) this.shuffle = false;
      this.repeat = status;
    },
    seekTo(value) {
      // this.$useMusicPlayer().seekSong(value);
      this.getMusicController.seekSong(value);
    },
    changeVolume(value) {
      // this.$useMusicPlayer().adjustVolume(value);
      this.getMusicController.setVolume(value)
    },
    async onSongEnd() {
      // if (this.shuffle) {
      //   await this.$useMusicPlayer().loadRandomSong();
      //   // Set song as the active music
      //   this.setActiveMusic(this.$useMusicPlayer().currentSong.value);
      //   console.log("current song: ", this.$useMusicPlayer().currentSong.value);
      //   // Play song
      //   this.playActiveSong();
      // }
      // if (this.repeat) {
      //   // update seekStatus
      //   this.$refs.musicplayerwebview.updateSeekStatus();
      //   this.$useMusicPlayer().repeatCurrentSong();
      // }
      // if (!this.shuffle && !this.repeat) {
      //   this.playNextSong();
      // }
    },
  },
  computed: {
    songLoaded() {
      return this.$useMusicPlayer().songLoaded.value;
    },
    isPlaying() {
      return this.$useMusicPlayer().isPlaying.value;
    },
    songEnded() {
      return this.$useMusicPlayer().songEnded.value;
    },
    songTitle() {
      return this.$useMusicPlayer().songTitle.value;
    },
    songArtists() {
      return this.$useMusicPlayer().songArtists.value;
    },
    showMusicPlayer() {
      // If the user has not selected a song to play then getActiveMusic.id will be zero
      // console.log("value: ", this.getActiveMusicId > 0);
      // If checkActiveMusic > 0 then it means the user has selected a song so return true, and this displays the music player
      /**
       * ||| Logic: |||
       * No song exist with an id 0, when the app is loaded for the first time, getActiveMusic.id will be zero-
       * It means the user has not started to play a song and it changes to an actual song id when user select a song
       */
      // console.log("alert================");
      return this.getActiveMusic.id > 0;
    },
    activeMusic() {
      // Active Music is managed using computed because there are views where it is not needed to display music player-
      // On visiting those pages music player will be active but wont be visible-
      // on returning to the previous page then the active page data should not be null.
      // ** If it is managed using data return then on returning activeMusic will be null which throws error
      // console.log("active music ========================", this.getActiveMusic);
      return this.getActiveMusic.id > 0 ? this.getActiveMusic : null;
    },
    activePlaylist() {
      return this.getActivePlaylist;
    },
    songCurrentTime() {
      return this.$useMusicPlayer().songUpdatedTime.value;
    },
    songProgress() {
      // console.log("music player: ", this.$useMusicPlayer().songProgressValue.value);
      return this.$useMusicPlayer().songProgressValue.value;
    },
    songUpdatedTime() {
      let minutes =
        "0" + Math.floor(this.$useMusicPlayer().songUpdatedTime.value / 60);
      let seconds =
        "0" + Math.floor(this.$useMusicPlayer().songUpdatedTime.value - minutes * 60);
      return minutes.slice(-2) + ":" + seconds.slice(-2);
    },
    songDuration() {
      // First set the value to songDurationValue
      // this.songDurationValue = this.$useMusicPlayer().songDuration.value;
      let minutes = "0" + Math.floor(this.$useMusicPlayer().songDuration.value / 60);
      let seconds =
        "0" + Math.floor(this.$useMusicPlayer().songDuration.value - minutes * 60);
      return minutes.slice(-2) + ":" + seconds.slice(-2);
    },
  },
  watch: {
    async getActiveMusic(song) {
      // console.log("get active music", this.musicStatusCode);
      // console.log("watched change in active music");
      // if (this.player != null) this.pauseActiveSong();
      // console.log("load song: ", song.name, song.id);
      // let buffer = await this.$useMusicPlayer().loadSong(song);
      // this.player = await this.$useMusicPlayer().musicController(buffer);
      // console.log("player: ", this.player);
      // console.log("loaded song: ", this.$useMusicPlayer().currentSong.value.name, this.$useMusicPlayer().currentSong.value.id);
      // console.log("about to play song: ", song.name, song.id);
      // this.musicStatusCode = 1;
      // console.log("loaded song");
      // Play song = as soon as selecting a new song this code will run
    },
    getMusicPlayingStatus(value) {
      if (this.getMusicController != null) {
        if (value) {
          // Play song - on playing or pausing a song (not from Music Player but from other music components) or-
          // selecting a new song will run this code
          if (!this.$useMusicPlayer().isPlaying.value) {
            // this.getMusicController.playSong()
            // this.musicStatusCode = 1
            this.playActiveSong();
          }
        } else {
          this.pauseActiveSong();
          // this.getMusicController.stopSong();
        }
      }
    },
    musicStatusCode(value) {
      // change in getActiveMusic and getMusicPlayingStatus will change musicStatusCode from 0 to 1
      // two times to avoid a collision, after playing the song its value will be changed to -1. This will
      // prevent this code from running again
      if (value == 1) {
        console.log("entered music status code");
        // if (this.$useMusicPlayer().isPlaying.value) this.pauseActiveSong();
        this.playActiveSong();
        // console.log("played song");
        this.musicStatusCode = -1;
        // console.log("changed music status code");
      }
    },
    async songEnded(value) {

      // console.log("song ended value: ", value);
      // console.log("repeat: ", this.repeat);
      if (value && this.shuffle) {
        // await this.$useMusicPlayer().loadRandomSong();
        this.selectMusic(this.getMusicController.randomSong())
        // Set song as the active music
        // this.setActiveMusic(this.$useMusicPlayer().currentSong.value);
        // console.log("current song: ", this.$useMusicPlayer().currentSong.value);
        // Play song
        // this.playActiveSong();
      }
      if (value && this.repeat) {
        // update seekStatus
        // this.$refs.musicplayerwebview.updateSeekStatus();
        // this.$useMusicPlayer().repeatCurrentSong();
        this.getMusicController.repeatSong();
      }
      if (value && !this.shuffle && !this.repeat) {
        // console.log("song ended, play next song");
        this.playNextSong();
      }

    }
  },
};
</script>
<style scoped></style>