

export default {
    computed: {
        getMusics() { // get list of songs
            return this.$store.state.music.musics;
        },
        getMusicPlayingStatus() { // playing status of current music whether playing or paused
            // console.log('music playing status: ', this.$store.state.music.isPlay);
            return this.$store.state.music.isPlay
        },
        getActiveMusic() { // get music currently playing
            return this.$store.state.music.activeMusic
        },
        getActivePlaylist() { // to manage playlist3
            return this.$store.state.music.activePlaylist
        },
        getSingleSongStatus() { // get details of a single song
            return this.$store.state.music.singleSongStatus
        },
        getSingleSong() {
            // console.log("single song: ", this.$store.state.music.singleSong);
            return this.$store.state.music.singleSong
        },
        getMusicController() {
            return this.$store.state.music.musicController
        },
        getDataToStream() {
            return this.$store.state.music.dataToStream
        },
        getActiveBuffer() {
            return this.$store.state.music.activeBuffer
        }
    },
    methods: {
        getActiveMusicId() {
            return this.getActiveMusic.id;
        },
        async getListOfMusic() {
            await this.$store.dispatch("music/getMusicList");
        },
        getListOfLatestSongs() {
            // Get first 9 songs in the list
            let latestSongs = this.getMusics.slice(0, 9);
            return latestSongs;
        },
        getListOfAlbumSongs(album_id) {
            let songs = this.getMusics.filter(song => song.album_id === album_id && song.album_id != null);
            console.log("songsssssss: ", songs);
            return songs;
        },
        async getListOfSongsOfArtist(artist_id) {
            return await this.$store.dispatch(
                "song/getSongsByArtist",
                artist_id
            );
        },
        async getListOfSongsOfPlaylist(playlist_id) {
            // Get playlist songs
            return await this.$store.dispatch(
                "playlist/getListOfSongsInPlaylist",
                playlist_id
            );
        },
        getSongsOfSameGenre(genre, id) {
            // This method is to show list of songs, if a user tries to play a song from the search list
            let songs = this.getMusics.filter(song => song.genre == genre && song.id != id);
            return songs;
        },
        async selectMusic(music) {
            if (music.id == this.getActiveMusicId()) {
                this.updatePlayStatus(!this.getMusicPlayingStatus);
            } else {
                // this.setActiveMusic(music);
                // this.setActiveMusic(music);
                await this.playSelectedMusic(music)
                this.updatePlayStatus(true);
            }
        },
        async playSelectedMusic(music) {
            this.$store.commit("music/setCurrentMusic", music);
            this.$emit("set-playlist", '');
            if (this.getMusicController != null) this.getMusicController.stopSong();
            // get data to stream

            await this.$useMusicPlayer().loadSong(music).then((response) => {
                response.playSong();
                console.log("response: ", response);
                this.$store.commit("music/setMusicController", response);
            })
            // let data = await this.$useMusicPlayer().getSongDataToStream(music);
            // if (this.getDataToStream != null) {
            //     this.$store.commit("music/setDataToStream", null);
            // }
            // this.$store.commit("music/setDataToStream", data);

            // console.log("data to stream: ", this.getDataToStream);
            // let buffer = await this.$useMusicPlayer().loadSong(this.getDataToStream);

            // await this.$useMusicPlayer().musicController(buffer).then((response) => {
            //     response.playSong();
            //     this.$store.commit("music/setMusicController", response);
            // })
        },
        checkActiveMusic(id) {
            // console.log("song id in mixins: ", id);
            // console.log("active music id: ", this.getActiveMusicId());
            return id == this.getActiveMusicId();
        },
        // setActiveMusic(music) {
        //     // console.log("active music: ", music);
        //     this.$store.commit("music/setCurrentMusic", music);
        // },
        async playNewSong(music) {
            if (this.getMusicController != null) {
                this.getMusicController.stopSong();
            }
            this.$store.commit("music/setCurrentMusic", music);
            let buffer = await this.$useMusicPlayer().loadSong(music);
            await this.$useMusicPlayer().musicController(buffer).then((response) => {
                response.playSong();
                console.log("entered again: ", music.name, music.id);
                this.$store.commit("music/setMusicController", response);
            })
            this.$emit("set-playlist",'')
            // console.log(this.checkActiveMusic(music.id));
            // this.clickedMusicItem(music.id);
        },
        updatePlayStatus(status) {
            let conditions = Object.is(this.isPlay, undefined) || Object.is(this.isPlay, null);
            if (!conditions) this.isPlay = status;
            this.$store.commit("music/updatePlayStatus", status);
        },
        clickedMusicItem(id) {
            /**
             * Logic: when a song is clicked then these two values will be true so -
             * emit - get-songs, to set the playlist where this list item belongs to.
             * when the song is paused then getMusicPlayingStatus will be false,-
             * So restrict from setting playlist again
             */

            if (this.checkActiveMusic(id) && this.getMusicPlayingStatus) {
                console.log("clicked music item");
                this.$emit("set-playlist", "");
            }
            else {
                console.log("not clicked music item");
            }
        },
        async setPlayList(setWhat, data = null) {
            // alert(setWhat)
            let playlist = null;
            let singleSongStatus = false;
            console.log("setWhat: ", setWhat);
            if (setWhat == "latestsongs") {
                playlist = this.getListOfLatestSongs();
            }
            if (setWhat == "album") {
                console.log("music id: ", this.getActiveMusic);
                playlist = this.getListOfAlbumSongs(this.getActiveMusic.album_id)
            }
            if (setWhat == "artist") {
                console.log("data: ", data); // data is artist id
                playlist = await this.getListOfSongsOfArtist(data)
            }
            if (setWhat == "playlist") {
                playlist = await this.getListOfSongsOfPlaylist(data)
            }
            if (setWhat == "single") {
                // Set single song
                // console.log("active song: ", this.getActiveMusic);
                this.$store.commit("music/setSingleSong", [this.getActiveMusic]);
                // Set playlist
                playlist = this.getSingleSong;
                singleSongStatus = true;
            }
            if (setWhat == "enable_autoplay_playlist") {
                // get active song genre
                playlist = this.getSongsOfSameGenre(this.getActiveMusic.genre, this.getActiveMusic.id);
                singleSongStatus = true;
            }
            if (setWhat == "disable_autoplay_playlist") {
                // Set playlist to the single song
                playlist = this.getSingleSong;
                singleSongStatus = true;
            }

            console.log("playlist: ", playlist);
            this.$store.commit("music/updatePlaylist", playlist);
            this.$store.commit("music/setSingleSongStatus", singleSongStatus)
        }
    },
}