<template>
    <div class="home-list">
        <div class="d-flex align-items-center justify-content-between">
            <h3 class="heading-3 text-capitalize mb-5">albums</h3>
            <v-chip class="see-all-chip" @click="$router.push({ name: 'albums-user' })">
                see all
            </v-chip>
        </div>
        <div v-if="loaded">
            <vue-horizontal ref="horizontal" class="horizontal" :button="true">
                <div class="items" v-for="album in albums" :key="album.id">
                    <AlbumCard :album="album" @clicked-album="viewAlbumDetails(album.id)" />
                </div>
            </vue-horizontal>
        </div>
        <div class="d-flex align-items-center justify-content-center p-5" v-else>
            <v-progress-circular indeterminate color="white" :size="60"></v-progress-circular>
        </div>
    </div>
</template>

<script>
import VueHorizontal from 'vue-horizontal';
import AlbumCard from "@/components/Music/AlbumCard.vue";
export default {
    name: 'AlbumsList',
    components: {
        VueHorizontal,
        AlbumCard
    },
    data() {
        return {
            loaded: false
        }
    },
    methods: {
        async getAlbumList() {
            await this.$store.dispatch("album/getAlbums");
        },
        viewAlbumDetails(albumId) {
            this.$router.push({
                name: "album-details-user",
                params: {
                    id: albumId,
                },
            });
        },
    },
    computed: {
        albums() {
            // return first 10 albums
            return this.$store.getters["album/allAlbums"].slice().reverse().slice(0, 10);
        },
    },
    mounted() {
        this.getAlbumList();
        this.loaded = true;
    },
}
</script>

<style scoped>
.items>* {
    min-width: var(--list-item-width);
    margin-right: 10px;
}
</style>