<template>
    <div class="nav-drawer-wrapper">
        <router-link v-for="menu in menu_list" :key="menu.id" class="nav-link" :to="menu.link"><i :class="menu.icon"></i> {{
            menu.name }}</router-link>
    </div>
</template>

<script>
import appData from "@/assets/app-data.json";

export default {
    name: "nav drawer",
    data() {
        return {
            menus: appData.user_side_bar_menus,
        };
    },
    props: {
        admin: Boolean,
    },
    computed: {
        menu_list() {
            return this.admin ? appData.admin_side_bar_menus : appData.user_side_bar_menus;
        }
    },
    methods: {
        // identifyRole() {
        //     if (this.$store.getters["auth/admin_exists"]) {
        //         this.menus = appData.admin_side_bar_menus;
        //     } else {
        //         this.menus = appData.user_side_bar_menus;
        //     }
        // },
    },
    mounted() {
        // this.identifyRole();
    },
    watch: {
        admin(value) {
            if (value) {
                this.menus = appData.admin_side_bar_menus;
            } else {
                this.menus = appData.user_side_bar_menus;
            }
        },
    },
}</script>

<style scoped>
.nav-drawer-wrapper {
    width: 100%;
    height: 100%;
    background: var(--dominant-color);
    position: fixed;
    left: 0;
    z-index: 20;
    padding: 25px;
}

.nav-drawer-wrapper .nav-link {
    padding: 10px 0px;
    text-transform: capitalize;
    color: var(--color);
    font-size: 18px;
    border-bottom: 1px solid var(--color);
}

@media (min-width: 992px) {
    .nav-drawer-wrapper {
        display: none;
    }
}
</style>