import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store'
//import HomeView from '../views/HomeView.vue'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import Error from "../views/Error.vue"


const routes = [
  { path: '/', name: 'home', component: Home, meta: { hideMarginals: false, showMusicPlayer: true } },
  { path: '/login', name: 'login', component: Login, meta: { hideMarginals: true, showMusicPlayer: false } },
  { path: '/error', name: 'error', component: Error, meta: { hideMarginals: true, showMusicPlayer: false } },
  { path: '/login/admin', name: 'admin login', component: () => import('../views/Admin/AdminLogin.vue'), meta: { hideMarginals: true, showMusicPlayer: false } },
  { path: '/result', name: 'result', component: () => import('../views/SearchResult.vue'), meta: { hideMarginals: false, showMusicPlayer: true } },
  {
    path: '/about', name: 'about',    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
    meta: { hideMarginals: true, showMusicPlayer: false }
  },
  { path: '/register', name: "register", component: () => import('../views/Register.vue'), meta: { hideMarginals: true, showMusicPlayer: false } },
  { path: '/admin', name: "admin", component: () => import('../views/Admin/AdminDashboard.vue'), meta: { hideMarginals: false, showMusicPlayer: false } },
  {
    path: '/creator-account', name: "creator", component: () => import('../views/Creator/CreatorAccount.vue'),
    meta: { hideMarginals: false, showMusicPlayer: true },
  },
  { path: '/profile', name: "profile", component: () => import('../views/User/Profile.vue'), meta: { hideMarginals: false, showMusicPlayer: true } },
  { path: '/settings', name: "settings", component: () => import('../views/User/Settings.vue'), meta: { hideMarginals: false, showMusicPlayer: true } },
  { path: '/history', name: "history", component: () => import('../views/User/History.vue'), meta: { hideMarginals: false, showMusicPlayer: true } },
  { path: '/albums', name: "albums-user", component: () => import('../views/User/Albums.vue'), meta: { hideMarginals: false, showMusicPlayer: true } },
  { path: '/albums/:id', name: "album-details-user", component: () => import('../views/User/AlbumDetails.vue'), meta: { hideMarginals: false, showMusicPlayer: true } },
  { path: '/playlists', name: "playlists-user", component: () => import('../views/User/Playlists.vue'), meta: { hideMarginals: false, showMusicPlayer: true } },
  { path: '/playlists/:id', name: "playlists-details-user", component: () => import('../views/User/PlaylistDetails.vue'), meta: { hideMarginals: false, showMusicPlayer: true } },
  { path: '/artists', name: "artists-user", component: () => import('../views/Artists.vue'), meta: { hideMarginals: false, showMusicPlayer: true } },
  { path: '/artists/:id', name: "artists-details-user", component: () => import('../views/ArtistDetails.vue'), meta: { hideMarginals: false, showMusicPlayer: true } },
  { path: '/create', name: "account", component: () => import('../views/Creator/Account.vue'), meta: { hideMarginals: false, showMusicPlayer: false } },
  { path: '/create/songs', name: "songs added", component: () => import('../views/Creator/Songs.vue'), meta: { hideMarginals: false, showMusicPlayer: false } },
  { path: '/create/songs/:id', name: "added song details", component: () => import('../views/Creator/SongDetails.vue'), meta: { hideMarginals: false, showMusicPlayer: false } },
  { path: '/create/artists', name: "artists-creator", component: () => import('../views/Creator/Artists.vue'), meta: { hideMarginals: false, showMusicPlayer: false } },
  { path: '/create/albums', name: "albums-creator", component: () => import('../views/Creator/Albums.vue'), meta: { hideMarginals: false, showMusicPlayer: false } },
  { path: '/create/albums/:id', name: "album-details-creator", component: () => import('../views/Creator/AlbumDetails.vue'), meta: { hideMarginals: false, showMusicPlayer: false } },
  { path: '/admin/all-tracks', name: "all tracks", component: () => import('../views/Admin/AllTracks.vue'), meta: { hideMarginals: false, showMusicPlayer: false } },
  { path: '/admin/all-tracks/:id', name: "all tracks details", component: () => import('../views/Creator/SongDetails.vue'), meta: { hideMarginals: false, showMusicPlayer: false } },
  { path: '/admin/users', name: "users", component: () => import('../views/Admin/Users.vue'), meta: { hideMarginals: false, showMusicPlayer: false } },
  { path: '/admin/users/:id', name: "user details", component: () => import('../views/Admin/UserDetails.vue'), meta: { hideMarginals: false, showMusicPlayer: false } },
  { path: '/admin/creators', name: "creators", component: () => import('../views/Admin/Creators.vue'), meta: { hideMarginals: false, showMusicPlayer: false } },
  { path: '/admin/role-requests', name: "role requests", component: () => import('../views/Admin/RoleRequests.vue'), meta: { hideMarginals: false, showMusicPlayer: false } },
  { path: '/admin/albums', name: "albums", component: () => import('../views/Admin/Albums.vue'), meta: { hideMarginals: false, showMusicPlayer: false } },
  { path: '/admin/flags', name: "flags admin", component: () => import('../views/Admin/Flags.vue'), meta: { hideMarginals: false, showMusicPlayer: false } },
  { path: '/admin/flags/details', name: "flag details admin", component: () => import('../views/Admin/FlaggedDetails.vue'), meta: { hideMarginals: false, showMusicPlayer: false } },
  { path: '/admin/albums/:id', name: "album-details-admin", component: () => import('../views/Creator/AlbumDetails.vue'), meta: { hideMarginals: false, showMusicPlayer: false } },
  { path: '/admin/artists', name: "artists-admin", component: () => import('../views/Creator/Artists.vue'), meta: { hideMarginals: false, showMusicPlayer: false } },
  { path: '/admin/artists/:id', name: "artist-details-admin", component: () => import('../views/Admin/ArtistDetails.vue'), meta: { hideMarginals: false, showMusicPlayer: false } },
  { path: '/admin/logs', name: "logs", component: () => import('../views/Admin/LogMessages.vue'), meta: { hideMarginals: false, showMusicPlayer: false } },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

function logout() {
  store.dispatch("auth/logout")
}

router.beforeEach((to, from, next) => {

  let user = store.getters["user/user"];

  // switch (to.path) {
  //   case "/creator-account":
  //     if (user == undefined) { return next("/") }
  //     else if (user.role == "Creator" || user.role == "Admin") { return next("/") }
  //     else { next() }
  //     break;
  //   case "/create":
  //   case "/create/songs":
  //   case "/create/songs/details":
  //   case "/create/artists":
  //   case "/create/albums":
  //     if (user == undefined) { return next("/") }
  //     else if (user.role == "Normal" || user.role == "Admin") { return next("/") }
  //     else { next() }
  //     break;

  //   case "/create/albums/:id":
  //     if (user == undefined) { return next("/") }
  //     else if (user.role == "Normal" || user.role == "Admin") { return next("/") }
  //     else { next() }
  //     break;

  //   case "/admin":
  //   case "/admin/albums/:id":
  //   case "/admin/artists":
  //   case "/admin/artists/:id":
  //   case "/admin/flags":
  //   case "/admin/flags/details":
  //   case "/admin/all-tracks":
  //   case "/admin/all-tracks/:id":
  //     if (user == undefined) { return next("/") }
  //     else if (user.role == "Normal" || user.role == "Creator") { logout() }
  //     else { next() }
  //     break;

  //   // case "/albums":
  //   // case "/albums/:id":
  //   case "/history":
  //   case "/profile":
  //   case "/playlists":
  //   case "/playlists/:id":
  //     if (user == undefined) { return next("/login") }
  //     else { next() }
  //     break;

  //   default:
  //     next()
  //     break;
  // }

  // console.log("route: ", to.matched[0].path, "/"+to.path.split("/")[1], to.matched.some);

  let basePath = "/"+to.path.split("/")[1];

  // console.log("base: ", basePath, to.path);

  switch (basePath) {
    
    case "/creator-account":
    case "/create":
      if (user == undefined) { return next("/") }
      else if (user.role == "Normal" || user.role == "Admin") { return next("/") }
      else { next() }
      break;

    case "/admin":
      if (user == undefined) { return next("/") }
      else if (user.role == "Normal" || user.role == "Creator") { logout() }
      else { next() }
      break;

    // case "/albums":
    // case "/albums/:id":
    case "/history":
    case "/profile":
    case "/playlists":
      if (user == undefined) { return next("/login") }
      else { next() }
      break;

    default:
      next()
      break;
  }

  // if (to.path == "/creator-account") {
  //   let user = store.getters["user/user"];
  //   if (user == undefined) { return next("/") }
  //   else if (user.role == "Creator" || user.role == "Admin") { return next("/") }
  //   else { next() }
  // }
  // if (to.path == "/account") {
  //   let user = store.getters["user/user"];
  //   if (user == undefined) { return next("/") }
  //   else if (user.role == "Normal") { return next("/") }
  //   else { next() }
  // }
  // next();
})

export default router
