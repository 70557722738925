export default {
    computed: {
        menuLink() {
            // console.log("comuted=> menu: ", this.$store.state.user.menu_link)
            return this.$store.state.user.menu_link
        }
    },
    methods: {
        setMenu(link) {
            this.$store.commit("user/setMenu", link)
            // console.log(link);
        }
    }
}