<template>
    <div class="song-component search-item" @mouseenter="mouseEntered" @mouseleave="mouseLeft">

        <div class="d-flex gap-3">
            <div class="thumbnail">
                <img class="search-item-image" src="https://cdn.pixabay.com/photo/2017/08/04/20/10/dj-2581269_1280.jpg"
                    alt="">
                <div class="music-status">
                    <div class="active-music rounded"
                        v-show="!show && checkActiveMusic(song.id) && getMusicPlayingStatus">
                        <i class="bi bi-volume-up"></i>
                    </div>
                    <i v-show="show" class="bi" :class="[
        checkActiveMusic(song.id) && getMusicPlayingStatus
            ? 'bi-pause-fill'
            : 'bi-play-fill',
    ]"></i>
                </div>
            </div>
            <div>
                <div class="d-flex align-items-center gap-2">
                    <div class="text-truncate search-item-heading text-capitalize" style="max-width: 300px;">
                        {{ song.name }}
                    </div>
                    <div class="d-flex align-items-centers gap-2" v-if="adminExists && song.avg_rating != null">
                        <div>
                            <v-rating v-model="song.avg_rating" color="yellow-darken-3" size="x-small" density="compact"
                                half-increments readonly></v-rating>
                        </div>
                        <div>{{ song.avg_rating }}/5</div>
                    </div>
                </div>
                <div class="other d-flex align-items-center text-capitalize text-truncate">
                    <span class="search-item-sub-heading">{{ song.type }}</span>
                    <span><i class="bi bi-dot"></i></span>
                    <span class="search-item-sub-heading">{{ song.artists.map(artist => artist.name).join(",") }}</span>
                    <span><i class="bi bi-dot"></i></span>
                    <span class="search-item-sub-heading">{{ song.genre }}</span>
                    <span><i class="bi bi-dot"></i></span>
                    <span class="search-item-sub-heading">{{ releaseDate }}</span>
                    <span><i class="bi bi-dot"></i></span>
                    <span class="search-item-sub-heading">{{ duration }}</span>
                </div>
            </div>
        </div>

        <!-- <p>Genre: {{ song.genre }}</p> -->
        <!-- <p>Duration: {{ duration }}</p> -->
        <!-- <p>Released: {{ formatDate(song.release_date) }}</p> -->
        <!-- <p v-if="song.album">Album: {{ song.album.title }}</p> -->
        <!-- <p>Artists: {{ songArtists }}</p> -->
        <!-- Additional features like play count could be added here -->
    </div>
</template>

<script>
import moment from 'moment';
import musicMixins from '@/mixins/musicMixins';
export default {
    mixins: [musicMixins],
    data() {
        return {
            details: null,
            show: false,
        }
    },
    props: {
        song: {
            type: Object,
            default: {}
        }
    },
    computed: {
        // songArtists() {
        //     // Assuming artists is an array of artist objects
        //     if (this.song.artists && Array.isArray(this.song.artists)) {
        //         return this.song.artists.map(artist => artist.name).join(',');
        //     }

        //     return ''; // return an empty string or somedefault values if the artist is not an 
        // },
        duration() {
            console.log("avg_rating: ", this.song);
            return moment.utc(this.song.duration * 1000).format("mm:ss")
        },
        releaseDate() {
            return moment(this.song.release_date).format('YYYY');
        },
        adminExists() {
            return this.$store.getters["auth/admin_exists"]
        }
    },
    methods: {
        formatDate(dateString) {
            const date = new Date(dateString);
            return date.toLocaleDateString(undefined, { year: 'numeric', month: 'long', day: 'numeric' });
        },
        mouseEntered() {
            if (!this.$store.getters['auth/admin_exists']) this.show = true;
        },
        mouseLeft() {
            if (!this.$store.getters['auth/admin_exists']) this.show = false;
        },
    },
}
</script>

<style scoped>
.song-component {
    margin: 10px 0px;
}

.thumbnail {
    position: relative;
}

.music-status i,
.music-status .active-music {
    position: absolute;
    color: #fff;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    font-size: 24px;
}

.music-status {
    background: rgba(0, 0, 0, 0.495);
}

.song-component:hover .search-item-image {
    filter: brightness(50%);
}
</style>