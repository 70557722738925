// src/views/Login.vueauthentication-flags

<template>
  <div class="back-to-home">
    <router-link to="/">
      <h4 style="color: var(--color);">Music App</h4>
    </router-link>
  </div>

  <div class="authentication-wrapper animate__animated animate__fadeInUp">

    <div class="authentication-flags">
      <div class="register-flag d-flex align-items-center flex-wrap justify-content-end">
        <span>Don't have a MusicApp account yet?</span>
        <router-link class="bttn fw-bold" to="/register">register</router-link>
      </div>
    </div>

    <!-- LOGIN CONTAINER -->
    <div class="login-container">
      <div class="heading text-center">
        <h4 class="heading-4">Welcome to Music App</h4>
        <p>
          Log in to create playlists, build your library, get personalized
          recommendations & more!
        </p>
      </div>

      <Alert :duration="duration" ref="alert" @completed="onCompleted" />

      <form @submit="userLogin" novalidate>
        <div class="input-control">
          <input id="username" class="form-control m-0" :class="v$.credentials.username_or_email.$error ? 'error-field' : ''
        " type="text" v-model="state.credentials.username_or_email" placeholder="Enter email or username" />
          <span class="field-error" v-if="v$.credentials.username_or_email.$error">
            {{ v$.credentials.username_or_email.$errors[0].$message }}
          </span>
        </div>
        <div class="input-control">
          <div class="password-input-group">
            <input id="password" class="form-control m-0" :class="v$.credentials.password.$error ? 'error-field' : ''"
              :type="showPassword ? 'text' : 'password'" v-model="state.credentials.password" placeholder="Enter password" />
            <i @click="showPassword = !showPassword" class="bi"
              :class="showPassword ? 'bi-eye-fill' : 'bi-eye-slash-fill'"></i>
          </div>
          <span class="field-error" v-if="v$.credentials.password.$error">
            {{ v$.credentials.password.$errors[0].$message }}
          </span>
        </div>

        <div class="text-center mt-4">
          <!-- <button class="bttn dominant" id="login-btn" type="submit">
            Login
          </button> -->
          <SubmitButton buttonText="login" :load="loadButton" />
        </div>

        <div class="mt-4 text-center other-options">
          <span>Forgot password ?</span>
        </div>
      </form>
    </div>
  </div>

  <!-- v-model="credentials.password" -->
</template>

<script>
import useValidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { reactive, computed } from "vue";
import Alert from "@/components/Miscellaneous/Alert.vue";
import SubmitButton from "@/components/Miscellaneous/SubmitButton.vue";

export default {
  name: "Login",
  data() {
    return {
      alertType: "error",
      alertMessage: "Message",
      serverResponse: false,
      duration: 3,
      loadButton: false,
      showPassword: false
    };
  },
  setup() {
    const state = reactive({
      credentials: {
        username_or_email: "",
        password: "",
      },
    });
    const rules = computed(() => {
      return {
        credentials: {
          username_or_email: { required },
          password: { required },
        },
      };
    });

    const v$ = useValidate(rules, state);

    return { state, v$ };
  },
  methods: {
    async userLogin(e) {
      e.preventDefault();

      this.v$.$validate(); // Check all input fields

      if (!this.v$.$error) {
        this.loadButton = true;
        // Store action to login
        let response = await this.$store.dispatch(
          "auth/login",
          this.state.credentials
        );

        if (response.success) {
          this.$router.push("/");
          // let fetchDataResponse = await this.$store.dispatch("user/getUserDetails");
          // if (fetchDataResponse.success) {
          //   this.$router.push("/");
          // }
          // else {
          //   this.$refs.alert.show("error", fetchDataResponse.message);
          //   // sessionStorage.clear();
          //   // localStorage.clear();
          // }
        } else {
          this.$refs.alert.show("error", response.error);
        }
      }
    },
    onCompleted() {
      this.loadButton = false
    }
  },
  components: {
    Alert,
    SubmitButton,
  },
};
</script>


<style scoped>
.back-to-home {
  position: absolute;
  top: 0;
  left: 0;
  padding: 25px;
  cursor: pointer;
}

.back-to-home h4 {
  color: white;
}

.authentication-wrapper {
  max-width: 500px;
  position: absolute;
  top: 150%;
  left: 0;
  right: 0;
  margin: auto;
  background: white;
  border-radius: 15px;
}

.login-container {
  /* Styles specific to login page */
  padding: 25px;
}

.authentication-flags {
  text-align: end;
  padding: 25px;
  font-size: 12px;
}

.login-container input {
  width: 100%;
  margin: 10px 0px;
}

/* .heading h4 {
  color: var(--dominant_color);
} */

.heading p {
  font-size: 14px;
  margin: 20px 0px;
  /* color: var(--dominant-color); */
  color: black;
}

.other-options {
  font-size: 14px;
  /* color: var(--dominant-color); */
  color: black;
  cursor: pointer;
}

.other-options span:hover {
  font-weight: bold;
}

@media (max-width: 598px) {
  .authentication-wrapper {
    margin: 15px;
  }
}
</style>
