<template>
  <div class="content-container">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "content",
};
</script>

<style scoped>
/* .content-container {
  padding-inline: 100px;
} */
</style>