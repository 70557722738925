<template>
    <div class="artist-wrapper rounded" @click="this.$emit('clicked-artist')">
        <div class="artist-container">
            <img src="https://cdn.pixabay.com/photo/2015/05/07/11/02/guitar-756326_960_720.jpg" alt="artist image">
            <div class="gradient"></div>
            <h6 class="text-truncate" style="max-width: 100px;">{{ artistName }}</h6>
        </div>
    </div>
</template>

<script>
export default {
    name: "ArtistCard",
    props: {
        artistName: {
            type: String,
            default: "Name"
        }
    },
}
</script>

<style scoped>
.artist-wrapper {
    background-color: var(--dominant-color-light);
    max-width: var(--list-item-width);
    color: white;
    /* border-radius: 15px; */
    height: var(--list-item-height);
    cursor: pointer;
    transition: transform 0.2s ease-in-out;
}

.artist-wrapper .artist-container {
    width: 100%;
    height: 100%;
    position: relative;
}

.gradient {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(to top, rgb(0, 0, 0), transparent);
}

.artist-wrapper .artist-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.artist-wrapper .artist-container h6 {
    font-weight: 600;
    font-size: 12px;
    text-transform: capitalize;
    position: absolute;
    bottom: 0;
    left: 5%;
    z-index: 2;
}
</style>