export default {
    methods: {
        async rateTheSong(payload) {
            return await this.$store.dispatch("rating/rateSong", payload);
        },
        async getSongsAvgRatingsArray() {
            let songWithRatingsArray = [];
            let songs = await this.$store.dispatch("admin/getAllTracks");

            // console.log("songssssss: ", songs);

            for (let i = 0; i < songs.length; i++) {
                // get song ratings
                let songAvgRatings = await this.$store.dispatch("rating/getAverageSongRating", songs[i].id);
                songWithRatingsArray.push(songAvgRatings);
            }
            // console.log("array: ", songWithRatingsArray);

            return songWithRatingsArray;
        },
    },
}