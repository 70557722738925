<template>
  <v-card class="music-card">
    <img class="music-card-image" src="https://cdn.pixabay.com/photo/2015/01/20/13/13/samsung-605439_1280.jpg"
      alt="music">
    <div class="music-card-overlay">
      <div class="play-music-div">
        <i class="clickable bi" @click="$emit('play-music')" :class="[checkActiveMusic(song.id) && getMusicPlayingStatus ? 'bi-pause-circle' : 'bi-play-circle']"></i>
      </div>
    </div>
  </v-card>
  <div class="mt-2 text-capitalize">
    <div class="song_name text-truncate" style="max-width: 130px;">{{song.name}}</div>
    <div class="song_artists text-truncate" style="max-width: 130px;">{{song.artists.map(artist => artist.name).join(",")}}</div>
  </div>
  <div>

  </div>
</template>

<script>
import musicMixins from '@/mixins/musicMixins';

export default {
  name: "MusicCard",
  mixins: [musicMixins],
  props: {
    song: {
      type: Object,
      default: {},
    },
  },
  methods: {
    playSong() {
      this.$store.dispatch("song/songPlayOrPauseController", true);
      this.$emit("play-song", "id");
    },
    showLyrics() {
      this.$emit("show-lyrics")
    }
  },
};
</script>

<style scoped>
.music-card {
  max-width: var(--list-item-width);
  height: var(--list-item-height);
  position: relative;
}

.music-card:hover .music-card-overlay {
  display: block;
}

.music-card-image {
  width: var(--list-item-width);
  height: var(--list-item-height);
  object-fit: cover;
}

.song_name, .song_artists {
  color: var(--color);
}

.song_name {
  font-size: 14px;
  font-weight: bold;
}

.song_artists{
  font-size: 12px;
}

.music-card-overlay {
  position: absolute;
  background: rgba(0, 0, 0, 0.647);
  inset: 0 0 0 0;
  display: none;
}

.play-music-div {
  position: absolute;
  bottom: 0;
  right: 5%;
}

.play-music-div i {
  color: white;
  font-size: 36px;
}
</style>