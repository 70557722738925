import store from '@/store';
import { ref, computed } from 'vue';

let audio = new Audio();
let currentSong = ref(null);
let isPlaying = ref(false);
let songUpdatedTime = ref(0);
let songProgressValue = ref(0)
let chunkSize = ref(4096);
let songLoaded = ref(false);
let songStartedAt = ref(null);
let songPausedAt = ref(null);
let source = ref({});
// let audioBuffer = ref({});
let audioContext = ref({});
let gainNode = ref({});
let timer = ref(null);
let duration = ref(0);
let songVolume = ref(1);
let songEnded = ref(false);
let seeking = ref(false);
let executed = ref(false);
let index = ref(0)
let songDecoded = ref(true);
let songDuration = ref(0);
// let activeMusic = ref({})

export function useMusicPlayer() {

  // let source = ref({})
  // let audioBuffer = ref({});
  // Load song  =>  old song
  // async function loadSong() {

  //   console.log("is playing value: ", isPlaying.value);
  //   songLoaded.value = false;
  //   completed.value = false;

  //   resumeValues();

  //   loadAudioController();
  //   // Get active music from music store
  //   activeMusic = store.state.music.activeMusic;
  //   console.log("active song: ", activeMusic);
  //   // Get song file
  //   await store.dispatch("song/getSong", activeMusic.id);
  //   // Save song details in song.value
  //   currentSong.value = store.getters["song/song"];
  //   console.log("fetched details of song: ", activeMusic.id);

  //   // New code
  //   let payload = {
  //     id: activeMusic.id,
  //     start: 0,
  //     end: chunkSize.value - 1
  //   }

  //   console.log("entered: ", completed.value);
  //   response = await store.dispatch("song/streamSong", payload);
  //   console.log("response data", response.data);
  //   // console.log("arraybuffer response: ", response.data);

  //   // console.log("audio buffer value: ", Object.keys(audioBuffer).length);
  //   audioBuffer = await audioContext.decodeAudioData(response.data);
  //   gainNode.gain.setValueAtTime(songVolume.value, audioContext.currentTime);
  //   console.log("fetched buffer data of song: ", activeMusic.id);

  //   if (isPlaying.value) {
  //     // source.disconnect(audioContext.destination);
  //     console.log("found is playing true");
  //     stopSong();
  //     source.disconnect(audioContext.destination)
  //     // isPlaying.value = false;
  //     return loadSong();
  //     // console.log("set is playing to true");
  //   }

  //   songLoaded.value = true;

  //   console.log("loaded song: ", currentSong.value.name);
  //   console.log("loaded song id: ", activeMusic.id);

  // }

  // old code

  // const getSongDataToStream = (activeMusic) => new Promise(async (resolve, reject) => {
  //   try {
  //     resumeValues();
  //     songLoaded.value = false;
  //     // Get music details
  //     await store.dispatch("song/getSong", activeMusic.id);
  //     // Save song details in song.value
  //     currentSong.value = store.getters["song/song"];
  //     console.log("fetched details of song: ", activeMusic.name, activeMusic.id);

  //     console.log("song decoded: ", songDecoded.value);
  //     let payload = {
  //       id: activeMusic.id,
  //       start: 0,
  //       end: chunkSize.value - 1
  //     }

  //     let response = await store.dispatch("song/streamSong", payload);
  //     console.log("fetched data to stream: ", response.data);
  //     // audio.src = response;
  //     // audio.play();
  //     // resolve(response.data);

  //   } catch (error) {
  //     console.log("error: ", error);
  //     reject(error)
  //   }
  // })

  // const decodeSong = (response) => new Promise(async (resolve, reject) => {
  //   try {
  //     loadAudioController();
  //     // songLoaded.value = false;
  //     songDecoded.value = false;

  //     // // Get music details
  //     // await store.dispatch("song/getSong", activeMusic.id);
  //     // // Save song details in song.value
  //     // currentSong.value = store.getters["song/song"];
  //     // console.log("fetched details of song: ", activeMusic.name, activeMusic.id);

  //     // let payload = {
  //     //   id: activeMusic.id,
  //     //   start: 0,
  //     //   end: chunkSize.value - 1
  //     // }

  //     // let response = await store.dispatch("song/streamSong", payload);
  //     let audioBuffer = await audioContext.decodeAudioData(response);


  //     // if (isPlaying.value) {
  //     //   return;
  //     // }

  //     console.log("decoded song: ", audioBuffer);
  //     gainNode.gain.setValueAtTime(songVolume.value, audioContext.currentTime);
  //     // console.log("fetched data to buffer: ", activeMusic.name, activeMusic.id);

  //     songDecoded.value = true;
  //     songLoaded.value = true;


  //     resolve(audioBuffer);

  //   } catch (error) {
  //     console.log("error in loading song:", error);
  //     reject(error)
  //   }

  // })

  const getAndSetAudio = (activeMusic) => new Promise(async (resolve, reject) => {
    resumeValues();
    songLoaded.value = false;
    // Get music details
    await store.dispatch("song/getSong", activeMusic.id);
    // Save song details in song.value
    currentSong.value = store.getters["song/song"];
    console.log("fetched details of song: ", activeMusic.name, activeMusic.id);
    let payload = {
      id: activeMusic.id,
      start: 0,
      end: chunkSize.value - 1
    }
    let response = await store.dispatch("song/streamSong", payload);
    audio.src = response;
    songLoaded.value = true;
    resolve(true);
    // let audioBuffer = await audioContext.decodeAudioData(response);

  })

  const musicController = () => new Promise((resolve, reject) => {
    try {
      const playSong = () => {
        isPlaying.value = true;
        startSongProgress()
        updateSongTime();
        songEndedChecker();
        audio.onloadedmetadata = () => {
          songDuration.value = audio.duration
        }
        audio.play();
      }

      const stopSong = () => {
        audio.pause();
        if (Object.keys(timer) == 0) clearInterval(timer);
        isPlaying.value = false;
      }

      const setVolume = (volume) => {
        audio.volume = parseFloat(volume);
      }

      const seekSong = (seekValue) => {
        audio.currentTime = seekValue;
        songProgressValue.value = seekValue;
      }

      const repeatSong = () => {
        stopSong();
        playSong();
      }

      const randomSong = () => {
        // Get songs length
        let currentPlaylistSongs = store.state.music.activePlaylist;
        let numberOfSongs = currentPlaylistSongs.length;
        let randomIndex;
        randomIndex = Math.floor(Math.random() * numberOfSongs);
        // song = currentPlaylistSongs[randomIndex];
        // console.log("song selected before: ", currentPlaylistSongs[randomIndex])
        // console.log("song id before: ", song.id, " and ", currentSong.value.id);
        //Logic:  If random song id is equal to the currentSong id then select another song
        if (currentPlaylistSongs[randomIndex].id == currentSong.value.id) {
          randomIndex = randomIndex + 1;
          if (randomIndex > numberOfSongs - 1) randomIndex = 0;
        }

        let song = currentPlaylistSongs[randomIndex];

        // console.log("song after: ", song);

        // Set the song as active music
        // store.commit("music/setCurrentMusic", song);
        return song;
        // Play the randomly selected song
        // await loadSong();
      }

      resolve({ playSong, stopSong, setVolume, seekSong, repeatSong, randomSong })
    } catch (error) {

    }
  })

  // const musicController = (audioBuffer) => new Promise(async (resolve, reject) => {

  //   // let source = null;

  //   try {
  //     // loadAudioController();
  //     // resumeValues();

  //     // songLoaded.value = false;
  //     // fetchedSongDetails.value = false;
  //     // let response = null;
  //     // let audioBuffer = null;
  //     // let source = null;

  //     // let activeMusic = song;
  //     // // Get music details
  //     // await store.dispatch("song/getSong", activeMusic.id);
  //     // // Save song details in song.value
  //     // currentSong.value = store.getters["song/song"];
  //     // console.log("fetched details of song: ", activeMusic.name, activeMusic.id);

  //     // fetchedSongDetails.value = true;

  //     // let payload = {
  //     //   id: activeMusic.id,
  //     //   start: 0,
  //     //   end: chunkSize.value - 1
  //     // }

  //     // response = await store.dispatch("song/streamSong", payload);
  //     // console.log("fetched data to stream: ", response.data);
  //     // // console.log("arraybuffer response: ", response.data);

  //     // // console.log("audio buffer value: ", Object.keys(audioBuffer).length);
  //     // audioBuffer = await audioContext.decodeAudioData(response.data);

  //     // // if (isPlaying.value) {
  //     // //   return;
  //     // // }

  //     // // songDecodedStatus = true;

  //     // console.log("decoded song: ", audioBuffer);
  //     // gainNode.gain.setValueAtTime(songVolume.value, audioContext.currentTime);
  //     // console.log("fetched data to buffer: ", activeMusic.name, activeMusic.id);

  //     // songLoaded.value = true;

  //     const playSong = (resume = 0) => {
  //       // console.log("play song");

  //       source = audioContext.createBufferSource();
  //       // console.log("source: ", source);

  //       source.buffer = audioBuffer;
  //       console.log("active buffer: ", audioBuffer);
  //       // source.buffer = store.state.music.activeBuffer

  //       // console.log("active buffer: ", source.buffer);
  //       source.connect(audioContext.destination);
  //       source.connect(gainNode);
  //       duration = audioBuffer.duration;

  //       // console.log("duration:", audioBuffer);

  //       // songPaused.value = false;
  //       if (resume == 0) resume = songPausedAt.value

  //       // console.log("resume value: ", resume);

  //       songStartedAt.value = Date.now() - resume;
  //       source.start(0, resume / 1000);

  //       isPlaying.value = true;

  //       // true => start progress
  //       songProgress(true);

  //       console.log("song is playing: ", isPlaying.value);
  //       console.log("===========================played song===============================");

  //     }

  //     const stopSong = () => {

  //       // if (Object.keys(source).length === 0) {
  //       //   // console.log("stopping");
  //       //   // source.buffer = null;
  //       //   // console.log("source before stop: ", source);
  //       //   source.stop(0)
  //       //   // console.log("source after stop: ", source);
  //       //   isPlaying.value = false;
  //       //   songPausedAt.value = Date.now() - songStartedAt.value
  //       //   songProgress(false);
  //       //   console.log("stopped song");
  //       // }
  //       // console.log("stopping");
  //       // source.buffer = null;
  //       // console.log("source before stop: ", source);
  //       if (source != null) {
  //         source.stop(0)
  //         // console.log("source after stop: ", source);
  //         isPlaying.value = false;
  //         songPausedAt.value = Date.now() - songStartedAt.value
  //         songProgress(false);
  //         console.log("stopped song");
  //       }

  //     }

  //     const seekSong = (seekValue) => {
  //       // console.log(seekValue);
  //       stopSong()
  //       playSong(seekValue * 1000)
  //     }

  //     // const nextSong = () => {
  //     //   console.log("clicked next button");
  //     //   // console.log("song decoded status: ", songDecodedStatus);
  //     //   // audioBuffer = null;
  //     //   // response = null;
  //     //   // audioBuffer = null;
  //     //   // console.log("soure before: ", source);
  //     //   // if (source) source = null;
  //     //   // console.log("source after: ", source);

  //     //   let currentPlaylistSongs = store.state.music.activePlaylist;
  //     //   // console.log("current: ", currentPlaylistSongs);
  //     //   let index = currentPlaylistSongs.findIndex(song => song.id === currentSong.value.id);
  //     //   // let index = currentPlaylistSongs.findIndex(song => song.id === loadedSongId.value);
  //     //   index++;
  //     //   // console.log("index: ", index);
  //     //   if (index > currentPlaylistSongs.length - 1) index = 0;
  //     //   // Get song of the current index => which will be the next song
  //     //   let song = currentPlaylistSongs[index];
  //     //   console.log("next song is: ", song.name, song.id);
  //     //   // console.log("song id load song:", song.id);
  //     //   // source.stop()
  //     //   // Set the song as active music
  //     //   // store.commit("music/setCurrentMusic", song);
  //     //   // console.log("stored song " + song.name + "," + song.id + " as active song in vuex store");
  //     //   return song;
  //     // }

  //     // const prevSong = () => {
  //     //   let currentPlaylistSongs = store.state.music.activePlaylist;
  //     //   let index = currentPlaylistSongs.findIndex(song => song.id === currentSong.value.id);
  //     //   index--;
  //     //   if (index < 0) index = currentPlaylistSongs.length - 1;
  //     //   // Get song of the current index => which will be the previous song
  //     //   let song = currentPlaylistSongs[index];

  //     //   // Set the song as active music
  //     //   // store.commit("music/setCurrentMusic", song);
  //     //   return song;
  //     // }

  //     const randomSong = () => {
  //       // Get songs length
  //       let currentPlaylistSongs = store.state.music.activePlaylist;
  //       let numberOfSongs = currentPlaylistSongs.length;
  //       let randomIndex;
  //       randomIndex = Math.floor(Math.random() * numberOfSongs);
  //       // song = currentPlaylistSongs[randomIndex];
  //       // console.log("song selected before: ", currentPlaylistSongs[randomIndex])
  //       // console.log("song id before: ", song.id, " and ", currentSong.value.id);
  //       //Logic:  If random song id is equal to the currentSong id then select another song
  //       if (currentPlaylistSongs[randomIndex].id == currentSong.value.id) {
  //         randomIndex = randomIndex + 1;
  //         if (randomIndex > numberOfSongs - 1) randomIndex = 0;
  //       }

  //       let song = currentPlaylistSongs[randomIndex];

  //       // console.log("song after: ", song);

  //       // Set the song as active music
  //       // store.commit("music/setCurrentMusic", song);
  //       return song;
  //       // Play the randomly selected song
  //       // await loadSong();
  //     }

  //     const repeatSong = () => {
  //       resumeValues();
  //       playSong();
  //     }

  //     const setVolume = (volume) => {
  //       songVolume.value = volume;
  //       gainNode.gain.setValueAtTime(songVolume.value, audioContext.currentTime);
  //     }

  //     resolve({ playSong, stopSong, seekSong, randomSong, repeatSong, setVolume })

  //   } catch (error) {
  //     console.log("error: ", error);
  //     stopSong();
  //   }
  // })

  // To play song create AudioContext class
  /**
   * Some browsers allow using Audio Context only after user interaction with the page. 
   * If the user will not make any action on the page, an error will occur. That's why you need getAudioContext.
   */

  const getAudioContext = () => {
    AudioContext = window.AudioContext;
    // const audioContent = new AudioContext();
    const audio_context = new AudioContext();
    // console.log("audioContext: ", typeof audioContent);
    return audio_context;
  };

  // load initial web audio api 
  const loadAudioController = () => {
    if (!executed.value) {
      executed.value = true;
      audioContext = getAudioContext();
      gainNode = audioContext.createGain();
      gainNode.connect(audioContext.destination);
      // console.log("executed once");
    } else {
      // console.log("not executed");
    }
  }

  const resumeValues = () => {
    songStartedAt.value = 0;
    songPausedAt.value = 0;
    songUpdatedTime.value = 0;
    songProgressValue.value = 0;
    songEnded.value = false;
    seeking.value = false;
  }

  // trigger song progress
  const songProgress = (status) => {
    if (status) {
      timer = setInterval(() => {
        const playBackTime = (Date.now() - songStartedAt.value) / 1000;
        // songProgressValue.value = parseInt((playBackTime * 100) / duration, 10);
        songProgressValue.value = playBackTime;
        // console.log("seek: ", playBackTime, duration);
        songUpdatedTime.value = playBackTime;
        // console.log(Math.trunc(playBackTime), Math.trunc(duration));
        if (Math.trunc(playBackTime) == Math.trunc(duration)) {
          songEnded.value = true
          clearInterval(timer)
        }
      }, 1000);
    }
    else {
      // console.log("paused song ========================");
      clearInterval(timer);
    }

  }

  function updateSongTime() {
    audio.addEventListener("timeupdate", (event) => {
      const { currentTime } = event.target;
      songUpdatedTime.value = currentTime;
    })
  }

  // song progress
  function startSongProgress() {
    //   Update song progress
    audio.addEventListener("timeupdate", (event) => {
      const { duration, currentTime } = event.target;
      // console.log("current time: ", currentTime);
      // const progressValue = (currentTime / duration) * 100;
      songProgressValue.value = currentTime;
    });
  }

  function songEndedChecker() {
    timer = setInterval(() => {

      // console.log("audio.currentTime", audio.currentTime);
      if (Math.trunc(audio.currentTime) == Math.trunc(songDuration.value)) {
        songEnded.value = true
        clearInterval(timer)
      }
    }, 1000);
  }

  const nextSong = () => {
    console.log("clicked next button");
    // console.log("song decoded status: ", songDecodedStatus);
    // audioBuffer = null;
    // response = null;
    // audioBuffer = null;
    // console.log("soure before: ", source);
    // if (source) source = null;
    // console.log("source after: ", source);

    let currentPlaylistSongs = store.state.music.activePlaylist;

    if (currentPlaylistSongs.length == 1) {
      console.log("playlist length is 1");
      return null;
    }
    // console.log("current playlist length: ", currentPlaylistSongs.length);
    let index = currentPlaylistSongs.findIndex(song => song.id === store.state.music.activeMusic.id);
    // console.log("index before increment: ", index);
    // let index = currentPlaylistSongs.findIndex(song => song.id === loadedSongId.value);
    index++;
    // console.log("index after increment: ", index);
    if (index > currentPlaylistSongs.length - 1) {
      // console.log("index is greater");
      index = 0;
    }
    // Get song of the current index => which will be the next song
    let song = currentPlaylistSongs[index];
    console.log("next song is: ", song.name, song.id);
    // console.log("song id load song:", song.id);
    // source.stop()
    // Set the song as active music
    // store.commit("music/setCurrentMusic", song);
    // console.log("stored song " + song.name + "," + song.id + " as active song in vuex store");
    return song;
  }

  const prevSong = () => {
    let currentPlaylistSongs = store.state.music.activePlaylist;

    if (currentPlaylistSongs.length == 1) {
      return null;
    }

    let index = currentPlaylistSongs.findIndex(song => song.id === store.state.music.activeMusic.id);
    index--;
    if (index < 0) index = currentPlaylistSongs.length - 1;
    // Get song of the current index => which will be the previous song
    let song = currentPlaylistSongs[index];

    // Set the song as active music
    // store.commit("music/setCurrentMusic", song);
    return song;
  }

  const loadSong = (music) => new Promise(async (resolve, reject) => {

    // await getSongDataToStream(music)
    await getAndSetAudio(music).then((response) => {
      return response;
    }).then((data) => {
      let controller = musicController();
      resolve(controller)
    })

    // await getSongDataToStream(music).then((dataResponse) => {
    //   return dataResponse
    // }).then(async (data) => {
    //   if (songDecoded.value) {
    //     let decodedSong = decodeSong(data);
    //     return decodedSong
    //   }
    //   else {
    //     return null;
    //   }
    // }).then((buffer) => {
    //   if (buffer != null) {
    //     let controller = musicController(buffer);
    //     resolve(controller);
    //   }
    //   else {
    //     console.log("music: ", music);
    //     return;
    //   }
    // }).catch((error) => {
    //   console.log("error on loading song", error);
    //   reject(error)
    // })
  })


  const songTitle = computed(() => currentSong.value?.name || 'No song playing');
  const songId = computed(() => currentSong.value?.id || null);
  const songArtists = computed(() => currentSong.value?.artists.map(artist => artist.name).join(",") || 'No song playing');
  // const songDuration = computed(() => currentSong.value?.duration || null);

  return { loadSong, musicController, nextSong, prevSong, currentSong, isPlaying, songEnded, songLoaded, songTitle, songId, songArtists, songUpdatedTime, songDuration, songProgressValue };
} 