<template>
  <v-card class="album-card" @click="this.$emit('clicked-album')">
    <img class="album-card-image" :src="album.image_file" alt="album thumbnail">
    <div class="image-overlay"></div>
    <div class="title text-truncate" style="max-width: 100px;">
      {{ album.title }}
    </div>
  </v-card>
</template>

<script>
export default {
  name: "AlbumCard",
  props: {
    album: {
      type: Object,
      default: {},
    },
  },
};
</script>

<style scoped>
.album-card {
  /* background-image: linear-gradient(to right, var(--accent_color), var(--dominant_color), var(--support_color)); */
  /* background-color: var(--dominant-color-light); */
  max-width: var(--list-item-width);
  /* color: white; */
  /* border-radius: 15px; */
  height: var(--list-item-height);
  /* padding: 15px; */
  cursor: pointer;
  position: relative;
}

/* .album-card .icon i {
  font-size: 56px;
} */

.album-card-image {
  width: var(--list-item-width);
  height: var(--list-item-height);
  object-fit: cover;
}

.image-overlay {
  position: absolute;
  background-image: linear-gradient(to top, black, transparent);
  inset: 0 0 0 0;
}

.album-card .title {
  font-weight: 500;
  font-size: 14px;
  text-transform: capitalize;
  position: absolute;
  bottom: 10%;
  left: 10%;
  color: white;
}

/* @media screen and (max-width: 992px) {
    .album-card {
        max-width: 100%;
    }
} */
</style>