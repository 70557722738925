// Api services
import apiServices from "@/services/apiServices";
// path
let _path = "/album"
// album.js
export default {
    namespaced: true,
    state: {
        // album state here
        albums: [],
        album: null,
        album_image: null,
        flags: [],
        artists: []
    },
    mutations: {
        // album mutations here
        setAlbums: (state, albums) => (state.albums = albums),
        setAlbum: (state, album) => (state.album = album),
        createAlbum: (state, newAlbum) => state.albums.unshift(newAlbum),
        setAlbumImage: (state, image) => (state.album_image = image),
        updateAlbum: (state, albumData) => {
            const index = state.albums.findIndex(album => album.id === albumData.id);
            if (index !== -1) {
                state.albums.splice(index, 1, albumData)
            }
        },
        deleteAlbum: (state, id) => (state.albums = state.albums.findIndex(album => album.id !== id)),
        addFlag: (state, id) => {
            state.albums.map(album => {
                if (album.id === id) {
                    return { ...state.albums, flag: true }
                }
            })
        },
        removeFlag: (state, id) => {
            state.albums.map(album => {
                if (album.id === id) {
                    return { ...state.albums, flag: false }
                }
            })
        },
        setFlags: (state, flags) => (state.flags = flags),
        setArtists: (state, artists) => (state.artists = artists),
        addArtist: (state, newArtist) => state.artists.unshift(newArtist),
        removeArtist: (state, id, artist_id) => {
            state.albums.map(album => {
                if (album.id === id) {
                    album.artists.filter(artist => artist.id !== artist_id)
                }
            })
        },
        updateAlbumName: (state, albumData) => {
            state.albums.map(album => {
                if (album.id === albumData.id) {
                    album.name = albumData.name
                }
            })
        },
        updateAlbumImage: (state, albumData) => {
            state.albums.map(album => {
                if (album.id === albumData.id) {
                    album.image = albumData.image
                }
            })
        },
        deleteAlbumImage: (state, id) => {
            state.albums.map(album => {
                if (album.id === id) {
                    album.image = null
                }
            })
        }
    },
    actions: {
        // album actions here

        // Get albums
        async getAlbums({ commit, dispatch }) {
            try {
                const albums = await apiServices.get(_path + "/albums");

                if (albums.data != null) {
                    for (let i = 0; i < albums.data.length; i++) {
                        const albumImage = await apiServices.get(_path + "/albums/image/" + albums.data[i].id);
                        if (albumImage.data != null) {
                            const byteCharacters = atob(albumImage.data);
                            const byteNumbers = new Array(byteCharacters.length);
                            for (let i = 0; i < byteCharacters.length; i++) {
                                byteNumbers[i] = byteCharacters.charCodeAt(i);
                            }
                            const byteArray = new Uint8Array(byteNumbers);
                            albums.data[i].image_file = URL.createObjectURL(new Blob([byteArray], { type: 'image/jpeg' }));
                        }
                        else {
                            albums.data[i].image_file = "https://cdn.pixabay.com/photo/2017/03/21/02/00/image-2160911_1280.png"
                        }
                    }
                }
                // console.log("albums: ", albums.data);
                
                commit("setAlbums", albums.data);
            } catch (error) {
                console.log("getAlbums error: ", error);
                // if (error.response.status == 401) dispatch("auth/logout", null, { root: true })
            }
        },

        // Get album
        async getAlbum({ commit, dispatch }, id) {
            try {
                const album = await apiServices.get(_path + "/albums/" + id);
                commit("setAlbum", album.data);
                return album.data
            } catch (error) {
                console.log("getAlbum error: ", error);
                if (error.response.status == 401) dispatch("auth/logout", null, { root: true })
            }
        },

        // Create album
        async createAlbum({ commit }, payload) {
            try {
                // console.log("payload", Array.from(payload));
                let response = await apiServices.post(_path + "/albums", payload);
                commit("createAlbum", payload)
                // console.log("response: ", response);
                return {
                    success: true,
                    message: "Added album successfully",
                    data: response.data
                }
            } catch (error) {
                console.log(error);
                return {
                    success: false,
                    message: "Failed to add album",
                    error: error
                }
            }
        },

        // Get album image
        async getAlbumImage({ commit }, id) {
            const albumImage = await apiServices.get(_path + "/albums/image/" + id);

            let image = null;

            if (albumImage.data != null) {
                const byteCharacters = atob(albumImage.data);
                const byteNumbers = new Array(byteCharacters.length);
                for (let i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                const byteArray = new Uint8Array(byteNumbers);
                image = URL.createObjectURL(new Blob([byteArray], { type: 'image/png' }));
            }
            else {
                image = "https://cdn.pixabay.com/photo/2017/03/21/02/00/image-2160911_1280.png"
            }


            commit("setAlbumImage", image)
        },

        // Update album
        async updateAlbum({ commit }, payload) {
            try {
                console.log(payload);
                await apiServices.put(_path + "/albums/" + payload.id, payload.data);
                commit("updateAlbum", payload)
                return {
                    success: true,
                    message: "Updated album successfully"
                }
            } catch (error) {
                console.log(error);
                return {
                    success: false,
                    message: "Failed to update album",
                    error: error
                }
            }
        },

        // Delete album
        async deleteAlbum({ commit }, id) {
            try {
                await apiServices.delete(_path + "/albums/" + id);
                commit("deleteAlbum", id);
                return {
                    success: true,
                    message: "Deleted album successfully"
                }
            } catch (error) {
                console.log("delete album error: ", error);
                return {
                    success: false,
                    message: "Failed to delete",
                    error: error
                }
            }
        },

        // Flag album
        async flagAlbum({ commit }, payload) {
            try {
                await apiServices.post(_path + "/albums/" + payload.album_id + "/flag", payload.data);
                commit("addFlag", payload.id);
                return {
                    success: true,
                    message: "Reported album successfully"
                }
            } catch (error) {
                console.log("flagAlbum error: ", error);
                return {
                    success: false,
                    message: "Failed to report",
                    error: error
                }
            }
        },

        // Unflag album
        async unFlagAlbum({ commit }, payload) {
            try {
                await apiServices.post(_path + "/albums/" + payload.album_id + "/unflag", payload.data);
                commit("removeFlag", payload.album_id);
                return {
                    success: true,
                    message: "Unflagged album successfully"
                }
            } catch (error) {
                console.log("unFlagAlbum error: ", error);
                return {
                    success: false,
                    message: "Failed to unflag",
                    error: error
                }
            }
        },

        // Get my flagged albumms
        async getMyFlaggedAlbums({ commit }) {
            const albums = await apiServices.get(_path + "/my_flagged_albums");
            commit("setAlbums", albums)
        },

        // Get all flags
        async getAllFlags({ commit }) {
            const flags = await apiServices.get(_path + "/all_flags");
            commit("setFlags", flags)
        },

        // Search artists
        async getArtists({ commit }) {
            const artists = await apiServices.get(_path + "/albums/" + id + "/artists/search");
            commit("setArtists", artists)
        },

        // Add artist to album
        async addArtistToAlbum({ commit }, payload) {
            const response = await apiServices.post(_path + "/albums/" + payload.id + "/add_artist")
            commit("addArtist", payload)
        },

        // Remove artist from album
        async removeArtistFromAlbum({ commit }, payload) {
            const response = await apiServices.delete(_path + "/albums/" + payload.id + "/delete_artist/" + payload.artist_id);
            commit("removeArtist", payload.id, payload.artist_id)
        },

        // Update album name
        async updateAlbumName({ commit }, payload) {
            const response = await apiServices.put(_path + "/albums/" + payload.id + "/update_name", payload);
            commit("updateAlbumName", payload)
        },

        // Update album image
        async updateAlbumImage({ commit }, payload) {
            const response = await apiServices.put(_path + "/albums/" + payload.id + "/update_image", payload);
            commit("updateAlbumImage", payload)
        },

        // Delete album image
        async deleteAlbumImage({ commit }, id) {
            const response = await apiServices.delete(_path + "/albums/" + id + "/delete_image");
            commit("removeAlbumImage", id)
        },

        // Remove song from album
        async removeSong({ }, payload) {
            try {
                await apiServices.put("/song/songs/" + payload.song_id + "/album", payload.data);
                return {
                    success: true,
                    message: "Removed song successfully"
                }
            } catch (error) {
                console.log("removeSong error: ", error);
                return {
                    success: false,
                    message: "Failed to remove song",
                    error: error
                }
            }
        },

        async addExistingSongsToAlbum({ }, payload) {
            try {
                for (let i = 0; i < payload.song_ids.length; i++) {
                    await apiServices.put("/song/songs/" + payload.song_ids[i] + "/album", payload.data);
                }
                return {
                    success: true,
                    message: "Added songs to album"
                }
            } catch (error) {
                console.log("addExistingSongsToAlbum error: ", error);
                return {
                    success: false,
                    message: "Failed to add songs",
                    error: error
                }
            }
        },

        async removeMultipleSongsFromAlbum({ }, payload) {
            try {
                for (let i = 0; i < payload.song_ids.length; i++) {
                    await apiServices.put("/song/songs/" + payload.song_ids[i] + "/album", payload.data);
                }
                return {
                    success: true,
                    message: "Removed songs from album"
                }
            } catch (error) {
                console.log("removeMultipleSongsFromAlbum error: ", error);
                return {
                    success: false,
                    message: "Failed to remove songs",
                    error: error
                }
            }
        },

        async playAlbum({ }, payload) {
            try {
                await apiServices.post(_path + "/albums/play", payload);
                return true
            } catch (error) {
                console.log("error => playAlbum: ", error);
                return false;
            }
        }

    },
    getters: {
        // album getters here
        allAlbums: state => state.albums,
        album: state => state.album,
        albumImage: state => state.album_image,
        albumFlags: state => state.flags,
        albumArtists: state => state.artists
    }
};