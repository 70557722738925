<template>
    <div class="playlist-card rounded" @click="this.$emit('clicked')">
        <div class="icon">
            <i class="bi bi-music-note-list"></i>
        </div>
        <div class="title text-truncate" style="max-width: 100px; color: white;">
            {{ title }}
        </div>
    </div>
</template>

<script>
export default {
    name: "playlist card",
    props: {
        title: {
            type: String,
            default: "playlist title"
        }
    },
}
</script>

<style scoped>
.playlist-card {
    background-color: var(--accent-color);
    max-width: var(--list-item-width);
    color: white;
    /* border-radius: 15px; */
    height: var(--list-item-height);
    padding: 15px;
    cursor: pointer;
    position: relative;
}

.playlist-card .icon i {
    font-size: 56px;
}

.playlist-card .title {
    font-weight: 500;
    font-size: 12px;
    text-transform: capitalize;
    position: absolute;
    bottom: 10%;
    left: 10%;
}

@media screen and (max-width: 992px) {
    .playlist-card {
        max-width: 100%;
    }
}
</style>