<template>
  <div class="music_player_web_view_wrapper">
    <div :class="expandedViewClasses" class="expanded-view animate__animated">
      <div class="sidebar-area invisible">
        <!-- sdjfdsjfdfkdjdskfjkdsfjdkfjkdjjjkdjfsjfksdjf -->
      </div>
      <div class="expanded-area">
        <div class="row ps-5 pe-5 pb-3" style="height: 100%; padding-top: 150px">
          <div class="col-md-7 mt-5">
            <div class="music-thumbnail" style="height: 100%">
              <img class="object-fit-cover rounded" style="width: 100%; height: 100%"
                src="https://cdn.pixabay.com/photo/2015/01/20/13/13/samsung-605439_1280.jpg" alt="music thumbnail" />
              <div class="rating-button-div">
                <v-chip class="text-capitalize" prepend-icon="mdi mdi-star" size="small" color="primary" variant="flat"
                  @click="showRatingStarDiv">
                  rate this song
                </v-chip>
              </div>
              <div class="rating-star-div text-center animate__animated" v-if="showRatingStar"
                :class="ratingStarDivAnimationClass">
                <Alert :duration="alertDuration" ref="ratingRef" @completed="showRatingStar = false" />

                <div class="close-rating-star-div" @click="showRatingStar = false">
                  <i class="bi bi-x-lg"></i>
                </div>

                <div class="text-h2 mt-5 text-warning">
                  {{ rating }}
                  <span class="text-h6 ml-n3">/5</span>
                </div>
                <v-rating v-model="rating" color="orange-lighten-1" half-increments hover @click="rateSong"></v-rating>
              </div>
              <div class="play-or-pause" @click="thumbnailclicked" v-ripple>
                <i v-if="songLoaded && showPlayOrPauseIcon" style="font-size: 6em;" class="bi clickable text-white animate__animated" :class="[playingStatus ? 'bi-pause-fill' : 'bi-play-fill', showPlayOrPauseIcon ? 'animate__fadeIn' : '']"></i>
                <div v-if="!songLoaded"><v-progress-circular indeterminate :size="44"></v-progress-circular></div>
              </div>
            </div>
          </div>
          <div class="col-md-5 bg-white mt-5">
            <v-tabs v-model="tab" color="deep-purple-accent-4" grow>
              <v-tab value="1" class="text-uppercase" :disabled="false">up next</v-tab>
              <v-tab value="2" class="text-uppercase" @click="getSongLyrics" :disabled="songLyricsStatus">lyrics</v-tab>
              <v-tab value="3" class="text-uppercase" :disabled="false">details</v-tab>
            </v-tabs>
            <v-window v-model="tab">
              <v-window-item value="1">
                <v-container fluid>
                  <!-- Playing from div -->
                  <div class="playing-from-div mb-5">
                    <div class="text-overline">playing from</div>
                    <div class="d-flex justify-content-between my-auto">
                      <h5 class="text-capitalize my-auto text-truncate" style="max-width: 250px">
                        {{ song.name }}
                      </h5>
                      <div class="d-flex my-auto gap-1">
                        <v-chip prepend-icon="mdi mdi-plus" @click="saveToPlaylist" class="my-auto text-capitalize"
                          size="small">
                          save
                        </v-chip>
                        <!-- <v-chip
                          prepend-icon="mdi mdi-flag-variant"
                          @click="showReportModal = true"
                          class="my-auto text-capitalize"
                          size="small"
                          color="red"
                          variant="flat"
                          v-if="userExist"
                        >
                          report
                        </v-chip> -->
                      </div>
                    </div>
                  </div>

                  <div class="music-list-div my-3">
                    <!-- Music list - playlist -->
                    <div v-if="getSingleSongStatus">
                      <div class="ms-3">
                        <v-switch v-model="model" color="#9C54D5" :label="model ? 'Autoplay: on' : 'Autoplay: off'"
                          hide-details></v-switch>
                      </div>
                      <!-- Only single song -->
                      <MusicListItem v-for="song in getSingleSong" :key="song.id" :song="song" @actions="doAction" />

                      <div v-if="model">
                        <div class="text-overline mt-4">similar songs of same genre</div>
                        <!-- Show songs of similar genre as playlist -->
                        <MusicListItem v-for="song in playlist" :key="song.id" :song="song" @actions="doAction" />
                      </div>

                    </div>

                    <div v-else>
                      <MusicListItem v-for="song in playlist" :key="song.id" :song="song" @actions="doAction" />
                    </div>

                  </div>
                </v-container>
              </v-window-item>
              <v-window-item value="2">
                <v-container fluid>
                  <div class="lyrics-container">
                    <pre>{{ songLyrics }}</pre>
                  </div>
                </v-container>
              </v-window-item>
              <v-window-item value="3">
                <v-container fluid>
                  <h3 class="text-capitalize">{{ song.name }}</h3>
                  <div class="text-capitalize" v-if="song.artists.length != 0">
                    <router-link v-for=" artist  in  song.artists " class="text-dark artists-link" :key="artist.id"
                      :to="'/artists/' + artist.id">{{ artist.name }}
                      <span v-if="song.artists.length > 1">,</span></router-link>
                  </div>

                  <div class="text-capitalize" v-else>No artist</div>

                  <div class="mt-4">Release date: {{ songReleaseDate }}</div>

                  <div class="description mt-4">
                    <div class="text-overline">description</div>
                  </div>
                </v-container>
              </v-window-item>
            </v-window>
          </div>
        </div>
      </div>
    </div>

    <div class="music_player_web_view_container">
      <div class="__container">
        <div class="__progress-bar-container clickable">
          <div class="__progress-bar">
            <v-slider :disabled="!songLoaded" v-model="seekTo" :min="0" :max="activeSong.duration" color="orange"
              track-color="#9C54D5" @click="setProgress"></v-slider>
          </div>
        </div>
        <div class="__details_and_control_wrapper">
          <div class="__container">
            <!-- Flex -->
            <div class="__controller_and_time">
              <div class="__controller">
                <div class="__prev clickable" @click="this.$emit('prev-song', '')">
                  <i class="bi bi-rewind-fill"></i>
                </div>
                <div @click="this.$emit('toggle-play', '')" class="__play clickable">
                  <i v-if="songLoaded" class="bi" :class="[playingStatus ? 'bi-pause-fill' : 'bi-play-fill']"></i>
                  <div v-else><v-progress-circular indeterminate :size="44"></v-progress-circular></div>
                </div>
                <div class="__next clickable" @click="this.$emit('next-song', '')">
                  <i class="bi bi-fast-forward-fill"></i>
                </div>
              </div>
              <!-- <div class="__time">0:00 / 0:00</div> -->
              <div class="__time">
                {{ songUpdatedTime }} / {{ songDuration }}
              </div>
            </div>

            <div class="__song_details clickable">
              <div class="__image">
                <img src="https://cdn.pixabay.com/photo/2015/01/20/13/13/samsung-605439_1280.jpg" alt="song thumbnail"
                  class="rounded" />
              </div>
              <div class="__details">
                <h6 class="text-truncate text-capitalize m-0 __details-song-title" style="max-width: 400px">
                  {{ songTitle }}
                </h6>
                <span class="text-truncate" style="max-width: 400px"><small class="song-artists-and-year">{{ songArtists }}, ({{ activeSongYear
                    }})</small></span>
              </div>
            </div>

            <div class="__other_options text-end">
              <div class="__volume_option clickable">
                <input type="range" id="volume" name="volume" min="0" max="1" step="0.05" v-model="songVolume" />
                <i class="bi" :class="[
      songMuted ? 'bi-volume-mute-fill' : halfVolume ? 'bi-volume-down-fill' : 'bi-volume-up-fill',
    ]
      "></i>
              </div>
              <div class="__repeat_option clickable" @click="enableOrDisableRepeat">
                <i class="bi bi-repeat" :class="repeat ? 'enabled' : 'disabled'"></i>
              </div>
              <div class="__shuffle_option clickable" @click="enableOrDisableShuffle">
                <i class="bi bi-shuffle" :class="shuffled ? 'enabled' : 'disabled'"></i>
              </div>
              <div class="__expand_icon_option clickable">
                <i :class="[
      showIcon ? 'bi-caret-up-fill' : 'bi-caret-down-fill',
    ]
      " @click="clickedExpandIcon" class="bi"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Report Modal -->
    <PopUpModal :title="'Report song'" :showModal="showReportModal" @close="updateReportModalStatus(false)">
      <template #body>
        <ReportSong :song="songDetails" />
      </template>
    </PopUpModal>

  </div>
</template>

<script>
// import { useMusicPlayer } from "@/hooks/useMusicPlayer";
import MusicListItem from "./MusicListItem.vue";
import musicMixins from "@/mixins/musicMixins";
import songActionsMixins from "@/mixins/songActionsMixins"
import PopUpModal from "../Miscellaneous/PopUpModal.vue";
import Alert from "@/components/Miscellaneous/Alert.vue";
import SubmitButton from "@/components/Miscellaneous/SubmitButton.vue";
import ratingMixins from "../../mixins/ratingMixins";
import menuChangeMixins from "@/mixins/menuChangeMixins";
import moment from "moment";
import { ref } from "vue";
import ReportSong from "./ReportSong.vue";

let seekStatus = ref(false);

export default {
  name: "MusicPlayerWebView",
  components: {
    MusicListItem,
    PopUpModal,
    Alert,
    SubmitButton,
    ReportSong

  },
  mixins: [musicMixins, ratingMixins, menuChangeMixins, songActionsMixins],
  data() {
    return {
      showIcon: false,
      showExpandedView: false,
      expandedViewClasses: ["animate__fadeInUp"],
      tab: null,
      // showReportModal: false,
      loadButton: false,
      alertDuration: 3,
      activeSong: this.song,
      reportReason: "",
      songLyrics: "",
      showRatingStar: false,
      rating: 0,
      ratingStarDivAnimationClass: ["animate__bounceIn"],
      songVolume: 1,
      songMuted: false,
      halfVolume: false,
      seekTo: 0,
      // songDurationValue: 0,
      shuffled: false,
      repeat: false,
      songToReportDetails: {
        id: null,
        name: "name",
        artists: "artists"
      },
      model: false,
      showPlayOrPauseIcon: false,
    };
  },
  props: {
    songTitle: {
      type: String,
      default: "song title",
    },
    songArtists: {
      type: String,
      default: "artists",
    },
    playingStatus: {
      type: Boolean,
      default: false,
    },
    newSongStatus: {
      type: Boolean,
      default: false,
    },
    song: {
      type: Object,
      default: {},
    },
    playlist: {
      type: Array,
      default: [],
    },
    songProgress: {
      type: Number,
      default: 0,
    },
    songUpdatedTime: {
      type: String,
      default: "00:00",
    },
    songDuration: {
      type: String,
      default: "00:00",
    },
    songLoaded: {
      type: Object,
      default: false
    }
  },
  methods: {
    clickedExpandIcon() {
      if (this.showIcon) {
        // Open expanded view - change the class
        this.expandedViewClasses.splice(
          this.expandedViewClasses.indexOf("animate__fadeOutDown"),
          1,
          "animate__fadeInUp"
        );

        console.log("open: ", this.expandedViewClasses);
        this.showIcon = false;
      } else {
        console.log(
          "index",
          this.expandedViewClasses.indexOf("animate__fadeInUp")
        );
        // Close expanded view - change the class
        this.expandedViewClasses.splice(
          this.expandedViewClasses.indexOf("animate__fadeInUp"),
          1,
          "animate__fadeOutDown"
        );
        console.log("close: ", this.expandedViewClasses);
        this.showIcon = true;
      }
    },
    async getSongLyrics() {
      this.songLyrics = await this.$store.dispatch(
        "song/getSongLyrics",
        this.activeSong.id
      );
    },
    // async reportSong(e) {
    //   e.preventDefault();

    //   this.loadButton = true;

    //   console.log("songToReportDetails: ", this.songToReportDetails);

    //   let payload = {
    //     data: {
    //       reason: this.reportReason,
    //     },
    //     id: this.songToReportDetails.id,
    //   };

    //   let response = await this.$store.dispatch("song/flagSong", payload);
    //   if (response.success) {
    //     this.$refs.alert.show("success", response.message);
    //   } else {
    //     this.$refs.alert.show("error", response.message);
    //   }
    // },
    // onComplete() {
    //   this.showReportModal = false;
    // },
    showRatingStarDiv() {
      // Check if the user is logged in
      if (this.$store.getters["auth/user_exists"]) {
        // If yes show star div
        this.showRatingStar = true;
      } else {
        // Redirect to login page
        this.$router.push({ name: "login" });
      }
    },

    async rateSong() {
      // Rate song
      let payload = {
        user_id: this.$store.getters["user/user"].id,
        song_id: this.activeSong.id,
        rating_value: this.rating,
      };
      // let response = await this.$store.dispatch("rating/rateSong", payload);
      let response = await this.rateTheSong(payload);

      if (response.success) {
        this.$refs.ratingRef.show("success", response.message);
      } else {
        this.$refs.ratingRef.show("error", response.message);
      }
    },
    setProgress() {
      // console.log(this.seekTo);
      this.$emit("seek-to", this.seekTo);
    },
    enableOrDisableShuffle() {
      this.shuffled = !this.shuffled;

      // Logic: cannot enable both shuffle and repeat at the same time so if on enabling shuffle, if repeat is already enabled then disable it and viceversa
      if (this.shuffled && this.repeat) this.repeat = false;

      this.$emit("shuffle-song", this.shuffled);
    },
    enableOrDisableRepeat() {
      this.repeat = !this.repeat;

      // Logic: cannot enable both shuffle and repeat at the same time so if on enabling shuffle, if repeat is already enabled then disable it and viceversa
      if (this.shuffled && this.repeat) this.shuffled = false;

      this.$emit("repeat-song", this.repeat);
    },
    updateSeekStatus() {
      // This will be called from parent component if the repeat option is enabled
      seekStatus.value = false;
    },
    // doAction(data) {
    //   if (data.action == "report") {
    //     // Get the active playlist
    //     let activePlaylist = this.getActivePlaylist;
    //     // console.log("active playlist", activePlaylist);

    //     // Get the song of id in data
    //     let song = activePlaylist.filter((song) => song.id === data.song_id);
    //     // console.log("song details", song);
    //     this.songToReportDetails.id = data.song_id;
    //     this.songToReportDetails.name = song[0].name;
    //     this.songToReportDetails.artists = song[0].artists.map(artist => artist.name).join(",");

    //     this.showReportModal = true;
    //   }
    // },
    thumbnailclicked() {
      this.$emit('toggle-play', '');
      this.showPlayOrPauseIcon = true;
      setTimeout(() => {
        this.showPlayOrPauseIcon = false;
      }, 700)
    }
  },
  computed: {
    userExist() {
      return this.$store.getters["auth/user_exists"];
    },
    songLyricsStatus() {
      // true - means disable lyrics tab
      // false - vice versa
      return this.activeSong.lyrics_file == null ? true : false;
    },
    activeSongYear() {
      return moment(this.activeSong.released_on).year();
    },
    songReleaseDate() {
      return moment(this.activeSong.released_on).format("DD/MM/YYYY");
    },
  },
  watch: {
    activeSong() {
      // watch active songs
      // If song changes then it show expanded music page - add show class
      if (this.expandedViewClasses.includes("animate__fadeOutDown")) {
        // this means that the expanded view is in closed state so replace class
        this.expandedViewClasses.splice(
          this.expandedViewClasses.indexOf("animate__fadeOutDown"),
          1,
          "animate__fadeInUp"
        );
        // Change show icon to false
        this.showIcon = false;
      }
    },
    song(value) {
      // when song changes set seekStatus to false
      seekStatus.value = false;
      this.activeSong = value;
    },
    change(value) {
      alert(value);
    },
    $route() {
      // When route value changes if the music details page is diaplayed then close it
      if (this.expandedViewClasses.includes("animate__fadeInUp")) {
        // Close expanded view - change the class
        this.expandedViewClasses.splice(
          this.expandedViewClasses.indexOf("animate__fadeInUp"),
          1,
          "animate__fadeOutDown"
        );
        console.log("close: ", this.expandedViewClasses);
        this.showIcon = true;
      }
    },
    // Watch song volume and update it
    songVolume(value) {
      value == 0 ? (this.songMuted = true) : (this.songMuted = false);
      value < 0.5 ? (this.halfVolume = true) : (this.halfVolume = false);
      //Update volume
      this.$emit("change-volume", value);
    },
    songProgress(value) {
      // console.log("progress: ", value);
      // const sliderEl = document.querySelector(".seek_slider");
      this.seekTo = value;
      // console.log("watch: ",this.seekTo);

      // sliderEl.style.background = `linear-gradient(to right, #f50 ${value}%, #ccc ${value}%)`;
    },
    seekTo(value) {
      // old code
      // if (this.activeSong.duration - value < 3 && !seekStatus.value) {
      //   seekStatus.value = true;
      //   // alert("trigger");
      //   this.$emit("song-ended", "");
      // }
      // console.log("seek value", this.activeSong.duration);
      // console.log(value);
      // this.$emit("seek-to", value);
    },
    model(value) {
      if (value) {
        // Enable playlist
        this.setPlayList('enable_autoplay_playlist')
      }
      else {
        // Disable playlist
        this.setPlayList('disable_autoplay_playlist')
      }
    }
  },
};
</script>

<style scoped>
.music_player_web_view_wrapper {
  display: block;
}

.music_player_web_view_container {
  position: fixed;
  width: 100%;
  bottom: 0;
  height: 80px;
  background: var(--dominant-color);
  z-index: 5;
}

.expanded-view {
  position: fixed;
  bottom: 80px;
  display: flex;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.expanded-view>* {
  min-width: 300px;
}

.expanded-view .expanded-area {
  /* background-image: linear-gradient(to right,
      #76ace6,
      var(--dominant_color),
      var(--support_color)); */
  background-color: var(--dominant-color);
  flex-grow: 1;
}

.__progress-bar-container {
  width: calc(100% - 7px);
  /* height: 3px; */
  /* background: red; */
  /* background: #fe00e9; */
  /* background: var(--support_color); */
  /* background: var(--dominant_color); */
  position: relative;
}

.__progress-bar {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 70px;
  z-index: -1;
}

.__progress-bar .v-input {
  height: 35px;
  /* height: 10px; */
  /* margin-bottom: 30px; */
  /* background: red; */
}

.__details_and_control_wrapper {
  height: 77px;
}

.__details_and_control_wrapper .__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-inline: 35px;
  height: 100%;
  color: var(--color);
}

.__details_and_control_wrapper .__container>* {
  width: 100%;
}

.__play i {
  font-size: 50px;
}

.__song_details .__image img {
  width: 40px;
  height: 40px;
  object-fit: cover;
}

.__prev i,
.__next i {
  font-size: 25px;
}

.__controller_and_time {
  display: flex;
  align-items: center;
  gap: 1em;
}

.__controller_and_time .__time {
  font-size: 12px;
}

.__controller_and_time .__controller {
  display: flex;
  align-items: center;
  gap: 0.7em;
}

.__song_details {
  display: flex;
  align-items: center;
  gap: 1em;
}

.__other_options {
  display: flex;
  align-items: center;
  gap: 1.2em;
  justify-content: flex-end;
}

.__controller_and_time,
.__other_options {
  flex: 0 0 400px;
}

.__other_options>* {
  font-size: 25px;
}

.music-list-div {
  height: 370px;
  display: flex;
  gap: 1em;
  flex-direction: column;
  overflow-y: scroll;
}

.lyrics-container {
  height: 450px;
  overflow-y: scroll;
}

.music-thumbnail {
  position: relative;
}

.music-thumbnail .rating-button-div {
  position: absolute;
  bottom: 2%;
  right: 2%;
  z-index: 5;
}

.music-thumbnail .rating-star-div,
.music-thumbnail .play-or-pause {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1em;
}

.music-thumbnail .rating-star-div {
  background: #452964ce;
  z-index: 5;
}

.close-rating-star-div {
  position: absolute;
  top: 2%;
  right: 2%;
  color: white;
  cursor: pointer;
}

.close-rating-star-div i {
  font-size: 20px;
}

.song-details {
  display: flex;
  flex-direction: column;
}

.artists-link:hover {
  text-decoration: underline;
  -webkit-text-underline-position: under;
  -ms-text-underline-position: below;
  text-underline-position: under;
}

.__volume_option {
  display: flex;
  align-items: center;
  gap: 0.5em;
}

.__volume_option:hover #volume {
  display: block;
}

#volume {
  /* background-color: white; */
  height: 3px;
  width: 100px;
  accent-color: var(--color);
  /* outline: none; */
  display: none;
}

.__shuffle_option .disabled,
.__repeat_option .disabled {
  color: gray;
}

.__shuffle_option .enabled,
.__repeat_option .enabled {
  color: var(--color);
}

@media (max-width: 992px) {
  .sidebar-area {
    display: none;
  }
}

@media (max-width: 1030px) {

  .__controller_and_time,
  .__other_options {
    flex: 0 0 300px;
  }
}

@media (max-width: 850px) {

  .__controller_and_time,
  .__other_options {
    flex: 0 0 230px;
  }
}

@media (max-width: 768px) {
  .music_player_web_view_wrapper {
    display: none;
  }
}
</style>