import apiServices from "@/services/apiServices";

// flag.js
export default {
    namespaced: true,
    state: {
        // flag state here
        songDetails: {},
        reportModalStatus: false,
    },
    mutations: {
        // flag mutations here
        setSongDetails: (state, song) => {
            state.songDetails = song
        },
        setReportModalStatus: (state, status) => {
            state.reportModalStatus = status
        }
    },
    actions: {
        // flag actions here
        async getListOfSongsFlaggedByAUser({ dispatch }) {
            try {
                let songs = await apiServices.get("/song/flagged_songs");
                return songs.data;
            } catch (error) {
                console.log("error => getListOfSongsFlaggedByAUser: ", error);
                if (error.response.status == 401) dispatch("auth/logout", null, { root: true })
            }
        },

        async getListOfAlbumsFlaggedByAUser({ dispatch }) {
            try {
                let albums = await apiServices.get("/album/my_flagged_albums");
                return albums.data;
            } catch (error) {
                console.log("error => getListOfAlbumsFlaggedByAUser: ", error);
                if (error.response.status == 401) dispatch("auth/logout", null, { root: true })
            }
        },
    },
    getters: {
        // flag getters here
    }
};