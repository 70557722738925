<template>
  <div class="app" :class="[theme == 0 ? 'dark-theme' : 'light-theme']">
    <Header v-if="!$route.meta.hideMarginals && !adminExists" />
    <AdminHeader v-if="adminExists" />

    <div class="body-wrapper">
      <div class="sidebar-area">
        <Sidebar v-if="!$route.meta.hideMarginals" :admin="this.$store.getters['auth/admin_exists']" />
      </div>
      <div class="content">
        <Content />
        <!-- <Footer v-if="!$route.meta.hideMarginals" /> -->
      </div>
    </div>

    <!-- <MusicPlayerBottom
    :mobileView="isMobileView"
    v-if="$route.meta.showMusicPlayer"
  /> -->
    <!-- <MobileBottomNav v-if="$route.meta.showMusicPlayer" /> -->

    <MusicPlayer v-if="$route.meta.showMusicPlayer" />
  </div>
</template>

<script>
import Header from "./components/Marginals/Header.vue";
import AdminHeader from "./components/Marginals/AdminHeader.vue";
import Footer from "./components/Marginals/Footer.vue";
import Sidebar from "./components/Marginals/Sidebar.vue";
import Content from "./components/Marginals/Content.vue";
import MusicPlayerBottom from "./components/Music/MusicPlayerBottom.vue";
import MobileBottomNav from "./components/Marginals/MobileBottomNav.vue";
import MusicPlayer from "./components/Music/MusicPlayer.vue";

export default {
  name: "App",
  data() {
    return {
      isMobileView: false,
    };
  },
  // Composition API setup can be added here if necessary
  components: {
    Header,
    AdminHeader,
    Footer,
    Sidebar,
    Content,
    MusicPlayerBottom,
    MobileBottomNav,
    MusicPlayer,
  },
  computed: {
    adminExists() {
      return this.$store.getters["auth/admin_exists"];
    },
    theme() {
      return this.$store.getters["user/theme"]
    },
    getMediaPreference() {
      const hasDarkPreference = window.matchMedia("(prefers-color-scheme: dark)").matches;
      console.log("hasDarkPreference: ", hasDarkPreference);
      if(hasDarkPreference) {
        // "dark-theme"
        return 0;
      }
      else {
        // "light-theme"
        return 1;
      }
    },
    setTheme(theme) {
      this.$store.commit("user/setTheme", theme)
    }
  },
  methods: {
    async getListOfAllSongs() {
      // Get list of all songs
      await this.$store.dispatch("music/getMusicList");
    },
  },
  mounted() {
    this.getListOfAllSongs();
    // Set browser theme as default theme
    //const browserTheme = this.getMediaPreference();
    //this.setTheme(browserTheme)
  },
};
</script>


<style>
.body-wrapper {
  display: flex;
  position: relative;
}

.body-wrapper>* {
  min-width: 300px;
}

.body-wrapper .sidebar-area {
  flex-grow: 1;
}

.body-wrapper .content {
  /* margin-left: var(--side-bar-width); */
  flex-grow: 2;
  margin-bottom: var(--music-player-bottom-height);
  width: 100%;
}

/* #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
} */

@media screen and (max-width: 992px) {
  .body-wrapper .sidebar-area {
    display: none;
  }

  .music-player-bottom-webview {
    display: none;
  }
}
</style>
