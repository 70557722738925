<template>
  <!-- Modal -->
  <div v-if="showModal" class="popupmodal-wrapper">
    <div class="popupmodal-container">
      <div class="popupmodal-content">
        <div class="popupmodal-header">
          <h5 class="popupmodal-title">{{ title }}</h5>
          <i class="bi bi-x-lg" @click="this.$emit('close')"></i>
        </div>
        <div class="popupmodal-body">
          <slot name="body" />
        </div>
        <div class="popupmodal-footer">
          <slot name="footer" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PopUpModal",
  data() {
    return {
      show: false,
    };
  },
  props: {
    title: {
      type: String,
      default: "title",
    },
    showModal: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
.popupmodal-wrapper {
  position: fixed;
  z-index: 15;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  background: rgba(0, 0, 0, 0.392);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;
}

.popupmodal-wrapper.sm .popupmodal-container {
  max-width: 400px;
}

.popupmodal-wrapper.md .popupmodal-container {
  max-width: 500px;
}

.popupmodal-container {
  max-width: 700px;
  width: 100%;
  max-height: 700px;
  background: rgb(255, 255, 255);
  padding: 25px;
  overflow-y: hidden;
  margin: auto;
}

.popupmodal-container::-webkit-scrollbar {
  display: none;
}

.popupmodal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 25px;
}

.popupmodal-header i {
  cursor: pointer;
}

.popupmodal-body {
  /* margin: 15px 0px; */
  /* padding: 15px 0px 20px 0px; */
  /* background: black; */
  max-height: 590px;
  overflow-y: scroll;
}

.popupmodal-body::-webkit-scrollbar {
  display: none;
}

.popupmodal-footer {
  position: relative;
  float: right;
  padding-top: 25px;
}
</style>