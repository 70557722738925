import axios from 'axios';

// Base URL for all API calls
const baseURL = "http://127.0.0.1:5000";
// const baseURL = "https://musicapi.vizamics.in";

// Create an Axios instance for API calls
const apiClient = axios.create({
    baseURL: baseURL,
    withCredentials: true, // Required for cookies (JWT and CSRF)
});

// Function to fetch and update the CSRF token
async function updateCsrfToken() {
    try {
        const response = await axios.get(baseURL + '/csrf-token', { withCredentials: true });
        const csrfToken = response.data.csrf_token;
        apiClient.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;
    } catch (error) {
        console.error('Error fetching CSRF token:', error);
        throw error; // Re-throw the error for caller to handle
    }
}

// Exported object containing methods for making API requests
export default {
    // GET request
    async get(url) {
        // await updateCsrfToken(); // Ensure CSRF token is up-to-date
        return apiClient.get('/api/v1' + url);
    },

    // POST request
    async post(url, payload) {
        // await updateCsrfToken(); // Ensure CSRF token is up-to-date
        return apiClient.post('/api/v1'+url, payload);
    },

    // PUT request
    async put(url, payload) {
        // await updateCsrfToken(); // Ensure CSRF token is up-to-date
        return apiClient.put('/api/v1' +url, payload);
    },

    // PUT request
    async patch(url, payload) {
        // await updateCsrfToken(); // Ensure CSRF token is up-to-date
        return apiClient.patch('/api/v1' +url, payload);
    },

    // DELETE request
    async delete(url) {
        // await updateCsrfToken(); // Ensure CSRF token is up-to-date
        return apiClient.delete('/api/v1'+url);
    },

    // Set the Authorization header (JWT token) for all future requests
    setAuthToken(token) {
        apiClient.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    },

    // Remove the Authorization header
    removeAuthToken() {
        delete apiClient.defaults.headers.common['Authorization'];
    }
};