<template>
  <div class="content-padding">

    <WelcomeContent :songs="songs" />

    <LatestSongs :songs="songs" @do-action="doAction" />

    <ArtistsList />

    <AlbumsList />

    <PlaylistsList />

    <!-- <div class="recommended-songs">
      <h6>Recommended songs</h6>
      <div @mousedown.left="onMouseDown" class="horizontal-scroll-div">
        <vue-horizontal
          responsive
          class="horizontal"
          ref="horizontal"
          snap="none"
          @scroll="onScroll"
        >
          <section v-for="song in songs" :key="song.id">
            <MusicCard
              :title="song.name"
              :artists="song.artists"
              @play-song="playSong(song.id)"
              @show-lyrics="showLyrics(song.id)"
            />
          </section>
        </vue-horizontal>
      </div>
    </div>
    <div class="artists">
      <h6>Top artists</h6>
      <div @mousedown.left="onScrollArtist" class="horizontal-scroll-div">
        <vue-horizontal
          responsive
          class="horizontal"
          ref="scrollartist"
          snap="none"
          @scroll="onScroll"
        >
          <section v-for="artist in artists" :key="artist.id">
            <ArtistCard :artistName="artist.name" />
          </section>
        </vue-horizontal>
      </div>
    </div> -->
  </div>

  <!-- Lyrics Modal -->
  <!-- <PopUpModal :title="'Song lyrics'" :showModal="showLyricsModal" @close="showLyricsModal = false">
    <template #body>
      <pre id="contents"></pre>
    </template>
</PopUpModal> -->


  <!-- snackbar:  if a user clicks on go to album song option, if the song is not available in any album then 
  this snackbar will be displayed at the bottom of the screen-->
  <v-snackbar v-model="snackbar" :timeout="timeout">
    This song is not present in any album

    <template v-slot:actions>
      <v-btn color="blue" variant="text" @click="snackbar = false">
        Close
      </v-btn>
    </template>
  </v-snackbar>


  <!-- Report Modal -->
  <PopUpModal title="Report song" :showModal="showReportModal" @close="updateReportModalStatus(false)">
    <template #body>
      <ReportSong :song="songDetails" />
    </template>
  </PopUpModal>

  <!-- Save to playlist modal-->
  <PopUpModal title="Save to playlist" class="md" :showModal="showSaveToPlaylistModal"
    @close="updateShowSavePlaylistModalStatus(false)">
    <template #body>
      <SaveToPlaylist @added-song="updateShowSavePlaylistModalStatus(false)" />
    </template>
  </PopUpModal>

</template>

<script>
import MusicCard from "@/components/Music/MusicCard.vue";
// import AlbumCard from "@/components/Music/AlbumCard.vue";
import ArtistCard from "@/components/Music/ArtistCard.vue";
import VueHorizontal from "vue-horizontal";
import PopUpModal from "@/components/Miscellaneous/PopUpModal.vue";
import WelcomeContent from "@/components/User/WelcomeContent.vue";
import songActionsMixins from "@/mixins/songActionsMixins";
import ReportSong from "@/components/Music/ReportSong.vue";
import LatestSongs from "@/components/HomePage/LatestSongs.vue";
import AlbumsList from "@/components/HomePage/AlbumsList.vue";
import PlaylistsList from "@/components/HomePage/PlaylistsList.vue";
import ArtistsList from "@/components/HomePage/ArtistsList.vue";
import SaveToPlaylist from "@/components/Music/SaveToPlaylist.vue";

export default {
  name: "App",
  mixins: [songActionsMixins],
  data() {
    return {
      left: 0,
      originX: 0,
      originLeft: 0,
      showLyricsModal: false,
      snackbar: false,
      timeout: 2000
    };
  },
  destroyed() {
    this.onMouseUp();
  },
  methods: {
    getSongs() {
      this.$store.dispatch("song/getSongs");
    },

    async getAlbums() {
      await this.$store.dispatch("album/getAlbums");
    },
    async getArtists() {
      await this.$store.dispatch("artist/getArtists");
    },
    async playSong(id) {
      // Fetch song using song id
      await this.$store.dispatch("song/getSong", id);
    },
    async showLyrics(id) {
      this.showLyricsModal = true;
      // Fetch song using song id
      let response = await this.$store.dispatch("song/getSongLyrics", id);
      document.getElementById("contents").textContent = response;
    },
    onScroll({ left }) {
      this.left = left;
    },
    onMouseDown(e) {
      this.originX = e.pageX;
      this.originLeft = this.left;

      window.addEventListener("mouseup", this.onMouseUp);
      window.addEventListener("mousemove", this.onMouseMove);
    },
    onScrollArtist(e) {
      this.originX = e.pageX;
      this.originLeft = this.left;

      window.addEventListener("mouseup", this.onMouseUp);
      window.addEventListener("mousemove", this.moveArtists);
    },
    onMouseUp() {
      window.removeEventListener("mouseup", this.onMouseUp);
      window.removeEventListener("mousemove", this.onMouseMove);
      window.removeEventListener("mousemove", this.moveArtists);
    },
    onMouseMove(e) {
      const offset = e.pageX - this.originX;
      const left = this.originLeft - offset;
      this.$refs.horizontal.scrollToLeft(left, "auto");
    },
    moveArtists(e) {
      const offset = e.pageX - this.originX;
      const left = this.originLeft - offset;
      this.$refs.scrollartist.scrollToLeft(left, "auto");
    },
  },
  computed: {
    songs() {
      // console.log(this.$store.getters["song/allSongs"]);
      return this.$store.getters["song/allSongs"];
    },
    albums() {
      return this.$store.getters["album/allAlbums"];
    },
    artists() {
      return this.$store.getters["artist/getAllArtists"];
    },
  },
  created() {
    this.getSongs();
    // this.getAlbums();
    this.getArtists();
  },
  components: {
    MusicCard,
    VueHorizontal,
    // AlbumCard,
    ArtistCard,
    PopUpModal,
    WelcomeContent,
    ReportSong,
    LatestSongs,
    AlbumsList,
    PlaylistsList,
    ArtistsList,
    SaveToPlaylist
  },
};
</script>

<style scoped>
/* Responsive Style */
/* section is used to control sizing, div is for the inner styling. */
@media (max-width: 640px) {
  /* .horizontal {
    margin: 0 -14px;
  }

  .horizontal >>> .v-hl-container {
    scroll-padding-left: 16px;
  }

  .horizontal >>> .v-hl-btn {
    display: none;
  }

  section {
    width: calc(100% - (16px + 16px));
    padding: 0 8px;
  }

  section:first-child {
    padding-left: 24px;
    width: calc(100% - 16px);
  }

  section:last-child {
    padding-right: 24px;
    width: calc(100% - 16px);
  }

  section:only-child {
    width: 100%;
  } */
}

@media (min-width: 640px) {
  /* section {
    width: calc((100% - (24px)) / 2);
    margin-right: 7px;
  } */
}

@media (min-width: 768px) {
  /* section {
    width: calc((100% - (2 * 24px)) / 3);
  } */
}

@media (min-width: 1024px) {
  /* section {
    width: calc((100% - (3 * 24px)) / 4);
  } */
}

@media (min-width: 1280px) {
  /* section {
    width: calc((100% - (4 * 24px)) / 5);
  } */
}
</style>