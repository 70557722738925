<template>
    <div class="mini-controller">

        <div class="song-details" @click="this.$emit('show-expanded-page')">
            <div class="song-thumbnail">
                <img src="https://cdn.pixabay.com/photo/2015/01/20/13/13/samsung-605439_1280.jpg" alt="" srcset="">
            </div>
            <div class="details">
                <h6>{{ songName }}</h6>
                <span>{{ getArtists }}</span>
            </div>
        </div>
        <div class="controller" @click="playSong">
            <i :class="[isPlayingSong ? 'bi-pause-circle' : 'bi-play-circle']" class="bi"></i>
        </div>
    </div>
</template>

<script>
export default {
    name: "MobileMiniController.vue",
    data() {
        return {
            isPlaying: false,
            artists_list: ""
        }
    },
    props: {
        songName: {
            type: String,
            default: "song name"
        },
        artists: {
            type: String,
            default: "artists"
        },
        isPlaying: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        playSong() {
            this.isPlaying = !this.isPlaying
            this.$store.dispatch("song/songPlayOrPauseController", true)
            this.$emit("play-song", "id");
        }
    },
    computed: {
        isPlayingSong() {
            return this.isPlaying
        },
        getArtists() {
            let data = this.artists.map(artist => artist.name).join(",")
            console.log(data);
            this.artists_list = data
            return this.artists_list; 
        }
    }
}
</script>

<style scoped>
.mini-controller {
    width: 100%;
    height: 60px;
    padding-inline: 30px;
    border-top: 1px solid var(--dominant_color);
    align-items: center;
    justify-content: space-between;
    position: fixed;
    bottom: 0;
    background: var(--dominant_color);
    margin-bottom: 70px;
    display: none;
    color: white;
}

.mini-controller h6 {
    font-size: 14px;
    font-weight: 500;
}

.mini-controller span {
    font-size: 12px;
}

.mini-controller .song-details {
    display: flex;
    gap: 1em;
    align-items: center;
}

.mini-controller .controller {
    font-size: 28px;
}

.mini-controller .song-thumbnail img {
    width: 40px;
    height: 40px;
    border-radius: 5px;
    object-fit: cover;
}

@media screen and (max-width: 992px) {
    .mini-controller {
        display: flex;
    }
}
</style>