// admin.js
import apiService from '@/services/apiServices';// Import your API service
import axios from "axios";


export default {
    namespaced: true,
    state: {
        // Admin state here
        app_users: [],
        tracks: [],
        albums: [],
        role_requests: [],
        notifications: [],
        missing_users: [],
        reminded_users: []
    },
    mutations: {
        // Admin mutations here
        SET_APP_USERS: (state, users) => (state.app_users = users),
        DELETE_USER: (state, id) => (state.app_users = state.app_users.filter(user => user.id !== id)),
        SET_TRACKS: (state, tracks) => (state.tracks = tracks),
        DELETE_TRACK: (state, id) => (state.tracks = state.tracks.filter(track => track.id !== id)),
        SET_ALBUMS: (state, albums) => (state.albums = albums),
        DELETE_ALBUM: (state, id) => (state.albums = state.albums.filter(album => album.id !== id)),
        SET_ROLE_REQUESTS: (state, requests) => (state.role_requests = requests),
        SET_NOTIFICATIONS: (state, notifications) => (state.notifications = notifications),
        REMOVE_NOTIFICATION: (state, payload) => (state.notifications = state.notifications.filter(notification => notification.id !== payload.id && notification.type !== payload.type)),
        SET_MISSING_USERS: (state, users) => (state.missing_users = users),
        SET_REMINDED_USERS: (state, users) => (state.reminded_users = users),
        REMOVE_MISSING_USER: (state, missing_user) => {
            state.missing_users = state.missing_users.filter(user => user.id !== missing_user.id)
            // Add user to reminded user
            state.reminded_users.push(missing_user)
        }
    },
    actions: {

        // Get statistics
        async getStatistics({ dispatch }) {
            try {
                const statistics = await apiService.get("/admin/dashboard");
                console.log("statistics: ", statistics.data);
                return statistics.data;
            } catch (error) {
                console.log("getStatistics error: ", error);
                if (error.response.status == 401) dispatch("auth/logout", null, { root: true })
            }
        },

        // Get all tracks
        async getAllTracks({ commit }) {
            const tracks = await apiService.get("/song/songs");
            // console.log("songs: ", tracks.data);
            commit("SET_TRACKS", tracks.data);
            return tracks.data;
        },

        // Delete track
        async deleteTrack({ commit }, id) {
            try {
                const response = await apiService.delete("/song/songs/" + id);
                console.log("response: ", response);
                commit("DELETE_TRACK", id)
                return {
                    success: true,
                    message: "Deleted track successfully",
                }
            } catch (error) {
                return {
                    success: false,
                    message: "Failed to delete track",
                    error: error
                }
            }
        },

        // Get all albums
        async getAllAlbums({ commit }) {
            const albums = await apiService.get("/album/albums");
            console.log("albums: ", albums.data);
            commit("SET_ALBUMS", albums.data)
        },

        // Delete album
        async deleteAlbum({ commit }, id) {
            try {
                const response = await apiService.delete("/album/albums/" + id);
                console.log("response: ", response);
                commit("DELETE_ALBUM", id)
                return {
                    success: true,
                    message: "Deleted album successfully",
                }
            } catch (error) {
                return {
                    success: false,
                    message: "Failed to delete album",
                    error: error
                }
            }
        },

        // Get Role Requests
        async getRoleRequests({ commit, dispatch }) {
            try {
                const response = await apiService.get("/admin/role-requests");
                commit("SET_ROLE_REQUESTS", response.data)
            } catch (error) {
                console.log("getRoleRequests error: ", error);
                if (error.response.status == 401) dispatch("auth/logout", null, { root: true })
            }
        },

        // Approve Role requests
        async approveRoleRequest({ commit }, id) {
            try {
                await apiService.post("/admin/approve-role-request/" + id);
                return {
                    success: true,
                    message: "Approved role request"
                }
            } catch (error) {
                console.log("approveRoleRequests Error: ", error);
                return {
                    success: false,
                    message: "Failed to approve role request",
                    error: error
                }
            }

        },

        // Deny role requests
        async denyRoleRequests({ }, id) {
            try {
                await apiService.post("/admin/deny-role-request/" + id);
                return {
                    success: true,
                    message: "Denied role request"
                }
            } catch (error) {
                console.log("denyRoleRequests Error: ", error);
                return {
                    success: false,
                    message: "Failed to deny role request",
                    error: error
                }
            }
        },

        // Admin actions here
        async getAppUsers({ commit }) {
            const app_users = await apiService.get("/admin/admin/users");
            console.log("users: ", app_users.data);
            commit("SET_APP_USERS", app_users.data);
        },

        // Get app user details
        async getAppUserDetails({ commit, dispatch }, user_id) {

            try {
                const app_user = await apiService.get("/admin/admin/users/" + user_id);
                return app_user.data;
            } catch (error) {
                console.log("getAppUserDetails error: ", error);
                if (error.response.status == 401) dispatch("auth/logout", null, { root: true })
            }

        },

        // Delete user
        async deleteAppUser({ commit }, id) {

            // const response = await apiService.delete("/admin/admin/user/" + id);
            // console.log("response: ", response);
            // commit("DELETE_USER", id)

            try {
                const response = await apiService.delete("/admin/admin/users/" + id);
                console.log("response: ", response);
                commit("DELETE_USER", id)
                return {
                    success: true,
                    message: "Deleted user successfully",
                }
            } catch (error) {
                return {
                    success: false,
                    message: "Failed to delete user",
                    error: error
                }
            }
        },

        async getAllFlaggedSongs({ dispatch }) {
            try {
                let flags = await apiService.get("/song/all_flags");
                let songs = await apiService.get("/song/songs");
                let flagged_songs = flags.data.filter(flag => flag.song_id != null);
                flagged_songs.map((f_song, index) => {
                    songs.data.map(song => {
                        if (f_song.song_id == song.id) {
                            flagged_songs[index].song = song.name;
                            flagged_songs[index].reason = flagged_songs[index].reason == "" ? "No reason" : flagged_songs[index].reason;
                        }
                    })
                })
                console.log("flagged_songs: ", flagged_songs);
                return flagged_songs;

            } catch (error) {
                console.log("getAllFlaggedSongs error: ", error);
                if (error.response.status == 401) dispatch("auth/logout", null, { root: true })
            }
        },

        async getAllFlaggedAlbums({ dispatch }) {
            try {
                let flags = await apiService.get("/song/all_flags");
                let albums = await apiService.get("/album/albums");
                let flagged_albums = flags.data.filter(flag => flag.album_id != null);

                flagged_albums.map((f_album, index) => {
                    albums.data.map(album => {
                        if (f_album.album_id == album.id) {
                            flagged_albums[index].album = album.title;
                            flagged_albums[index].reason = flagged_albums[index].reason == "" ? "No reason" : flagged_albums[index].reason;
                        }
                    })
                })

                return flagged_albums
            } catch (error) {
                console.log("getAllFlaggedSongs error: ", error);
                if (error.response.status == 401) dispatch("auth/logout", null, { root: true })
            }
        },

        // Get flag details
        async getFlagDetails({ dispatch }, id) {
            try {
                let response = await apiService.get("/song/all_flags/" + id);
                return response.data
            } catch (error) {
                console.log("getFlagDetails error: ", error);
                if (error.response.status == 401) dispatch("auth/logout", null, { root: true })
            }
        },

        // Send reminder email
        async sendReminderEmail({ }, payload) {

            let message = null;
            // console.log("emails: ", payload.emails[0]);
            try {

                for (let i = 0; i < payload.emails.length; i++) {
                    await apiService.post("/admin/send-reminder-email", { email: payload.emails[i] })
                }

                if (payload.emails.length > 1) {
                    message = "Sent reminder email successfully to selected emails"
                }
                else {
                    message = "Sent reminder email successfully to " + payload.emails[0]
                }
                return {
                    success: true,
                    message: message
                }
            } catch (error) {
                console.log("Error => sendReminderEmail => ", error);
                return {
                    success: false,
                    message: "Failed to send email",
                    error: error
                }
            }
        },

        // get notifications
        async getNotifications({ commit, dispatch }) {
            try {
                let notifications = await apiService.get("/admin/notifications");
                console.log("notifications: ", notifications.data);
                commit("SET_NOTIFICATIONS", notifications.data)
            } catch (error) {
                console.log("get Notifications error: ", error);
                if (error.response.status == 401) dispatch("auth/logout", null, { root: true })
            }
        },
        async removeNotification({ commit, dispatch }, payload) {
            try {
                await apiService.patch("/admin/remove-notification", payload);
                commit("REMOVE_NOTIFICATION", payload)
                return {
                    success: true,
                    message: "removed notification"
                }
            } catch (error) {
                console.log("removeNotification error: ", error);
                if (error.response.status == 401) dispatch("auth/logout", null, { root: true })
                return {
                    success: false,
                    message: "Failed to remove notification",
                    error: error
                }
            }
        },
        async getLogs({ dispatch }) {
            try {
                console.log("to fetch logs");
                let response = await apiService.get("/admin/logs");
                console.log("response================: ", response);
                return response.data
            } catch (error) {
                console.log("getLogs error: ", error);
                if (error.response.status == 401) dispatch("auth/logout", null, { root: true })
            }
        }
    },
    getters: {
        // Admin getters here
        all_app_users: state => state.app_users,
        all_tracks: state => state.tracks,
        all_albums: state => state.albums,
        all_role_requests: state => state.role_requests,
        all_notifications: state => state.notifications,
        all_missing_users: state => state.missing_users,
        all_reminded_users: state => state.reminded_users,
    }
};