<template>
  <!-- 
    /** alertType = "error" : "success" */
 -->

  <div v-if="showAlert" :class="Classes">
    {{ alertMessage }}
  </div>
</template>

<script>
export default {
  data() {
    return {
      showAlert: false,
      alertMessage: "alert message",
      Classes: ["alert", "animate__animated"],
    };
  },
  props: {
    // alertType: String,
    // alertMessage: String,
    // show: Boolean,
    duration: Number,
  },
  methods: {
    show(alertType, alertMessage) {
      this.alertMessage = alertMessage;
      this.Classes.unshift("animate__flipInX");

      console.log(alertType);

      if (alertType == "success") {
        // If the class list contains the class error then remove it
        if (this.Classes.includes("error")) {
          let index = this.Classes.indexOf("error");
          this.Classes.splice(index, 1);
        }
        // if classes array does not contain success class then add it
        if (!this.Classes.includes("success")) {
          // add success class
          this.Classes.unshift("success");
        }

        this.showAlert = true;
      } else if (alertType == "error") {
        // If the class list contains the class success then remove it
        if (this.Classes.includes("success")) {
          let index = this.Classes.indexOf("success");
          this.Classes.splice(index, 1);
        }
        // if classes array does not contain error class then add it
        if (!this.Classes.includes("error")) {
          // add error class
          this.Classes.unshift("error");
        }

        this.showAlert = true;
      } else {
        this.showAlert = false;
      }

      console.log(this.Classes);

      setTimeout(() => {
        // Change class after 2.4 s
        const index = this.Classes.indexOf("animate__flipInX");
        this.Classes.splice(index, 1, "animate__flipOutX"); // replace animate__flipInX with animate__flipOutX so alert closes with effect
      }, parseInt(this.duration * 1000) - 600);

      // Remove animate flip out class to show the alert, if it is called again and set show alert to false
      setTimeout(() => {
        let index = this.Classes.indexOf("animate__flipOutX");
        this.Classes.splice(index, 1);
        this.showAlert = false;

        if (alertType == "success") this.$emit("completed", true);
        else this.$emit("completed", false);

      }, parseInt(this.duration * 1000));
    },
  },
  // watch: {
  //   show(value) {
  //     if (value) {
  //       this.showAlert = true;
  //       this.Classes.unshift("animate__flipInX");

  //       if (this.alertType == "success") {
  //         // If the class list contains the class error then remove it and add success class
  //         if (this.Classes.includes("error")) {
  //           let index = this.Classes.indexOf("error");
  //           this.Classes.splice(index, 1);
  //         }
  //         // add success class
  //         this.Classes.unshift("success");
  //       } else if (this.alertType == "error") {
  //         // If the class list contains the class success then remove it and add error class
  //         if (this.Classes.includes("success")) {
  //           let index = this.Classes.indexOf("success");
  //           this.Classes.splice(index, 1);
  //         }
  //         // add error class
  //         this.Classes.unshift("error");
  //       }

  //       setTimeout(() => {
  //         // Change class after 2.4 s
  //         const index = this.Classes.indexOf("animate__flipInX");
  //         this.Classes.splice(index, 1, "animate__flipOutX"); // replace animate__flipInX with animate__flipOutX
  //       }, this.duration - 600);
  //     } else {
  //       this.showAlert = false;
  //       // Remove animate flip out class to show the alert, if it is called again
  //       let index = this.Classes.indexOf("animate__flipOutX");
  //       this.Classes.splice(index, 1);
  //     }
  //   },
  // },
};
</script>

<style scoped>
.alert {
  padding: 10px;
  margin: 20px 0px;
  border-radius: 50px;
  font-weight: 500;
  text-align: center;
}

.alert.error {
  background: rgb(255, 145, 145);
  color: rgb(150, 0, 0);
}

.alert.success {
  background: rgb(145, 255, 149);
  color: rgb(0, 150, 15);
}
</style>