// history.js
export default {
    state: {
        // history state here
    },
    mutations: {
        // history mutations here
    },
    actions: {
        // history actions here
    },
    getters: {
        // history getters here
    }
};