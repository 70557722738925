<template>
    <div class="error-page">
        <h1>Oops! Something went wrong.</h1>
        <p>We're sorry for the inconvenience. Please try again later.</p>
        <p>{{ error }}</p>
        <router-link to="/">Go Home</router-link>
    </div>
</template>

<script>
export default {
    name: 'ErrorPage',
    data() {
        return {
            error: ""
        }
    },
    mounted() {
        this.error = this.$store.state["auth/error_"]
    }
}
</script>

<style scoped>
/* your CSS styling */
</style>