<template>
    <div class="home-list" v-if="artists != ''">
        <div class="d-flex align-items-center justify-content-between">
            <h3 class="heading-3 text-capitalize mb-5">artists</h3>
            <v-chip class="see-all-chip" @click="$router.push({ name: 'artists-user' })">
                see all
            </v-chip>
        </div>
        <div v-if="loaded">
            <vue-horizontal ref="horizontal" class="horizontal" :button="true">
                <div class="artists-items" v-for="artist in artists" :key="artist.id">
                    <ArtistCard :artistName="artist.name" @clicked-artist="viewDetails(artist.id)" />
                </div>
            </vue-horizontal>
        </div>
        <div class="d-flex align-items-center justify-content-center p-5" v-else>
            <v-progress-circular indeterminate color="white" :size="60"></v-progress-circular>
        </div>
    </div>
</template>

<script>
import ArtistCard from "@/components/Music/ArtistCard.vue";
import VueHorizontal from 'vue-horizontal';

export default {
    name: "ArtistList",
    components: {
        ArtistCard,
        VueHorizontal
    },
    data() {
        return {
            artists: [],
            loaded: false
        }
    },
    methods: {
        async getArtists() {
            await this.$store.dispatch("artist/getArtists");
            this.artists = this.$store.getters["artist/getAllArtists"].slice(0, 10)
        },
        viewDetails(artist_id) {
            this.$router.push({
                name: "artists-details-user",
                params: {
                    id: artist_id,
                },
            });
        },
    },
    async mounted() {
        await this.getArtists();
        this.loaded = true
    },
}
</script>

<style scoped>
.artists-items>* {
    min-width: var(--list-item-width);
    margin-right: 10px;
}
</style>