<template>
  <ul class="list-group" @mouseenter="mouseEntered" @mouseleave="mouseLeft">
    <li
      class="list-group-item d-flex justify-content-between align-items-center my-auto user-select-none"
      role="button"
    >
      <div class="music-list-item-image-div">
        <img
          class="rounded music-list-item-image"
          :src="song.image_file == null ? 'https://cdn.pixabay.com/photo/2015/01/20/13/13/samsung-605439_960_720.jpg' : song.image_file"
          alt="music image"
        />
        <div class="music-status">
          <div
            class="active-music rounded"
            v-show="!show && checkActiveMusic(song.id) && getMusicPlayingStatus"
          >
            <i class="bi bi-volume-up"></i>
          </div>
          <i
            v-show="show"
            class="bi"
            @click="
              selectMusic(song);
            "
            :class="[
              checkActiveMusic(song.id) && getMusicPlayingStatus
                ? 'bi-pause-fill'
                : 'bi-play-fill',
            ]"
          ></i>
          <!-- <i class="bi bi-pause-fill" v-show="show" v-if="checkActiveMusic(song.id) && getMusicPlayingStatus"></i>
          <i class="bi bi-play-fill" v-show="show" @click="selectMusic(song);" v-else></i> -->
        </div>
      </div>
      <div class="text-capitalize flex-grow-1 ms-3">
        <div class="text-truncate test-song-name" style="max-width: 150px">
          {{ song.name }}
        </div>
        <small class="text-truncate">{{ artists }}</small>
      </div>

      <div class="action-or-duration-div">
        <span class="badge bg-primary rounded-pill" v-if="!show">{{
          songDuration
        }}</span>

        <v-menu>
          <template v-slot:activator="{ props }">
            <i class="bi bi-three-dots-vertical" v-if="show" v-bind="props"></i>
          </template>

          <v-list>
            <v-list-item
              v-for="(item, i) in items"
              :key="i"
              :value="i"
              @click="
                this.$emit('actions', { action: item.action, song: song })
              "
            >
              <v-list-item-title class="text-capitalize"
                ><i :class="item.icon"></i> {{ item.title }}</v-list-item-title
              >
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </li>
  </ul>

  
</template>

<script>
import musicMixins from "@/mixins/musicMixins";
import appData from "@/assets/app-data.json";

export default {
  name: "MusicListItem",
  mixins: [musicMixins],
  data() {
    return {
      getPlayStatus: false,
      songData: this.song,
      duration: null,
      show: false,
      items: appData.song_primary_actions,
    };
  },
  methods: {
    controlSong(playStatus) {
      if (playStatus) {
        // Pause song
        this.pauseSong();
      } else {
        // Play song
        this.playSong();
      }
    },
    checkUserExists() {
      // This is to show report song option if user exists
      if (this.userExist) {
        this.items = this.items.concat(appData.song_advanced_actions);
      } else {
        this.items = appData.song_primary_actions;
      }
    },
    // clickedMusicItem(id) {
    //   /**
    //    * Logic: when a song is clicked then these two values will be true so -
    //    * emit - get-songs, to set the playlist where this list item belongs to.
    //    * when the song is paused then getMusicPlayingStatus will be false,-
    //    * So restrict from setting playlist again
    //    */

    //   if (this.checkActiveMusic(id) && this.getMusicPlayingStatus) {
    //     this.$emit("set-playlist", "");
    //   }
    // },
    playSong() {
      // Play song
      // Set is playing to true
      /**
       * Logic: Change the previous song icon from pause to play,-
       * if another song is tried to play while the previous one was playing
       */

      // Emit play song to parent
      this.$emit("play-song", "");
    },
    pauseSong() {
      this.$emit("pause-song", "");
    },
    mouseEntered() {
      this.show = true;
    },
    mouseLeft() {
      this.show = false;
    },
  },
  props: {
    song: {
      type: Object,
      default: {},
    },
  },
  computed: {
    artists() {
      if (this.songData.artists == "") {
        return "No artist";
      }
      return this.songData.artists.map((artist) => artist.name).join(",");
    },
    songDuration() {
      let minutes = "0" + Math.floor(this.songData.duration / 60);
      let seconds = "0" + Math.floor(this.songData.duration - minutes * 60);
      return minutes.slice(-2) + ":" + seconds.slice(-2);
    },
    userExist() {
      return this.$store.getters["auth/user_exists"];
    }
  },
  mounted() {
    this.checkUserExists();
    // console.log("song: ", this.song);
  },
};
</script>

<style scoped>
.music-list-item-image {
  width: 50px;
  height: 50px;
  object-fit: cover;
}
.music-list-item-image-div {
  position: relative;
}

.music-list-item-image-div i,
.music-list-item-image-div .active-music {
  position: absolute;
  color: #fff;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  font-size: 24px;
}

.active-music {
  background: rgba(0, 0, 0, 0.495);
}

.list-group:hover .music-list-item-image {
  filter: brightness(50%);
}
</style>