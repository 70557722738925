// Api services
import apiServices from "@/services/apiServices";
let basePath = "/playlist"
import axios from "axios";
// playlist.js
export default {
    namespaced: true,
    state: {
        // playlist state here
        playlists: [],
        playlist: {},
        playlist_songs: [],
        showPlaylistModal: false,
        songIdToBeSaved: null
    },
    mutations: {
        // playlist mutations here
        SET_PLAYLISTS: (state, playlists) => (state.playlists = playlists),
        SET_PLAYLIST: (state, playlist) => (state.playlist = playlist),
        DELETE_PLAYLIST: (state, id) => (state.playlists = state.playlists.filter(playlist => playlist.id !== id)),
        SET_PLAYLIST_SONGS: (state, songs) => (state.playlist_songs = songs),
        SET_SAVE_TO_PLAYLIST_MODAL_STATUS: (state, status) => (state.showPlaylistModal = status),
        SET_SONG_ID_TO_BE_SAVED_TO_PLAYLIST: (state, id) => (state.songIdToBeSaved = id)
    },
    actions: {
        // playlist actions here

        // Add a playlist
        async addPlayList({ commit }, payload) {
            try {
                // console.log("playlist payload: ", payload);
                await apiServices.post(basePath + "/playlists", payload);
                return {
                    success: true,
                    message: "Added playlist successfully"
                }
            } catch (error) {

                console.log("Add playlist error: ", error);
                return {
                    success: false,
                    message: "Failed to add playlist",
                    error: error
                }

            }

        },
        // Get list of playlists
        async getListOfPlayLists({ commit, dispatch }) {
            try {
                let response = await apiServices.get(basePath + "/playlists");
                console.log(response);
                commit("SET_PLAYLISTS", response.data.playlists)
            } catch (error) {
                console.log("getListOfPlayLists error: ", error);
                if (error.response.status == 401) dispatch("auth/logout", null, { root: true })
            }
        },

        // Get a playlist
        async getPlayListDetails({ commit }, id) {
            let response = await apiServices.get(basePath + "/playlists/" + id);
            commit("SET_PLAYLIST", response.data.playlist)
        },

        // Update playlist data
        async updatePlaylist({ commit }, payload) {
            try {
                await apiServices.put(basePath + "/playlists/" + payload.id, payload.data);
                return {
                    success: true,
                    message: "Updated playlist successfully"
                }
            } catch (error) {
                console.log("Update playlist error: ", error);
                return {
                    success: false,
                    message: "Failed to update playlist",
                    error: error
                }
            }
        },

        // Add song to playlist
        async addSongToPlaylist({ commit }, payload) {

            //         ///
            //         axios.get('/csrf-token')  // where is this base url resolved 
            // .then(response => {
            //     const csrfToken = response.data.csrf_token;

            //     // Now use the token in a POST request
            //     axios.post('/api/v1/playlist/playlists/5/songs', { id: [10] }, {
            //         headers: {
            //             "Accept": "application/json",
            //             "Content-Type": "application/json",
            //             'X-CSRF-TOKEN': csrfToken,
            //         },
            //         withCredentials: true // Ensure cookies are included
            //     })
            //     .then(response => console.log(response))
            //     .catch(error => console.error(error));
            // });

            try {

                // Check if the payload data contains multiple song ids
                if (payload.data.id.length > 1) {
                    //  trying to add multiple songs
                    for (let i = 0; i < payload.data.id.length; i++) {
                        let data = {
                            id: [payload.data.id[i]]
                        }
                        await apiServices.post(basePath + "/playlists/" + payload.playlist_id + "/songs", data);
                        // It is done via apiServices
                    }
                }
                else {
                    await apiServices.post(basePath + "/playlists/" + payload.playlist_id + "/songs", payload.data)
                }
                return {
                    success: true,
                    message: "Added song to the playlist"
                }
            } catch (error) {
                console.log("Add songs to playlist error: ", error);
                return {
                    success: false,
                    message: "Failed to add songs to the playlist",
                    error: error
                }
            }
        },

        async getListOfSongsInPlaylist({ commit, dispatch }, id) {
            try {
                let response = await apiServices.get(basePath + "/playlists/" + id + "/songs");
                commit("SET_PLAYLIST_SONGS", response.data);
                return response.data;
            } catch (error) {
                console.log("getListOfSongsInPlaylist error: ", error);
                if (error.response.status == 401) dispatch("auth/logout", null, { root: true })
            }
        },

        async deletePlaylist({ commit }, id) {
            try {
                await apiServices.delete(basePath + "/playlists/" + id);
                commit("DELETE_PLAYLIST", id);
                return {
                    success: true,
                    message: "Deleted playlist successfully"
                }
            } catch (error) {
                console.log("Delete playlist error: ", error);
                return {
                    success: false,
                    message: "Failed to delete playlist",
                    error: error
                }
            }
        }
    },
    getters: {
        // playlist getters here
        all_playlists: state => state.playlists,
        playlist_details: state => state.playlist,
        all_playlist_songs: state => state.playlist_songs
    }
};