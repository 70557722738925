// Api services
import apiServices from "@/services/apiServices";
import songData from "../../../tests/testData/songs.json"
import songServices from "@/services/songServices";
import axios from "axios";

let base_path = {
    _song: "/song",
    _songs: "/song/songs"
}
// song.js
export default {
    namespaced: true,
    state: {
        // song state here
        songs: [],
        song: {},
        lyrics: null,
        user_status: null,
        stream_song: null,
        playOrPauseSong: false,
        streaming_song: null
    },
    mutations: {
        // song mutations here
        /**
         * changes in state are managed here
         */
        addSong: (state, newSong) => state.songs.unshift(newSong),
        setSongs: (state, songs) => (state.songs = songs),
        setSong: (state, song) => (state.song = song),
        updateSong: (state, updatedSong) => {
            const index = state.songs.findIndex(song => song.id === updatedSong.id);
            if (index !== -1) {
                state.songs.splice(index, 1, updatedSong)
            }
        },
        deleteSong: (state, id) => (state.songs = state.songs.filter(song => song.id !== id)),
        getSongsByGenre: (state, songs) => (state.songs = songs),
        getSongsByArtist: (state, songs) => (state.songs = songs),
        getSongLyrics: (state, lyrics) => (state.lyrics = lyrics),
        flagSong: (state, id) => {

            state.songs.map(song => {
                if (song.id === id) {
                    return { ...state.songs, flag: true }
                }
            })
        },
        unFlagSong: (state, id) => {

            state.songs.map(song => {
                if (song.id === id) {
                    return { ...state.songs, flag: false }
                }
            })
        },
        getSongUserStatus: (state, user_status) => (state.user_status = user_status),
        getStreamSongFile: (state, stream_song) => (state.stream_song = stream_song),
        getNextSong: (state, song) => {
            state.song = song;
        },
        getPreviousSong: (state, song) => {
            state.song = song;
        },
        getShuffledSong: (state, song) => {
            state.song = song;
        },
        playOrPause: (state, value) => (state.playOrPauseSong = value),
        setLyrics: (state, lyrics) => (state.lyircs = lyrics),
    },
    actions: {
        // song actions here

        // Play or Pause Song Controller
        songPlayOrPauseController({ commit }, value) {
            commit("playOrPause", value)
        },

        // Stream music
        async streamSong({ }, payload) {
            try {
                let response = await axios.get("http://127.0.0.1:5000/api/v1/song/songs/file/" + payload.id, {
                    // responseType: 'blob',
                    headers: {
                        'Range': `bytes=${payload.start}-${payload.end}`,
                    },
                    responseType: 'arraybuffer',
                })

                const byteArray = new Uint8Array(response.data);
                let song = URL.createObjectURL(new Blob([byteArray], { type: 'text/plain' }));
                // console.log("song: ", song);
                return song;
            } catch (error) {
                console.log("error => streamSong: ", error);
            }
        },
        // Add song
        async addSong({ commit }, data) {
            try {
                await apiServices.post(base_path._songs, data)
                commit("addSong", data);
                return {
                    success: true,
                    message: "Uploaded song successfully"
                }
            } catch (error) {
                return {
                    success: false,
                    message: "Failed to upload song",
                    error: error
                }
            }
        },

        // Get all songs list
        async getSongs({ commit }) {

            try {

                // Real data
                const songs = await apiServices.get(base_path._songs);

                if (songs.data != null) {
                    for (let i = 0; i < songs.data.length; i++) {
                        if (songs.data[i].image_file != null) {
                            const byteCharacters = atob(songs.data[i].image_file);
                            const byteNumbers = new Array(byteCharacters.length);
                            for (let i = 0; i < byteCharacters.length; i++) {
                                byteNumbers[i] = byteCharacters.charCodeAt(i);
                            }
                            const byteArray = new Uint8Array(byteNumbers);
                            songs.data[i].image_file = URL.createObjectURL(new Blob([byteArray], { type: 'image/jpeg' }));
                        }
                        else {
                            songs.data[i].image_file = null
                        }
                    }
                }

                // console.log("songs==========", songs);

                commit("setSongs", songs.data)

                // Dummy data
                // const songs = songData;
                // console.log(songs);
                // commit("setSongs", songs.data)

            } catch (error) {
                console.log(error);
            }

        },

        // Get a song by id
        async getSong({ commit }, id) {
            // Real data
            const song = await songServices.getSongById(id);

            // console.log("song: ", song);
            // const song = await apiServices.get(`${base_path._songs}/${id}`);
            // console.log(song.data);
            commit("setSong", song.data);

            // Dummy data
            // let song;

            // for(let i=0 ; i<songData.data.length; i++) {
            //     if(songData.data[i].id == id) {
            //         song = songData.data[i]
            //     }
            // }
            // console.log("song", song);
            // commit("setSong", song)
        },

        // Get song details
        async getSongDetails({ commit }, id) {
            // Real data
            const song = await songServices.getSongById(id);
            // console.log("song :", song);
            return song.data;
        },

        // Get added song details
        async getAddedSongDetails({ commit }, id) {
            const song = await apiServices.get(`${base_path._songs}/${id}`);
            console.log("song: ", song);
            return song.data
        },

        // Update a song
        async updateSong({ commit }, payload) {
            try {
                await apiServices.put(`${base_path._songs}/${payload.id}`, payload.data);
                commit("updateSong", payload);
                return {
                    success: true,
                    message: "Updated song successfully"
                }
            } catch (error) {
                console.log("Update song error: ", error);
                return {
                    success: false,
                    message: "Failed to update song",
                    error: error
                }
            }
        },

        // Delete a song
        async deleteSong({ commit }, id) {
            try {
                await apiServices.delete(`${base_path._songs}/${id}`);
                commit("deleteSong", id);
                return {
                    success: true,
                    message: "Deleted song successfully"
                }
            } catch (error) {
                console.log(error);
                return {
                    success: false,
                    message: "Internal error",
                    error: error
                }
            }
        },

        // Get song by genre
        async getSongsByGenre({ commit }, genreName) {
            const songs = await apiServices.get(`${base_path._songs}/${genreName}`);
            commit("getSongByGenre", songs);
        },

        // Get song by artist
        // async getSongsByArtist({ commit }, artistName) {
        //     try {
        //         const songs = await apiServices.get(`${base_path._songs}/artist/${artistName}`);
        //         return songs.data
        //     } catch (error) {
        //         console.log("getSongsByArtist error: ", error);
        //     }
        //     // commit("getSongsByArtist", songs)
        // },
        async getSongsByArtist({ commit }, artistId) {
            try {
                const songs = await apiServices.get(`${base_path._songs}/artist/${artistId}`);
                return songs.data
            } catch (error) {
                console.log("getSongsByArtist error: ", error);
            }
            // commit("getSongsByArtist", songs)
        },

        // Dowload song file
        async downloadSong({ commit }, id) {
            const response = await apiServices.get(`${base_path._songs}/download/${id}`);
            console.log(response);
        },

        // Get lyrics
        async getSongLyrics({ commit }, id) {
            try {
                const lyrics = await apiServices.get(`${base_path._songs}/lyrics/${id}`);
                commit("getSongLyrics", lyrics);
                if (lyrics.data == null) {
                    return "No lyrics"
                }
                else {
                    return lyrics.data
                }
            } catch (error) {
                console.log("Fetch lyrics error: ", error);
                return "No lyrics"
            }
        },

        // Update song lyrics
        async updateLyricsFile({ }, payload) {
            try {
                await songServices.updateLyrics(payload.id, payload.data);
                return {
                    success: true,
                    message: "Updated lyrics successfully"
                }
            } catch (error) {
                console.log("updateLyricsFile error: ", error);
                return {
                    success: false,
                    message: "Failed to update lyrics",
                    error: error
                }
            }
        },

        // Flag song
        async flagSong({ commit }, payload) {
            try {
                await songServices.flagSong(payload);
                return {
                    success: true,
                    message: "Reported successfully"
                }
                // commit("flagSong", payload.id);
            } catch (error) {
                console.log("flagSong error: ", error);
                return {
                    success: false,
                    message: "Failed to flag song",
                    error: error
                }
            }
        },

        // Unflag song
        async unFlagSong({ commit }, payload) {
            try {
                console.log("payload: ", payload);
                await apiServices.post(`${base_path._songs}/${payload.song_id}/unflag`, payload.data);
                commit("unFlagSong", payload.song_id);
                return {
                    success: true,
                    message: "Unflagged song successfully"
                }
            } catch (error) {
                return {
                    success: false,
                    message: "Failed to unflag song",
                    error: error
                }
            }
        },

        // Get song user status
        async getSongUserStatus({ commit }, id) {
            const user_status = await apiServices.get(`${base_path._songs}/${id}/user_stats`);
            commit("getSongUserStatus", user_status)
        },

        // Get stream song file
        async getStreamSongFile({ commit }, id) {
            try {

                let response = await apiServices.get(base_path._songs + "/file/" + id);
                console.log("response: ", response.data);

                let song = null;

                const byteCharacters = atob(response.data);
                const byteNumbers = new Array(byteCharacters.length);
                for (let i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                const byteArray = new Uint8Array(byteNumbers);
                song = URL.createObjectURL(new Blob([byteArray], { type: 'text/plain' }));
                commit("getStreamSongFile", song);

            } catch (error) {
                console.log("streamSong error: ", error);
            }

        },

        // Get next song
        async getNextSong({ commit, state }, id) {
            let index = state.songs.findIndex(song => song.id === id);
            index++;
            console.log("index: ", index);
            if (index > state.songs.length - 1) index = 0;
            // Get song of the current index
            let song = state.songs[index];
            const nextSong = await apiServices.get(`${base_path._songs}/${song.id}`);
            commit("getNextSong", nextSong.data);
        },

        // Get previous song
        async getPreviousSong({ commit, state }, id) {
            let index = state.songs.findIndex(song => song.id === id);
            index--;
            if (index < 0) index = state.songs.length - 1;
            // Get song of the current index
            let song = state.songs[index];
            const prevSong = await apiServices.get(`${base_path._songs}/${song.id}`);
            commit("getPreviousSong", prevSong.data);
        },

        // Shuffle song
        async getShuffledSong({ commit, state }, id) {
            // Get songs length
            let numberOfSongs = state.songs.length;
            let randomIndex;
            randomIndex = Math.floor(Math.random() * numberOfSongs);
            let song;

            song = state.songs[randomIndex];

            if (song.id == id) {
                randomIndex = randomIndex + 1;
                if (randomIndex > state.songs.length - 1) randomIndex = 0;

                song = state.songs[randomIndex];

            }

            const songDetails = await apiServices.get(`${base_path._songs}/${song.id}`);
            commit("getShuffledSong", songDetails.data)
        },
        async getCreatorsListOfSongs({ dispatch }) {
            try {
                const songs = await apiServices.get(base_path._songs + "/created");
                return {
                    success: true,
                    message: "Fetched list of songs",
                    data: songs.data
                }
            } catch (error) {
                console.log(error);
                if (error.response.status == 401) { dispatch("auth/logout", null, { root: true }) }

                return {
                    success: false,
                    message: "Internal error",
                    data: [],
                    error: error
                }
            }
        },

        async getListOfSongsInAlbum({ }, albumId) {
            const songs = await apiServices.get(base_path._songs);
            return songs.data.filter(song => song.album_id == albumId)
        }
    },
    getters: {
        // song getters here
        allSongs: state => state.songs,
        song: state => state.song,
        songLyrics: state => state.lyrics,
        songUserStatus: state => state.user_status,
        streamingSong: state => state.stream_song,
        playorPauseController: state => state.playOrPauseSong
    }
};