// auth.js
// store/modules/auth.js
import router from '@/router';
import Cookies from 'js-cookie';
//import { apiClient1 } from '@/axios-setup';
import apiServices from '@/services/apiServices';
import axios from 'axios';

export default {
    namespaced: true,
    // state: {
    //     user_status: false,
    //     admin_logged_in: false,
    //     user_role: null,
    //     role_requested_status: false,
    //     error_: null
    // },

    // mutations: {
    //     SET_USER_STATUS(state, status) {
    //         state.user_status = status;
    //     },
    //     SET_ADMIN_LOGGED_IN(state, status) {
    //         state.admin_logged_in = status;
    //     },
    //     SET_USER_ROLE_DATA(state, roleObj) {
    //         state.user_role = roleObj.role;
    //         state.role_requested_status = roleObj.role_requested;
    //     },
    //     SET_ERROR(state, error) {
    //         state.error_ = error;
    //     },
    //     CLEAR_AUTH_STATE(state) {
    //         state.user_status = false;
    //         state.admin_logged_in = false;
    //         state.user_role = null;
    //         state.role_requested_status = false;
    //         state.error_ = null;
    //     }
    // },

    state: {
        token: null,
        httpOnlyCookie: null,
        user_status: false,
        admin_logged_in: false,
        user_role: null,
        role_requested_status: false,
    },

    mutations: {
        SET_TOKEN(state, token) {
            state.token = token;
        },
        CLEAR_AUTH_STATE(state) {
            state.currentUser = null;
            state.token = null;
        },
        SET_USER(state, status) {
            state.user_status = status
        },
        SET_ADMIN_LOGGED_IN(state, status) {
            state.admin_logged_in = status
        },
        SET_USER_ROLE_DATA(state, roleObj) {
            state.user_role = roleObj.role;
            state.role_requested_status = roleObj.role_requested
        }
    },

    actions: {
        async login({ commit, dispatch }, credentials) {

            try {
                let response = await apiServices.post('/user/login', credentials);
                // console.log("response: ", response);
                console.log(response);

                // Cookies.set("token", response.data.access_token);
                // apiService.setAuthToken(response.data.access_token);
                // sessionStorage.setItem("token", response.data.access_token);
                let detailsResponse = await dispatch("user/getUserDetails", null, { root: true });
                console.log(detailsResponse);
                if (detailsResponse.success) {
                    commit("SET_USER", true);
                }
                else {
                    this.logout()
                    throw new Error(detailsResponse.message)
                }
                return {
                    success: true,
                    message: "Logged in successfully"
                }
            } catch (error) {
                console.log("login error: ", error);
                return {
                    success: false,
                    message: "Failed to login",
                    error: error.response.data.message
                }
            }

            // const url = 'http://127.0.0.1:5000/api/v1/user/login';
            // const data = {
            //     username_or_email: "vizdemotester1@gmail.com",
            //     password: "app@App12345"
            // };

            // // Replace 'your_csrf_token_here' with the actual CSRF token value
            // let response = await axios.get('http://127.0.0.1:5000/csrf-token',  { withCredentials: true });
            // const csrfToken = response.data.csrf_token;
            // console.log("Received csrToken: ", csrfToken);
            // axios.defaults.headers.common['X-CSRF-TOKEN'] =  csrfToken;
            // axios.post(url, credentials, {
            //     headers: {
            //         'Content-Type': 'application/json',
            //         'X-CSRF-TOKEN': csrfToken, // Use the correct CSRF token variable
            //     },
            //     withCredentials: true // This ensures cookies are sent with the request
            // });
            // const response = await apiServices.post("/user/login", credentials);
            // console.log("login response: ", response);

            // const response = await persistantAxios.get("http://127.0.0.1:5000/csrf-token", {withCredentials: true})

            // console.log("response: ", response);

            // let loginResponse = await persistantAxios.post('http://127.0.0.1:5000/api/v1/user/login', credentials, {
            //     withCredentials: true,
            //     headers: {
            //         'X-CSRFToken': response.headers['x-csrftoken']
            //     }
            // })

            // console.log("login response : ", loginResponse);
            // fetch(url, {
            //     method: 'POST',
            //     headers: {
            //         'Content-Type': 'application/json',
            //         'X-CSRF-Token': csrfToken,
            //         withCredentials: true
            //     },
            //     credentials: 'include', // Required for cookies to be sent and received
            //     body: JSON.stringify(data)
            // }).then(response => response.json())
            // .then(data => console.log(data)).catch(error => console.error('Error:', error))


            // console.log("login methods called !!") //run y
            // updateCsrfToken().then(async()=> {
            // console.log(apiClient1.defaults.headers.common['X-CSRF-TOKEN'])
            // const response = await apiClient1.post('/api/v1/user/login', credentials);
            // console.log("login methods call completed !!")
            // console.log("Login response: ", response.data);
            // commit("SET_USER_STATUS", true);
            // }

            // call upate csfr //
            // const response = await apiClient1.post('/api/v1/user/login', credentials);
            // console.log("login response: ", response);
            // console.log("login methods call completed !!")

            // const response = await apiServices.post('/user/login', credentials);


            // Now fetching user details
            //let detailsResponse = await apiClient1.get("/api/v1/user/users/details");
            //console.log("User details response: ", detailsResponse.data);

            // if (detailsResponse.data) {
            //     commit("SET_USER_STATUS", true);
            //     commit("SET_USER_ROLE_DATA", detailsResponse.data);
            //     router.push("/dashboard");
            // } else {
            //     commit("SET_ERROR", "Failed to fetch user details");
            // }

            // return { success: true, message: "Logged in successfully" };

        },

        async adminLogin({ commit, dispatch }, payload) {

            try {
                let response = await apiServices.post('/user/login', payload);
                // sessionStorage.setItem("token", response.data.access_token);
                // Cookies.set("token", response.data.access_token);
                // apiServices.setAuthToken(response.data.access_token);

                let detailsResponse = await dispatch("user/getUserDetails", null, { root: true });
                // console.log(detailsResponse);
                if (detailsResponse.success) {
                    commit("SET_ADMIN_LOGGED_IN", true);
                }
                else {
                    this.logout()
                    throw new Error(detailsResponse.message)
                }
                // sessionStorage.setItem("token", response.data.access_token)
                // commit('SET_CURRENT_USER', response)
                return {
                    success: true,
                    message: "Logged in successfully"
                }

            } catch (error) {
                console.log(error);
                return {
                    success: false,
                    message: "Failed to login",
                    error:  error.response.data.message
                }
            }
        },

        async logout({ commit }) {
            // try {

            //     const response = await apiClient1.post("/api/v1/user/logout");
            //     console.log("Logout response: ", response);
            //     commit("CLEAR_AUTH_STATE");
            //     router.push("/login");
            //     return { success: true, message: "Logged out successfully" };
            // } catch (error) {
            //     console.error('Logout error:', error);
            //     return { success: false, message: "Internal error", error: error };
            // }

            try {

                let response = await apiServices.post('/user/logout');
                console.log(response);
                // apiServices.removeAuthToken();
                // Cookies.remove("token");
                commit("SET_USER", false);
                commit("SET_ADMIN_LOGGED_IN", false);
                commit("user/SET_USER", null, { root: true });
                router.push("/")
                return {
                    success: true,
                    message: "Logged out successfully"
                }
            } catch (error) {
                return {
                    success: false,
                    message: "Internal error",
                    error: error
                }
            }
        },

        // async register({ commit }, userData) {
        //     try {
        //         const response = await apiClient1.post('/api/v1/user/register', userData);
        //         commit("SET_USER_STATUS", true);
        //         router.push("/dashboard");
        //         return { success: true, message: "Registered successfully" };
        //     } catch (error) {
        //         console.error('Registration error:', error);
        //         commit("SET_ERROR", error.message);
        //         return { success: false, message: "Failed to register", error: error };
        //     }
        // }

        async register({ commit }, userData) {
            try {
                const response = await apiServices.post('/user/register', userData);
                apiServices.setAuthToken(response.data.token);
                return {
                    success: true,
                    message: "Registered successfully"
                }
            } catch (error) {
                console.error('Registration error:', error);
                return {
                    success: false,
                    message: "Failed to register",
                    error: error
                }
            }
        },
    },

    // getters: {
    //     isAuthenticated(state) {
    //         return state.user_status;
    //     },
    //     getUserRole(state) {
    //         return state.user_role;
    //     },
    //     getError(state) {
    //         return state.error_;
    //     }
    // }
    getters: {
        isAuthenticated(state) {
            return !!state.currentUser && !!state.token;
        },
        access_token: state => state.token,
        user_exists: state => state.user_status,
        admin_exists: state => state.admin_logged_in,
        role: state => state.user_role,
        requested_role: state => state.role_requested_status
    }
};
