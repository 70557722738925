<template>
    <div class="mobile-nav">
        <div class="home">
            <i class="bi bi-house-fill"></i>
            <span>home</span>
        </div>
        <div class="browse">
            <i class="bi bi-browser-safari"></i>
            <span>browse</span>
        </div>
        <div class="search">
            <i class="bi bi-search"></i>
            <span>search</span>
        </div>
        <div class="account">
            <i class="bi bi-person-circle"></i>
            <span>login</span>
        </div>
    </div>
</template>

<script>
export default {
    name: "MobileBottomNav"
}
</script>

<style scoped>
.mobile-nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    bottom: 0;
    width: 100%;
    padding-inline: 30px;
    height: 70px;
    background: rgb(255, 255, 255);
    border-top: 1px solid var(--dominant_color);
}

.mobile-nav > * {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.mobile-nav span {
    font-size: 12px;
    text-transform: capitalize;
}

.mobile-nav i {
    font-size: 20px;
}
</style>