export default {
    computed: {
        songDetails() {
            return this.$store.state.flag.songDetails
        },
        showReportModal() {
            return this.$store.state.flag.reportModalStatus
        },
        showSaveToPlaylistModal() {
            return this.$store.state.playlist.showPlaylistModal
        },
        songIdToBeSavedToPlaylist() {
            return this.$store.state.playlist.songIdToBeSaved
        }
    },
    methods: {
        async doAction(data) {
            
            if(data.action == "report") {
                
                // Get song details
                let song = await this.$store.dispatch("song/getSongDetails", data.song.id);
                this.$store.commit("flag/setSongDetails", song);
                this.updateReportModalStatus(true)
            }

            if(data.action == "gotoalbum") {
                console.log("data: ", data.song);
                if(data.song.album_id != null) {
                    this.$router.push({
                        name: "album-details-user",
                        params: {
                            id: data.song.album_id
                        }
                    })
                }
                else {
                    // this.updateSnackBar(true)
                    this.snackbar = true;
                }
            }

            if(data.action == "gotoartist") {
                this.$router.push({
                    name: "artists-details-user",
                    params: {
                        id: data.song.artists[0].id
                    }
                })
            }

            if(data.action == "savetoplaylist") {
                if(this.$store.getters["auth/user_exists"]) {
                    this.updateShowSavePlaylistModalStatus(true);
                    this.updateSongIdToBeSavedToPlaylist(data.song.id);
                }
                else {
                    this.updateShowSavePlaylistModalStatus(false);
                    this.$router.push({
                        name: "login"
                    })
                }
            }
        },
        // updateSnackBar(status) {
        //     return status;
        // },
        updateReportModalStatus(status) {
            this.$store.commit("flag/setReportModalStatus", status)
        },
        updateShowSavePlaylistModalStatus(status) {
            this.$store.commit("playlist/SET_SAVE_TO_PLAYLIST_MODAL_STATUS", status)
        },
        updateSongIdToBeSavedToPlaylist(song_id) {
            this.$store.commit("playlist/SET_SONG_ID_TO_BE_SAVED_TO_PLAYLIST", song_id);
        }
    }
}