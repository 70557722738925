<template>
  <header class="web">
    <h4>Admin dashboard</h4>

    <!-- <div class="search">
      <input
        type="search"
        class="search"
        style="font-family: bootstrap-icons, 'Montserrat', sans-serif"
        placeholder="&#xF52A;  Search for songs, artists, albums..."
        id="search"
        name="search"
      />
    </div> -->
    <div class="search">
      <!-- <input
        type="search"
        class="search"
        style="font-family: bootstrap-icons, 'Montserrat', sans-serif"
        placeholder="&#xF52A;  Search for songs, artists, albums..."
        id="search"
        name="search"
      /> -->
      <Search />
    </div>
    <nav>
      <div class="d-flex align-items-center gap-2">

        <v-menu v-model="menu" :close-on-content-click="true" location="end">
          <template v-slot:activator="{ props }">
            <div class="clickable mt-1 me-3 position-relative" v-bind="props">
              <i class="bi bi-bell-fill"></i>
              <span v-if="notifications != ''"
                class="position-absolute top-0 start-100 translate-middle p-1 bg-warning border border-light rounded-circle">
                <span class="visually-hidden">New alerts</span>
              </span>
            </div>
          </template>

          <v-card min-width="300" min-height="300">
            <v-list class="p-2">
              <v-list-subheader class="text-uppercase fw-bold d-flex align-items-center gap-4"><i
                  class="bi bi-bell-fill"></i>
                <span>notifications</span> <span class="badge bg-primary">{{ notifications.length
                  }}</span></v-list-subheader>
              <div v-if="notifications != ''">
                      <v-virtual-scroll :height="300" :items="notifications">
                        <template v-slot:default="{ item }">
                          <v-list-item :prepend-icon="item.type == 'register' ? 'bi bi-person-plus-fill' : ''"
                            :subtitle="item.name" class="border-bottom my-1"
                            :title="item.type == 'register' ? 'new registration' : 'notification'"
                            @click="notificationClicked(item.id, item.type)"></v-list-item>
                        </template>
                      </v-virtual-scroll>
                    </div>
                    <div class="d-flex align-items-center justify-content-center" v-else>
                      No notifications
                    </div>
            </v-list>

            <!-- <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn variant="text" @click="menu = false">
                Cancel
              </v-btn>
              <v-btn color="primary" variant="text" @click="menu = false">
                Save
              </v-btn>
            </v-card-actions> -->
          </v-card>
        </v-menu>

        <span class="text-lowercase admin-username">{{ username }}</span>
        <!-- <img class="rounded-circle" width="30" height="30" src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png" alt="avatar"> -->
        <v-menu class="admin-menu" v-model="adminmenu">
          <template v-slot:activator="{ props }">
            <div class="clickable d-flex align-items-center gap-2" v-bind="props">
              <img class="rounded-circle img-thumbnail" width="30" height="30"
                src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png" alt="avatar">
              <i class="bi bi-caret-down-fill mt-1"></i>
            </div>
          </template>

          <v-list>

            <!-- <v-list-item>
              <v-list-item-title class="text-capitalize">
                <router-link class="nav-link" to="/admin/all-tracks">Tracks</router-link>
              </v-list-item-title>
            </v-list-item>

            <v-list-item>
              <v-list-item-title class="text-capitalize">
                <router-link class="nav-link" to="/admin/albums">Albums</router-link>
              </v-list-item-title>
            </v-list-item> -->

            <v-list-item>
              <v-list-item-title class="text-capitalize">
                <router-link class="nav-link" @click="logout" to="/logout"><i class="bi bi-box-arrow-left"></i>
                  logout</router-link>
              </v-list-item-title>
            </v-list-item>

          </v-list>

        </v-menu>
      </div>
      <!-- <router-link class="nav-link" to="/admin/all-tracks">Tracks</router-link>
      <router-link class="nav-link" to="/admin/albums">Albums</router-link>
      <router-link class="nav-link" @click="logout" to="/logout">logout</router-link> -->
    </nav>
  </header>

  <header class="mobile">

    <div class="d-flex gap-3 align-items-center">
      <i style="font-size: 24px;" @click="openDrawer" class="bi bi-list"></i>
      <div class="text-truncate h4 mt-2" style="max-width: 100px;">Admin dashboard</div>
    </div>

    <div v-if="showSearchField" class="search mobile">
      <Search />
      <i class="bi bi-x-lg text-white clickable" @click="showSearchField = false"></i>
    </div>

    <!-- <div class="search">
      <input
        type="search"
        class="search"
        style="font-family: bootstrap-icons, 'Montserrat', sans-serif"
        placeholder="&#xF52A;  Search for songs, artists, albums..."
        id="search"
        name="search"
      />
    </div> -->
    <nav>
      <div class="d-flex align-items-center gap-2">
        <div>
          <i style="font-size: 18px;" @click="showSearchField = true" class="bi bi-search"></i>
        </div>
        <span class="text-lowercase">{{ username }}</span>
        <!-- <img class="rounded-circle" width="30" height="30" src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png" alt="avatar"> -->
        <v-menu class="admin-menu" v-model="adminmenumobile">
          <template v-slot:activator="{ props }">
            <div class="clickable d-flex align-items-center gap-2" v-bind="props">
              <div class="position-relative">
                <img class="rounded-circle img-thumbnail" width="30" height="30"
                  src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                  alt="avatar">

                <span v-if="notifications != ''"
                  class="position-absolute top-0 start-100 translate-middle p-1 bg-warning border border-light rounded-circle">
                  <span class="visually-hidden">New alerts</span>
                </span>

              </div>
              <i class="bi bi-caret-down-fill"></i>
            </div>
          </template>

          <v-list>

            <!-- <v-list-item>
              <v-list-item-title class="text-capitalize">
                <router-link class="nav-link" to="/admin/all-tracks">Tracks</router-link>
              </v-list-item-title>
            </v-list-item>

            <v-list-item>
              <v-list-item-title class="text-capitalize">
                <router-link class="nav-link" to="/admin/albums">Albums</router-link>
              </v-list-item-title>
            </v-list-item> -->

            <v-list-item>
              <v-menu v-model="mobilemenu" :close-on-content-click="true" location="end">
                <template v-slot:activator="{ props }">
                  <div class="clickable mt-1 me-3 position-relative text-capitalize" v-bind="props">
                    notifications
                    <span v-if="notifications != ''"
                      class="position-absolute top-0 start-100 translate-middle p-1 bg-warning border border-light rounded-circle">
                      <span class="visually-hidden">New alerts</span>
                    </span>
                  </div>
                </template>

                <v-card min-width="300" min-height="300">
                  <v-list class="p-2">
                    <v-list-subheader class="text-uppercase fw-bold d-flex align-items-center gap-4"><i
                        class="bi bi-bell-fill"></i> <span>notifications</span> <span class="badge bg-primary">{{
          notifications.length }}</span></v-list-subheader>
                    <div v-if="notifications != ''">
                      <v-virtual-scroll :height="300" :items="notifications">
                        <template v-slot:default="{ item }">
                          <v-list-item :prepend-icon="item.type == 'register' ? 'bi bi-person-plus-fill' : ''"
                            :subtitle="item.name" class="border-bottom my-1"
                            :title="item.type == 'register' ? 'new registration' : 'notification'"
                            @click="notificationClicked(item.id, item.type)"></v-list-item>
                        </template>
                      </v-virtual-scroll>
                    </div>
                    <div class="d-flex align-items-center justify-content-center" v-else>
                      No notifications
                    </div>
                  </v-list>

                  <!-- <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn variant="text" @click="menu = false">
                Cancel
              </v-btn>
              <v-btn color="primary" variant="text" @click="menu = false">
                Save
              </v-btn>
            </v-card-actions> -->
                </v-card>
              </v-menu>
            </v-list-item>
            <v-list-item>
              <v-list-item-title class="text-capitalize">
                <router-link class="nav-link" @click="logout" to="/logout"><i class="bi bi-box-arrow-left"></i>
                  logout</router-link>
              </v-list-item-title>
            </v-list-item>

          </v-list>

        </v-menu>
      </div>
      <!-- <router-link class="nav-link" to="/admin/all-tracks">Tracks</router-link>
      <router-link class="nav-link" to="/admin/albums">Albums</router-link>
      <router-link class="nav-link" @click="logout" to="/logout">logout</router-link> -->
    </nav>
  </header>

  <NavDrawer class="animate__animated" :class="drawerClasses" v-if="showDrawer"
    :admin="this.$store.getters['auth/admin_exists']" />

  <!-- <nav v-for="menu in navMenu" :key="menu.id">
      <router-link to="{{menu.link}}">{{ menu.name }}</router-link>
    </nav> -->
</template>

<script>
import { ref } from "vue";
import Search from "@/components/Miscellaneous/Search.vue";
import NavDrawer from "@/components/Marginals/NavDrawer.vue"
const show = ref(true);

export default {
  name: "App",
  components: {
    NavDrawer,
    Search
  },
  data() {
    return {
      showDrawer: false,
      drawerClasses: [],
      showSearchField: false,
      menu: false,
      mobilemenu: false,
      adminmenu: false,
      adminmenumobile: false,
      notifications_: [
        { "title": "new notification", subtitle: "new user registered" },
        { "title": "new notification", subtitle: "new user registered" },
        { "title": "new notification", subtitle: "new user registered" },
        { "title": "new notification", subtitle: "new user registered" },
        { "title": "new notification", subtitle: "new user registered" },
        { "title": "new notification", subtitle: "new user registered" },
        { "title": "new notification", subtitle: "new user registered" },
        { "title": "new notification", subtitle: "new user registered" },
        { "title": "new notification", subtitle: "new user registered" },
        { "title": "new notification", subtitle: "new user registered" },
      ]
    }
  },
  computed: {
    userExist() {
      return this.$store.getters["auth/user_exists"];
    },
    adminExist() {
      return this.$store.getters["auth/admin_exists"];
    },
    username() {
      return this.$store.getters["user/user"].username
    },
    show() {
      return show;
    },
    notifications() {
      return this.$store.getters["admin/all_notifications"]
    }
  },
  methods: {
    async logout() {
      let response = await this.$store.dispatch("auth/logout");
      if (response.success) {
        // this.$router.push("/");
        console.log("logged out");
      }
    },
    // Get notifications
    async getNotifications() {
      await this.$store.dispatch("admin/getNotifications")
    },
    openDrawer() {
      // change drawer class
      if (!this.showDrawer) {
        // this.drawerClasses.splice(
        //   this.drawerClasses.indexOf("animate__slideInLeft"),
        //   1,
        //   "animate__slideOutLeft"
        // );
        this.drawerClasses.pop();
        this.drawerClasses.push("animate__slideInLeft");
        this.showDrawer = true;
      }
      else {
        this.drawerClasses.pop();
        this.drawerClasses.push("animate__slideOutLeft");
        setTimeout(() => {
          this.showDrawer = false;
        }, 290)
      }
    },
    hideNotification() {
      if (window.innerWidth > 1024) {
        this.mobilemenu = false;
        this.adminmenumobile = false;
      }
      else {
        this.menu = false;
        this.adminmenu = false;
      }
    },
    async notificationClicked(id, type) {
      if (type == "register") {
        // Go to user details and remove item from notification list
        let response = await this.$store.dispatch("admin/removeNotification", { id: id, type: type })
        // console.log("response: =================", response);
        if (response.success) {
          this.getNotifications();
          this.$router.push({
            name: "user details",
            params: {
              id: id
            }
          })
        }
        else {
          console.log("error: ", response.message);
        }
      }
    }
  },
  watch: {
    $route() {
      if (this.drawerClasses.includes("animate__slideInLeft")) {
        this.drawerClasses.pop();
        this.drawerClasses.push("animate__slideOutLeft");
        setTimeout(() => {
          this.showDrawer = false;
        }, 290)
      }
    }
  },
  async mounted() {
    await this.getNotifications();
    window.addEventListener("resize", this.hideNotification)
  },
  unmounted() {
    window.removeEventListener('resize', this.hideNotification)
  }
};
</script>

<style scoped>
/* header {
  padding: 20px 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--dominant_color);
  color: white;
  z-index: 2;
} */

header.web,
header.mobile {
  padding: 20px 25px;
  height: 75px;
  justify-content: space-between;
  align-items: center;
  background: var(--dominant-color);
  color: var(--color);
  z-index: 2;
  user-select: none;
}

header.web {
  display: flex;
  position: sticky;
  top: 0;
}

header.web>* {
  min-width: 300px;
}

.admin-menu {
  position: relative;
  z-index: 5;
}

nav {
  text-align: end;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

nav .nav-link {
  display: inline-block;
  text-transform: capitalize;
}

nav .nav-link:hover {
  color: var(--accent-color);
}

nav a {
  font-weight: 400;
}

nav a.router-link-exact-active {
  font-weight: bold;
  border-bottom: 2px solid var(--dominant-color);
}

header nav>* {
  margin-inline: 10px;
  color: white;
}

header.mobile {
  display: none;
  width: 100%;
}

.animate__slideInLeft,
.animate__slideOutLeft {
  animation-duration: 0.3s;
}

.search.mobile {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1em;
  background: var(--dominant-color);
}

@media (min-width: 992px) {
  .search.mobile {
    display: none;
  }
}

@media (max-width: 1020px) {
  header.web {
    display: none;
  }

  header.mobile {
    display: flex;
    position: sticky;
    top: 0;
  }
}

/* .search {
  padding: 5px 15px 10px 15px;
  width: 350px;
  outline: none;
  border: none;
  border-radius: 50px;
  position: relative;
  display: flex;
  align-items: center;
  font-size: 14px;
} */
/* .search::placeholder {
  font-size: 14px;
} */
</style>