<template>
  <div>
    <button id="submit-button" type="submit" class="bttn dominant">
      <span>{{ buttonName }}</span>
    </button>
  </div>
</template>

<script>
export default {
  name: "SubmitButton",
  data() {
    return {
      buttonName: this.buttonText,
      submitButton: this.ref,
      loadButton: this.load
    };
  },
  props: {
    buttonText: {
      type: String,
      default: "button",
    },
    ref: {
      type: String
    },
    load: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    startLoad() {
      let button = document.querySelector("#submit-button");
      button.setAttribute("disabled", "disabled");
      button.innerHTML = "submitting...";
    },
    stopLoad() {
      let button = document.querySelector("#submit-button");
      button.removeAttribute("disabled");
      button.innerHTML = this.buttonText;
    },
  },
  watch: {
    load(value) {
      if (value) {
        this.startLoad()
      }
      else {
        this.stopLoad()
      }
    }
  }
};
</script>

<style scoped></style>