<template>
  <header class="web">
    <div class="app-name">
      <h4 style="color: var(--color)">Music App</h4>
    </div>

    <div class="search">
      <!-- <input
        type="search"
        class="search"
        style="font-family: bootstrap-icons, 'Montserrat', sans-serif"
        placeholder="&#xF52A;  Search for songs, artists, albums..."
        id="search"
        name="search"
      /> -->
      <Search />
    </div>
    <nav>
      <router-link v-if="!userExist" class="nav-link" to="/login/admin">Admin</router-link>
      <router-link v-if="!userExist" class="nav-link" to="/login">login</router-link>
      <router-link v-if="!userExist" class="nav-link" to="/register">register</router-link>
      <div class="d-flex align-items-center gap-2" v-if="userExist">
        <span class="text-lowercase username web">{{ username }}</span>
        <!-- <img class="rounded-circle" width="30" height="30" src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png" alt="avatar"> -->
        <v-menu>
          <template v-slot:activator="{ props }">
            <div class="clickable d-flex align-items-center gap-2" v-bind="props">
              <img class="rounded-circle img-thumbnail" width="30" height="30"
                src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png" alt="avatar">
              <i class="bi bi-caret-down-fill"></i>
            </div>
          </template>

          <v-list>

            <v-list-item v-if="userExist && isNormalUser">
              <v-list-item-title class="text-capitalize">
                <!-- general user menu -->
                <router-link class="nav-link" to="/creator-account">become a
                  creator</router-link>
              </v-list-item-title>
            </v-list-item>

            <v-list-item v-if="userExist && !isNormalUser">
              <v-list-item-title class="text-capitalize">
                <v-menu class="creator-menu" open-on-hover location="start">
                  <template v-slot:activator="{ props }">
                    <span class="clickable" v-bind="props"><i style="font-size: 12px" class="bi bi-caret-left-fill"></i>
                      Create</span>
                  </template>

                  <v-list>
                    <v-list-item v-for="(item, i) in items" :key="i" :value="i">
                      <v-list-item-title class="text-capitalize">
                        <router-link class="text-dark" :to="item.link">
                          <i :class="item.icon"></i> {{ item.title }}
                        </router-link>
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-list-item-title>
            </v-list-item>

            <v-list-item>
              <v-list-item-title class="text-capitalize">
                <router-link v-if="userExist" class="nav-link" to="/profile"><i class="bi bi-person-circle"></i>
                  profile</router-link>
              </v-list-item-title>
            </v-list-item>

            <v-list-item>
              <v-list-item-title class="text-capitalize">
                <router-link v-if="userExist" class="nav-link" @click="logout" to="/logout"><i
                    class="bi bi-box-arrow-left"></i>
                  logout</router-link>
              </v-list-item-title>
            </v-list-item>

          </v-list>

        </v-menu>
      </div>

    </nav>
  </header>

  <!-- Mobile header -->
  <header class="mobile">

    <div class="d-flex gap-3 align-items-center">
      <i style="font-size: 24px;" @click="openDrawer" class="bi bi-list"></i>
      <div class="app-name fs-5">Music App</div>
    </div>

    <div v-if="showSearchField" class="search mobile">
      <Search />
      <i style="color: var(--color);" class="bi bi-x-lg clickable" @click="showSearchField = false"></i>
    </div>

    <!-- Actions -->
    <div class="d-flex justify-content-end align-items-center gap-3">
      <div>
        <i style="font-size: 18px;" @click="showSearchField = true" class="bi bi-search"></i>
      </div>
      <div v-if="!userExist">
        <v-menu>
          <template v-slot:activator="{ props }">
            <div class="clickable d-flex align-items-center gap-2" v-bind="props">
              <img class="rounded-circle img-thumbnail" width="30" height="30"
                src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png" alt="avatar">
              <i class="bi bi-caret-down-fill"></i>
            </div>
          </template>

          <v-list>

            <v-list-item>
              <v-list-item-title class="text-capitalize">
                <router-link v-if="!userExist" class="nav-link" to="/login/admin">Admin</router-link>
              </v-list-item-title>
            </v-list-item>

            <v-list-item>
              <v-list-item-title class="text-capitalize">
                <router-link v-if="!userExist" class="nav-link" to="/login">login</router-link>
              </v-list-item-title>
            </v-list-item>

            <v-list-item>
              <v-list-item-title class="text-capitalize">
                <router-link v-if="!userExist" class="nav-link" to="/register">register</router-link>
              </v-list-item-title>
            </v-list-item>

          </v-list>

        </v-menu>
      </div>

      <div class="d-flex align-items-center gap-2" v-if="userExist">
        <!-- <span class="text-lowercase username web">{{ username }}</span> -->
        <!-- <img class="rounded-circle" width="30" height="30" src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png" alt="avatar"> -->
        <v-menu>
          <template v-slot:activator="{ props }">
            <div class="clickable d-flex align-items-center gap-2" v-bind="props">
              <img class="rounded-circle img-thumbnail" width="30" height="30"
                src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png" alt="avatar">
              <i class="bi bi-caret-down-fill"></i>
            </div>
          </template>

          <v-list>

            <v-list-item>
              <v-list-item-title>
                <span class="text-capitalize username mobile">Hi, {{ username }}</span>
              </v-list-item-title>
            </v-list-item>

            <v-list-item v-if="userExist && isNormalUser">
              <v-list-item-title class="text-capitalize">
                <!-- general user menu -->
                <router-link class="nav-link" to="/creator-account">become a
                  creator</router-link>
              </v-list-item-title>
            </v-list-item>

            <v-list-item v-if="userExist && !isNormalUser">
              <v-list-item-title class="text-capitalize">
                <v-menu class="creator-menu" location="start">
                  <template v-slot:activator="{ props }">
                    <span class="clickable" v-bind="props"><i style="font-size: 12px" class="bi bi-caret-left-fill"></i>
                      Create</span>
                  </template>

                  <v-list>
                    <v-list-item v-for="(item, i) in items" :key="i" :value="i">
                      <v-list-item-title class="text-capitalize">
                        <router-link class="text-dark" :to="item.link">
                          <i :class="item.icon"></i> {{ item.title }}
                        </router-link>
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-list-item-title>
            </v-list-item>

            <v-list-item>
              <v-list-item-title class="text-capitalize">
                <router-link v-if="userExist" class="nav-link" to="/profile"><i class="bi bi-person-circle"></i>
                  profile</router-link>
              </v-list-item-title>
            </v-list-item>

            <v-list-item>
              <v-list-item-title class="text-capitalize">
                <router-link v-if="userExist" class="nav-link" @click="logout" to="/logout"><i
                    class="bi bi-box-arrow-left"></i>
                  logout</router-link>
              </v-list-item-title>
            </v-list-item>

          </v-list>

        </v-menu>
      </div>
    </div>
  </header>

  <NavDrawer class="animate__animated" :class="drawerClasses" v-if="showDrawer"
    :admin="this.$store.getters['auth/admin_exists']" />


</template>

<script>
import { ref } from "vue";
import Search from "@/components/Miscellaneous/Search.vue";
import NavDrawer from "@/components/Marginals/NavDrawer.vue"

const show = ref(true);

export default {
  name: "App",
  components: {
    Search,
    NavDrawer
  },
  data() {
    return {
      items: [
        { title: "Song", icon: "bi bi-music-note", link: "/create/songs" },
        { title: "Album", icon: "bi bi-disc-fill", link: "/create/albums" },
        {
          title: "Artist",
          icon: "bi bi-person-heart",
          link: "/create/artists?role=creator",
        },
      ],
      showDrawer: false,
      drawerClasses: [],
      showSearchField: false
    };
  },
  computed: {
    userExist() {
      return this.$store.getters["auth/user_exists"];
    },
    username() {
      return this.$store.getters["user/user"].username
    },
    isNormalUser() {
      let user = this.$store.getters["user/user"];
      if (user != null && user.role == "Normal") {
        return true;
      }
    },
    show() {
      return show;
    },
  },
  methods: {
    async logout() {
      let response = await this.$store.dispatch("auth/logout");
      if (response.success) {
        // this.$router.push("/")
        console.log("logged out");
      }
    },
    openDrawer() {
      // change drawer class
      if (!this.showDrawer) {
        // this.drawerClasses.splice(
        //   this.drawerClasses.indexOf("animate__slideInLeft"),
        //   1,
        //   "animate__slideOutLeft"
        // );
        this.drawerClasses.pop();
        this.drawerClasses.push("animate__slideInLeft");
        this.showDrawer = true;
      }
      else {
        this.drawerClasses.pop();
        this.drawerClasses.push("animate__slideOutLeft");
        setTimeout(() => {
          this.showDrawer = false;
        }, 290)
      }
    }
  },
  watch: {
    $route() {
      if (this.drawerClasses.includes("animate__slideInLeft")) {
        this.drawerClasses.pop();
        this.drawerClasses.push("animate__slideOutLeft");
        setTimeout(() => {
          this.showDrawer = false;
        }, 290)
      }
    }
  }
};
</script>

<style scoped>
.creator-menu {
  position: relative;
  z-index: 2;
}

header.web,
header.mobile {
  padding: 20px 25px;
  height: 75px;
  justify-content: space-between;
  align-items: center;
  background: var(--dominant-color);
  color: white;
  z-index: 2;
  user-select: none;
}

header.web {
  display: flex;
  position: sticky;
  top: 0;
}

header.mobile {
  position: relative;
}

.search.mobile {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  gap: 1em;
  background: var(--dominant-color);
}

header.web>* {
  min-width: 300px;
}

nav {
  text-align: end;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

nav .nav-link {
  display: inline-block;
  text-transform: capitalize;
}

nav .nav-link:hover {
  color: var(--accent-color);
}

nav a {
  font-weight: 400;
}

nav a.router-link-exact-active {
  font-weight: bold;
  border-bottom: 2px solid var(--support_color);
}

header.web nav>* {
  margin-inline: 10px;
  /* color: white; */
  color: var(--color);
}

.search {
  flex-grow: 1;
  display: flex;
  justify-content: center;
}

.animate__slideInLeft,
.animate__slideOutLeft {
  animation-duration: 0.3s;
}

header.mobile {
  display: none;
  width: 100%;
  color: var(--color);
}

/* .username.web {
  display: flex;
}

.username.mobile {
  display: none;
} */

@media (min-width: 992px) {
  .search.mobile {
    display: none;
  }
}

@media (max-width: 992px) {
  header.web {
    display: none;
  }

  header.mobile {
    display: flex;
    position: sticky;
    top: 0;
  }
}

/* @media (max-width: 460px) {
  .username.web {
    display: none;
  }

  .username.mobile {
    display: flex;
  }
} */

/* .search {
  display: block;
  padding-inline: 15px;
  width: 350px;
  height: 34px;
  outline: none;
  border: none;
  border-radius: 50px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  background: white;
}

.search::placeholder {
  font-size: 14px;
}

@media screen and (max-width: 992px) {
  nav,
  .search {
    display: none;
  }
} */
</style>