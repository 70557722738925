<template>
    <div class="menu-item" :class="active ? 'active': ''" @click="$emit('clicked', '')">
        <i :class="icon"></i>  {{ item }}
    </div>
</template>

<script>
export default {
    name: "MenuItem",
    data() {
        return {
            // active: false
        }
    },
    props: {
        item: {
            type: String,
            default: "menu item"
        },
        icon: {
            type: String,
            default: ""
        },
        active: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        // clicked() {
        //     this.active = true
        // }
    },
}
</script>

<style scoped>
.menu-item {
    padding: 10px 20px;
    border-radius: 10px;
    margin-bottom: 10px;
}

.menu-item.active {
  background: var(--accent-color);
  color: white;
}

.menu-item:hover {
  background: var(--accent-color);
  color: white;
}

</style>