<template>
    <div class="artist-details search-item">
        <div class="d-flex gap-3">
            <div>
                <img class="search-item-image"
                    src="https://cdn.pixabay.com/photo/2015/05/07/11/02/guitar-756326_960_720.jpg" alt="">
            </div>
            <div>
                <div class="text-truncate search-item-heading text-capitalize">
                    {{ artist.name }}
                </div>
                <div class="d-flex align-items-center text-capitalize text-truncate" style="max-width: 500px;">
                    <span class="search-item-sub-heading">{{ artist.type }}</span>
                    <span><i class="bi bi-dot"></i></span>
                    <span class="search-item-sub-heading" v-if="artist.genre != null">{{ artist.genre }}</span>
                    <span class="search-item-sub-heading" v-else>no genre</span>
                    <span><i class="bi bi-dot"></i></span>
                    <span class="search-item-sub-heading" v-if="artist.bio != null">{{ artist.bio }}</span>
                    <span class="search-item-sub-heading" v-else>no bio</span>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    name: "artist component",
    props: {
        artist: Object
    }
}
</script>