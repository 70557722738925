// services/ratingService.js
import apiServices from './apiServices';

const ratingEndpoint = '/rating';

export default {
    // Rate a song
    rateSong(payload) {
        return apiServices.post(`${ratingEndpoint}/song_rating`, payload);
    },

    // Get ratings for a song
    getSongRatings(songId) {
        return apiServices.get(`${ratingEndpoint}/song_ratings/${parseInt(songId)}`);
    },

    // Get all song ratings
    getAllSongRatings() {
        return apiServices.get(`${ratingEndpoint}/song_ratings`);
    },

    // Get all album ratings
    getAllAlbumRatings() {
        return apiServices.get(`${ratingEndpoint}/album_ratings`);
    },

    // Get average rating for a song
    getAverageSongRating(songId) {
        return apiServices.get(`${ratingEndpoint}/song/${songId}/average_rating`);
    },

    // Rate an album
    rateAlbum(payload) {
        return apiServices.post(`${ratingEndpoint}/album_rating`, payload);
    },

    // Get ratings for an album
    getAlbumRatings(albumId) {
        return apiServices.get(`${ratingEndpoint}/album_ratings/${albumId}`);
    },

    // Get average rating for an album
    getAverageAlbumRating(albumId) {
        return apiServices.get(`${ratingEndpoint}/album/${albumId}/average_rating`);
    },

    // Rate a playlist
    ratePlaylist(playlistId, rating) {
        return apiServices.post(`${ratingEndpoint}/playlist_rating`, { playlistId, rating });
    },

    // Get ratings for a playlist
    getPlaylistRatings(playlistId) {
        return apiServices.get(`${ratingEndpoint}/playlist_ratings/${playlistId}`);
    },

    // Get average rating for a playlist
    getAveragePlaylistRating(playlistId) {
        return apiServices.get(`${ratingEndpoint}/playlist/${playlistId}/average_rating`);
    },

    // Additional rating-related API calls can be added here as needed
};
