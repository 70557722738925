<template>
    <div class="search-item">
        <div class="d-flex gap-3 align-items-center text-secondary">
            <div>
                <div class="magnifier-icon">
                    <i class="bi bi-search"></i>
                </div>
            </div>
            <div>
                <div class="text-truncate search-item-heading text-capitalize">
                    {{ query }}
                </div>
                <!-- <div class="d-flex align-items-center text-capitalize text-truncate" style="max-width: 500px;">
                <span class="search-item-sub-heading">{{ album.type }}</span>
                <span><i class="bi bi-dot"></i></span>
                <span class="search-item-sub-heading d-flex gap-1 align-items-center">
                    <div>
                        <v-rating v-model="rating" color="yellow-darken-3" size="x-small" density="compact"
                            half-increments readonly></v-rating>
                    </div>
                    <div>{{ rating }}/5</div>
                </span>
            </div> -->
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "SearchQueryComponent",
    props: {
        query: String
    }

}
</script>
<style scoped>
.magnifier-icon {
    width: 50px;
    height: 50px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>
