<template>
  <aside class="sidebar">
    <router-link v-for="(menu, index) in menus" :key="index" class="nav-link" :to="menu.link">
      <MenuItem :item="menu.name" :icon="menu.icon" :active="menuLink == menu.link ? true : false"
        @clicked="setMenu(menu.link)" />
    </router-link>
  </aside>
</template>

<script>
import appData from "@/assets/app-data.json";
import menuChangeMixins from "@/mixins/menuChangeMixins";
import MenuItem from "@/components/Miscellaneous/MenuItem.vue";

export default {
  mixins: [menuChangeMixins],
  components: {
    MenuItem
  },
  data() {
    return {
      sidebarMenus: appData.user_side_bar_menus,
      active: false,
      activeMenuIndex: 0,
      menu_name: null
    };
  },
  props: {
    admin: Boolean,
  },
  methods: {
    // identifyRole() {
    //   if (this.adminExist) {
    //     this.sidebarMenus = appData.admin_side_bar_menus;
    //   } else {
    //     this.sidebarMenus = appData.user_side_bar_menus;
    //   }
    // },
    activateMenu(index) {
      this.activeMenuIndex = index;
    }
  },
  computed: {
    adminExist() {
      return this.$store.getters["auth/admin_exists"]
    },
    path() {
      return this.$route.path
    },
    menus() {
      return this.adminExist ? appData.admin_side_bar_menus : appData.user_side_bar_menus;
    }
  },
  mounted() {
    // this.identifyRole();
    console.log("route: ", this.$route);
    // if(this.$route.href != this.menuLink) this.setMenu(this.$route.href)
    if (this.path != this.menuLink) this.setMenu(this.path)
  },
  watch: {
    admin(value) {
      if (value) {
        this.sidebarMenus = appData.admin_side_bar_menus;
      } else {
        this.sidebarMenus = appData.user_side_bar_menus;
      }
    },
    $route(value) {
      this.menu_name = value.href;
      this.setMenu(value.href);
    }
  },
};
</script>

<style scoped>
.sidebar {
  /* background: var(--support_color); */
  width: var(--side-bar-width);
  padding: 20px;
  min-height: 100vh;
  color: var(--color);
  text-transform: capitalize;
  z-index: 5;
  /* border-right: 1px solid var(--secondary-color); */
  user-select: none;
}

.sidebar .nav-link.active {
  background: var(--accent-color);
  border-radius: 10px;
  /* display: flex;
  align-items: center;
  gap: 1em; */
}

/* .sidebar .nav-link:hover {
  background: var(--dominant_color);
} */

@media screen and (max-width: 992px) {
  .sidebar {
    display: none;
  }
}
</style>