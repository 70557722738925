<template>
  <div class="music_player_mobile_view_wrapper">
    <div :class="expandedViewClasses" class="expanded-view animate__animated">
      <!-- <div class="sidebar-area invisible">
        
      </div> -->
      <div class="expanded-area-mobile">

        <div class="close-expanded-view-icon">
          <i class="bi bi-x-lg fs-3" @click="clickedExpandIcon"></i>
        </div>

        <div class="music-mobile-container">
          <div class="d-flex align-items-center justify-content-center">
            <div class="music-thumbnail">
              <img class="rounded" src="https://cdn.pixabay.com/photo/2015/01/20/13/13/samsung-605439_1280.jpg"
                alt="music thumbnail" />
              <div class="rating-button-div">
                <v-chip class="text-capitalize" prepend-icon="mdi mdi-star" size="small" color="primary" variant="flat"
                  @click="showRatingStarDiv">
                  rate this songs
                </v-chip>
              </div>

              <!-- Rating section -->
              <div class="rating-star-div text-center animate_animated" v-if="showRatingStar"
                :class="ratingStarDivAnimationClass">
                <Alert :duration="alertDuration" ref="ratingRef" @completed="showRatingStar = false" />

                <div class="close-rating-star-div" @click="showRatingStar = false">
                  <i class="bi bi-x-lg"></i>
                </div>

                <div class="text-h2 mt-5 text-warning">
                  {{ rating }}
                  <span class="text-h6 ml-n3">/5</span>
                </div>
                <v-rating v-model="rating" color="orange-lighten-1" half-increments hover @click="rateSong"></v-rating>
              </div>

              <div class="play-or-pause" @click="thumbnailclicked" v-ripple>
                <i v-if="songLoaded && showPlayOrPauseIcon" style="font-size: 6em;"
                  class="bi clickable text-white animate__animated"
                  :class="[playingStatus ? 'bi-pause-fill' : 'bi-play-fill', showPlayOrPauseIcon ? 'animate__fadeIn' : '']"></i>
                <div v-if="!songLoaded"><v-progress-circular indeterminate :size="44"></v-progress-circular></div>
              </div>

            </div>
          </div>

          <!-- Music details -->
          <div style="color: var(--color);">
            <h6 class="text-truncate text-capitalize fs-1 m-0 song-title-mobile" style="max-width: 300px">
              {{ songTitle }}
            </h6>
            <span class="text-truncate fs-4" style="max-width: 400px"><small class="song-artists-and-year-mobile">{{ songArtists }}, ({{ activeSongYear
                }})</small></span>
          </div>

          <!-- Music controller -->
          <div class="music-controller-mobile">
            <div class="progress-bar">
              <v-slider v-model="seekTo" :min="0" :max="activeSong.duration" color="orange" track-color="#9C54D5"
                @click="setProgress"></v-slider>

              <div class="d-flex justify-content-between">
                <span class="song-time">{{ songUpdatedTime }}</span>
                <span class="song-time">{{ songDuration }}</span>
              </div>
            </div>


            <div style="color: var(--color);" class="d-flex align-items-center justify-content-center gap-5">

              <div class="__shuffle_option_mobile clickable" @click="enableOrDisableShuffle">
                <i class="bi bi-shuffle" :class="shuffled ? 'enabled' : 'disabled'"></i>
              </div>

              <div class="d-flex align-items-center gap-3">
                <div class="clickable" @click="this.$emit('prev-song', '')">
                  <i class="bi bi-rewind-fill" style="font-size: 30px;"></i>
                </div>
                <div @click="this.$emit('toggle-play', '')" class="clickable">
                  <i v-if="songLoaded" class="bi" style="font-size: 60px;"
                    :class="[playingStatus ? 'bi-pause-circle-fill' : 'bi-play-circle-fill']"></i>
                  <div v-else><v-progress-circular indeterminate :size="44"></v-progress-circular></div>
                </div>
                <div class="clickable" @click="this.$emit('next-song', '')">
                  <i class="bi bi-fast-forward-fill" style="font-size: 30px;"></i>
                </div>
              </div>

              <div class="__repeat_option_mobile clickable" @click="enableOrDisableRepeat">
                <i class="bi bi-repeat" :class="repeat ? 'enabled' : 'disabled'"></i>
              </div>
            </div>
          </div>
        </div>

        <!-- Details section -->
        <div class="details-section animate__animated"
          :class="showDetails ? 'expand animate__slideInUp' : collapseDetails ? 'animate__slideInDown' : ''">
          <v-tabs v-model="tab" :color="showDetails ? 'deep-purple-accent-4' : ''" grow>
            <v-tab value="1" class="text-uppercase" :class="showDetails ? '' : 'tabs'" @click="viewDetails()" :disabled="false">up next</v-tab>
            <v-tab value="2" class="text-uppercase" :class="songLyricsStatus ? 'tab-disabled' : showDetails ? '' : 'tabs'" @click="getSongLyrics(); viewDetails();"
              :disabled="songLyricsStatus">lyrics</v-tab>
            <v-tab value="3" class="text-uppercase" :class="showDetails ? '' : 'tabs'" @click="viewDetails()" :disabled="false">details</v-tab>
          </v-tabs>
          <v-window v-model="tab">
            <v-window-item value="1">
              <v-container fluid>
                <!-- Playing from div -->
                <div class="playing-from-div mb-5">
                  <div class="text-overline">playing from</div>
                  <div class="d-flex justify-content-between my-auto">
                    <h5 class="text-capitalize my-auto text-truncate" style="max-width: 250px">
                      {{ song.name }}
                    </h5>
                    <div class="d-flex my-auto gap-1">
                      <v-chip prepend-icon="mdi mdi-plus" @click="saveToPlaylist" class="my-auto text-capitalize"
                        size="small">
                        save
                      </v-chip>
                    </div>
                  </div>
                </div>

                <div class="music-list-div-mobile my-3">
                  <!-- Music list - playlist -->
                  <div v-if="getSingleSongStatus">
                    <div class="ms-3">
                      <v-switch v-model="model" color="#9C54D5" :label="model ? 'Autoplay: on' : 'Autoplay: off'"
                        hide-details></v-switch>
                    </div>
                    <!-- Only single song -->
                    <MusicListItem v-for="song in getSingleSong" :key="song.id" :song="song" @actions="doAction" />

                    <div v-if="model">
                      <div class="text-overline mt-4">similar songs of same genre</div>
                      <!-- Show songs of similar genre as playlist -->
                      <MusicListItem v-for="song in playlist" :key="song.id" :song="song" @actions="doAction" />
                    </div>

                  </div>

                  <div v-else>
                    <MusicListItem v-for="song in playlist" :key="song.id" :song="song" @actions="doAction" />
                  </div>

                </div>
              </v-container>
            </v-window-item>
            <v-window-item value="2">
              <v-container fluid>
                <div class="lyrics-container-mobile">
                  <pre>{{ songLyrics }}</pre>
                </div>
              </v-container>
            </v-window-item>
            <v-window-item value="3">
              <v-container fluid>
                <h3 class="text-capitalize">{{ song.name }}</h3>
                <div class="text-capitalize" v-if="song.artists.length != 0">
                  <router-link v-for=" artist  in  song.artists " class="text-dark artists-link" :key="artist.id"
                    :to="'/artists/' + artist.id">{{ artist.name }}
                    <span v-if="song.artists.length > 1">,</span></router-link>
                </div>

                <div class="text-capitalize" v-else>No artist</div>

                <div class="mt-4">Release date: {{ songReleaseDate }}</div>

                <div class="description mt-4">
                  <div class="text-overline">description</div>
                </div>
              </v-container>
            </v-window-item>
          </v-window>
        </div>

      </div>
    </div>

    <div class="music_player_mobile_view_container"
      :class="showDetails ? 'top animate__animated animate__fadeInUp' : ''">
      <!-- <div class="d-flex aling-items-center justify-content-between"> -->
      <!-- <div class="__progress-bar-container clickable">
          <div class="__progress-bar">
            <v-slider v-model="seekTo" :min="0" :max="activeSong.duration" color="orange" track-color="#9C54D5"
              @click="setProgress"></v-slider>
          </div>
        </div> -->
      <!-- <div class="__details_and_control_wrapper">
          <div class="__container">
            
            <div class="__controller_and_time">
              <div class="__controller">
                <div class="__prev clickable" @click="this.$emit('prev-song', '')">
                  <i class="bi bi-rewind-fill"></i>
                </div>
                <div @click="this.$emit('toggle-play', '')" class="__play clickable">
                  <i class="bi" :class="[playingStatus ? 'bi-pause-fill' : 'bi-play-fill']"></i>
                </div>
                <div class="__next clickable" @click="this.$emit('next-song', '')">
                  <i class="bi bi-fast-forward-fill"></i>
                </div>
              </div>
              <div class="__time">
                {{ songUpdatedTime }} / {{ songDuration }}
              </div>
            </div>

            <div class="__song_details clickable">
              <div class="__image">
                <img src="https://cdn.pixabay.com/photo/2015/01/20/13/13/samsung-605439_1280.jpg" alt="song thumbnail"
                  class="rounded" />
              </div>
              <div class="__details">
                <h6 class="text-truncate text-capitalize m-0" style="max-width: 400px">
                  {{ songTitle }}
                </h6>
                <span class="text-truncate" style="max-width: 400px"><small>{{ songArtists }}, ({{ activeSongYear
                    }})</small></span>
              </div>
            </div>

            <div class="__other_options text-end">
              <div class="__volume_option clickable">
                <input type="range" id="volume" name="volume" min="0" max="1" step="0.05" v-model="songVolume" />
                <i class="bi" :class="[
      songMuted ? 'bi-volume-mute-fill' : 'bi-volume-up-fill',
    ]
      "></i>
              </div>
              <div class="__repeat_option clickable" @click="enableOrDisableRepeat">
                <i class="bi bi-repeat" :class="repeat ? 'enabled' : 'disabled'"></i>
              </div>
              <div class="__shuffle_option clickable" @click="enableOrDisableShuffle">
                <i class="bi bi-shuffle" :class="shuffled ? 'enabled' : 'disabled'"></i>
              </div>
              <div class="__expand_icon_option clickable">
                <i :class="[
      showIcon ? 'bi-caret-up-fill' : 'bi-caret-down-fill',
    ]
      " @click="clickedExpandIcon" class="bi"></i>
              </div>
            </div>
          </div>
        </div> -->



      <!-- </div> -->

      <div @click="expandPlayer">
        <h6 class="text-truncate text-capitalize fs-5 m-0" style="max-width: 400px">
          {{ songTitle }}
        </h6>
        <span class="text-truncate fs-6" style="max-width: 400px"><small>{{ songArtists }}, ({{ activeSongYear
            }})</small></span>
      </div>
      <div @click="this.$emit('toggle-play', '')" class="clickable">
        <i class="bi" style="font-size: 40px;" :class="[playingStatus ? 'bi-pause-fill' : 'bi-play-fill']"></i>
      </div>
    </div>

  </div>

  <!-- Report Modal -->
  <PopUpModal :title="'Report song'" :showModal="showReportModal" @close="updateReportModalStatus(false)">
    <template #body>
      <ReportSong :song="songDetails" />
    </template>
  </PopUpModal>

</template>

<script>
// import { useMusicPlayer } from "@/hooks/useMusicPlayer";
import MusicListItem from "./MusicListItem.vue";
import musicMixins from "@/mixins/musicMixins";
import songActionsMixins from "@/mixins/songActionsMixins"
import PopUpModal from "../Miscellaneous/PopUpModal.vue";
import Alert from "@/components/Miscellaneous/Alert.vue";
import SubmitButton from "@/components/Miscellaneous/SubmitButton.vue";
import ratingMixins from "../../mixins/ratingMixins";
import menuChangeMixins from "@/mixins/menuChangeMixins";
import moment from "moment";
import { ref } from "vue";
import ReportSong from "./ReportSong.vue";

let seekStatus = ref(false);

export default {
  name: "MusicPlayerMobileView",
  components: {
    MusicListItem,
    PopUpModal,
    Alert,
    SubmitButton,
    ReportSong

  },
  mixins: [musicMixins, ratingMixins, menuChangeMixins, songActionsMixins],
  data() {
    return {
      showIcon: false,
      showExpandedView: false,
      expandedViewClasses: ["animate__fadeInUp"],
      tab: null,
      // showReportModal: false,
      loadButton: false,
      alertDuration: 3,
      activeSong: this.song,
      reportReason: "",
      songLyrics: "",
      showRatingStar: false,
      rating: 0,
      ratingStarDivAnimationClass: ["animate__bounceIn"],
      songVolume: 1,
      songMuted: false,
      seekTo: 0,
      // songDurationValue: 0,
      shuffled: false,
      repeat: false,
      songToReportDetails: {
        id: null,
        name: "name",
        artists: "artists"
      },
      model: false,
      showDetails: false,
      collapseDetails: false,
      showPlayOrPauseIcon: false,
    };
  },
  props: {
    songTitle: {
      type: String,
      default: "song title",
    },
    songArtists: {
      type: String,
      default: "artists",
    },
    playingStatus: {
      type: Boolean,
      default: false,
    },
    newSongStatus: {
      type: Boolean,
      default: false,
    },
    song: {
      type: Object,
      default: {},
    },
    playlist: {
      type: Array,
      default: [],
    },
    songProgress: {
      type: Number,
      default: 0,
    },
    songUpdatedTime: {
      type: String,
      default: "00:00",
    },
    songDuration: {
      type: String,
      default: "00:00",
    },
    songLoaded: {
      type: Object,
      default: false
    }
  },
  methods: {
    clickedExpandIcon() {
      if (this.showIcon) {
        // Open expanded view - change the class
        this.expandedViewClasses.splice(
          this.expandedViewClasses.indexOf("animate__fadeOutDown"),
          1,
          "animate__fadeInUp"
        );

        console.log("open: ", this.expandedViewClasses);
        this.showIcon = false;
      } else {
        console.log(
          "index",
          this.expandedViewClasses.indexOf("animate__fadeInUp")
        );
        // Close expanded view - change the class
        this.expandedViewClasses.splice(
          this.expandedViewClasses.indexOf("animate__fadeInUp"),
          1,
          "animate__fadeOutDown"
        );
        console.log("close: ", this.expandedViewClasses);
        this.showIcon = true;
        this.showDetails = false;
      }
    },
    async getSongLyrics() {
      this.songLyrics = await this.$store.dispatch(
        "song/getSongLyrics",
        this.activeSong.id
      );
    },

    expandPlayer() {
      if (this.showDetails) {
        this.showDetails = false;
        this.collapseDetails = true;
      }
      else {
        this.clickedExpandIcon()
      }
    },
    // async reportSong(e) {
    //   e.preventDefault();

    //   this.loadButton = true;

    //   console.log("songToReportDetails: ", this.songToReportDetails);

    //   let payload = {
    //     data: {
    //       reason: this.reportReason,
    //     },
    //     id: this.songToReportDetails.id,
    //   };

    //   let response = await this.$store.dispatch("song/flagSong", payload);
    //   if (response.success) {
    //     this.$refs.alert.show("success", response.message);
    //   } else {
    //     this.$refs.alert.show("error", response.message);
    //   }
    // },
    // onComplete() {
    //   this.showReportModal = false;
    // },
    showRatingStarDiv() {
      // Check if the user is logged in
      if (this.$store.getters["auth/user_exists"]) {
        // If yes show star div
        this.showRatingStar = true;
      } else {
        // Redirect to login page
        this.$router.push({ name: "login" });
      }
    },

    async rateSong() {
      // Rate song
      let payload = {
        user_id: this.$store.getters["user/user"].id,
        song_id: this.activeSong.id,
        rating_value: this.rating,
      };
      // let response = await this.$store.dispatch("rating/rateSong", payload);
      let response = await this.rateTheSong(payload);

      if (response.success) {
        this.$refs.ratingRef.show("success", response.message);
      } else {
        this.$refs.ratingRef.show("error", response.message);
      }
    },
    setProgress() {
      console.log(this.seekTo);
      this.$emit("seek-to", this.seekTo);
    },
    enableOrDisableShuffle() {
      this.shuffled = !this.shuffled;

      // Logic: cannot enable both shuffle and repeat at the same time so if on enabling shuffle, if repeat is already enabled then disable it and viceversa
      if (this.shuffled && this.repeat) this.repeat = false;

      this.$emit("shuffle-song", this.shuffled);
    },
    enableOrDisableRepeat() {
      this.repeat = !this.repeat;

      // Logic: cannot enable both shuffle and repeat at the same time so if on enabling shuffle, if repeat is already enabled then disable it and viceversa
      if (this.shuffled && this.repeat) this.shuffled = false;

      this.$emit("repeat-song", this.repeat);
    },
    updateSeekStatus() {
      // This will be called from parent component if the repeat option is enabled
      seekStatus.value = false;
    },
    // doAction(data) {
    //   if (data.action == "report") {
    //     // Get the active playlist
    //     let activePlaylist = this.getActivePlaylist;
    //     // console.log("active playlist", activePlaylist);

    //     // Get the song of id in data
    //     let song = activePlaylist.filter((song) => song.id === data.song_id);
    //     // console.log("song details", song);
    //     this.songToReportDetails.id = data.song_id;
    //     this.songToReportDetails.name = song[0].name;
    //     this.songToReportDetails.artists = song[0].artists.map(artist => artist.name).join(",");

    //     this.showReportModal = true;
    //   }
    // },

    viewDetails() {
      this.showDetails = true;
      this.collapseDetails = false;
    },

    thumbnailclicked() {
      this.$emit('toggle-play', '');
      this.showPlayOrPauseIcon = true;
      setTimeout(() => {
        this.showPlayOrPauseIcon = false;
      }, 700)
    }

  },
  computed: {
    userExist() {
      return this.$store.getters["auth/user_exists"];
    },
    songLyricsStatus() {
      // true - means disable lyrics tab
      // false - vice versa

      // console.log("lyrics: ", this.activeSong.lyrics_file);
      return this.activeSong.lyrics_file == null ? true : false;
    },
    activeSongYear() {
      return moment(this.activeSong.released_on).year();
    },
    songReleaseDate() {
      return moment(this.activeSong.released_on).format("DD/MM/YYYY");
    },
  },
  watch: {
    activeSong() {
      // watch active songs
      // If song changes then it show expanded music page - add show class
      if (this.expandedViewClasses.includes("animate__fadeOutDown")) {
        // this means that the expanded view is in closed state so replace class
        this.expandedViewClasses.splice(
          this.expandedViewClasses.indexOf("animate__fadeOutDown"),
          1,
          "animate__fadeInUp"
        );
        // Change show icon to false
        this.showIcon = false;
      }
    },
    song(value) {
      // when song changes set seekStatus to false
      seekStatus.value = false;
      this.activeSong = value;
    },
    change(value) {
      alert(value);
    },
    $route() {
      // When route value changes if the music details page is diaplayed then close it
      if (this.expandedViewClasses.includes("animate__fadeInUp")) {
        // Close expanded view - change the class
        this.expandedViewClasses.splice(
          this.expandedViewClasses.indexOf("animate__fadeInUp"),
          1,
          "animate__fadeOutDown"
        );
        console.log("close: ", this.expandedViewClasses);
        this.showIcon = true;
      }
    },
    // Watch song volume and update it
    songVolume(value) {
      value == 0 ? (this.songMuted = true) : (this.songMuted = false);
      //Update volume
      this.$emit("change-volume", value);
    },
    songProgress(value) {
      // console.log("progress: ", value);
      // const sliderEl = document.querySelector(".seek_slider");
      this.seekTo = value;
      // console.log("watch: ",this.seekTo);

      // sliderEl.style.background = `linear-gradient(to right, #f50 ${value}%, #ccc ${value}%)`;
    },
    seekTo(value) {
      if (this.activeSong.duration - value < 3 && !seekStatus.value) {
        seekStatus.value = true;
        // alert("trigger");
        this.$emit("song-ended", "");
      }
    },
    model(value) {
      if (value) {
        // Enable playlist
        this.setPlayList('enable_autoplay_playlist')
      }
      else {
        // Disable playlist
        this.setPlayList('disable_autoplay_playlist')
      }
    }
  },
};
</script>

<style scoped>
/* .music_player_mobile_view_wrapper {
  position: fixed;
  display: none;
  width: 100%;
  bottom: 0;
  height: 80px;
  background: var(--support_color);
  z-index: 5;
} */

.music_player_mobile_view_wrapper {
  display: none;
}

.music_player_mobile_view_container {
  position: fixed;
  width: 100%;
  bottom: 0;
  height: 80px;
  background: var(--dominant-color);
  z-index: 1;
  color: var(--color);
  border-top: 1px solid var(--color);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-inline: 30px;
}

.music_player_mobile_view_container.top {
  top: 0;
  z-index: 5;
  border-top: none;

}

.expanded-area-mobile {
  width: 100%;
  /* padding-inline: 25px; */
}

.close-expanded-view-icon {
  position: absolute;
  z-index: 4;
  left: 30px;
  top: 30px;
}

.close-expanded-view-icon i {
  color: var(--color);
}

.expanded-view {
  position: fixed;
  bottom: 0;
  background: var(--dominant-color);
  width: 100%;
  height: 100%;
  z-index: 5;
}

.music-thumbnail {
  max-width: 100%;
  height: 300px;
  position: relative;
}

.music-thumbnail .rating-button-div {
  position: absolute;
  bottom: 2%;
  right: 2%;
  z-index: 3;
}

.music-thumbnail .rating-star-div,
.music-thumbnail .play-or-pause {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1em;
}

.music-thumbnail .rating-star-div {
  background: #452964ce;
  z-index: 3;
}

.music-thumbnail img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.close-rating-star-div {
  position: absolute;
  top: 2%;
  right: 2%;
  color: white;
  cursor: pointer;
}

.close-rating-star-div i {
  font-size: 20px;
}

.music-controller-mobile .progress-bar {
  max-width: 100%;
  margin: auto;
  padding: 5px;
}

.music-controller-mobile .progress-bar .v-input {
  height: 30px;
}

.song-time {
  font-size: 12px;
  color: var(--color);
}

.__shuffle_option_mobile i,
.__repeat_option_mobile i {
  font-size: 18px;
}

.__shuffle_option_mobile .disabled,
.__repeat_option_mobile .disabled {
  color: gray;
}

.__shuffle_option_mobile .enabled,
.__repeat_option_mobile .enabled {
  color: var(--color);
}


.music-list-div-mobile {
  max-height: calc(100vh - 33vh);
  display: flex;
  gap: 1em;
  flex-direction: column;
  overflow-y: scroll;
}

.lyrics-container-mobile {
  /* height: 600px; */
  max-height: calc(100vh - 30vh);
  overflow-y: scroll;
}



.details-section {
  position: absolute;
  top: calc(100vh - 7vh);
  left: 0;
  right: 0;
  height: calc(100vh - 9vh);
  background-color: var(--dominant-color);
  z-index: 4;
}

.details-section .tabs {
  color: var(--color);
}

.details-section .tab-disabled {
  color: gray;
}

.details-section.expand {
  position: absolute;
  top: 80px;
  left: 0;
  right: 0;
  background-color: rgb(255, 255, 255);
}

.music-mobile-container {
  display: grid;
  grid-template-columns: 1fr;
  height: calc(100vh - 10vh);
  padding-top: 10vh;
  padding-left: 7vw;
  padding-right: 7vw;
}

/* .details-section.collapse {
  position: absolute;
  top: 104%;
  left: 0;
  right: 0;
}

.details-section.expand {
  position: absolute;
  top: 30%;
  left: 0;
  right: 0;
} */

/* .v-slider-track {
  --v-slider-track-size: 2px;
} */

@media (max-width: 768px) {
  .music_player_mobile_view_wrapper {
    display: block;
  }
}

@media (max-height: 670px) {
  .music-list-div-mobile {
    max-height: calc(100vh - 40vh);
  }

  .details-section {
    top: calc(100vh - 7.5vh);
  }
}

/* @media (min-width: 356px) and (max-width: 419px) {
  .music-thumbnail {
    max-width: 250px;
    height: 250px;
  }

  .expanded-view .expanded-area-mobile {
    padding-top: 80px;
  }
} */
</style>