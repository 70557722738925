<template>
    <!-- Report Modal -->
    <Alert :duration="alertDuration" ref="alert" @completed="onComplete" />

    <div class="song-details text-capitalize">
        <strong class="song-name">{{ song.name }}</strong><br />
        <small>Artists:
            {{
        song.artists.map(artist => artist.name).join(",")
    }}</small>
        <div class="mt-5">
            <small>You may need to provide a reason for reporting the song, such as
                inappropriate content or copyright issues. Reporting a song helps
                maintain the quality and safety of the platform for all
                users.</small>
        </div>
    </div>

    <form @submit="reportSong">
        <textarea class="form-control" name="report_reason" id="report_reason_textarea"
            placeholder="Write your reason here (Optional)" cols="30" rows="10" v-model="reportReason"></textarea>

        <div class="mt-5 text-end">
            <SubmitButton buttonText="report" :load="loadButton" />
        </div>
    </form>
</template>

<script>

import Alert from "@/components/Miscellaneous/Alert.vue"
import SubmitButton from "@/components/Miscellaneous/SubmitButton.vue"
import songActionsMixins from "@/mixins/songActionsMixins"
import song from "@/store/modules/song"

export default {
    name: "Report Song",
    mixins: [songActionsMixins],
    components: {
        Alert,
        SubmitButton,
    },
    data() {
        return {
            loadButton: false,
            alertDuration: 3,
            reportReason: ""
        }
    },
    props: {
        song: {
            type: Object,
            default: {}
        }
    },
    methods: {
        async reportSong(e) {
            e.preventDefault();

            this.loadButton = true;

            console.log("songToReportDetails: ", this.song);

            let payload = {
                data: {
                    reason: this.reportReason,
                },
                id: this.song.id,
            };

            let response = await this.$store.dispatch("song/flagSong", payload);
            if (response.success) {
                this.$refs.alert.show("success", response.message);
            } else {
                this.$refs.alert.show("error", response.message);
            }
        },
        onComplete(status) {
            if (status) {
                this.updateReportModalStatus(false);
                this.reportReason = ""
            }
        }
    },
}
</script>