<template>
  <div id="music-player-bottom" v-if="showMusicPlayer">
    <div class="music-player-progress-bar">
      <div @click="setProgress" class="song-progress-container">
        <div class="time-tooltip"></div>
        <div class="song-progress"></div>
      </div>
    </div>
    <audio :src="changeSongData.songUrl" id="audio" hidden></audio>
    <div class="music-player-bottom-wrapper">
      <div class="song-details">
        <div class="image">
          <div class="middle-circle"></div>
          <img src="https://cdn.pixabay.com/photo/2015/01/20/13/13/samsung-605439_1280.jpg"
            :class="isPlaying ? 'play' : ''" alt="" srcset="" />
        </div>
        <div class="details">
          <div class="song-name text-overflow">{{ changeSongData.songName }}</div>
          <div class="song-singers">{{ changeSongData.songGenre }}</div>
        </div>
      </div>
      <div class="player-controls">
        <div @click="shuffleSong = !shuffleSong" :class="[shuffleSong ? 'enabled' : '']" class="shuffle">
          <i class="bi bi-shuffle"></i>
        </div>
        <div class="rewind" @click="prevSong">
          <i class="bi bi-rewind-circle"></i>
        </div>
        <div @click="playSong" class="play">
          <i :class="[isPlaying ? 'bi-pause-circle' : 'bi-play-circle']" class="bi"></i>
        </div>
        <div class="forward" @click="nextSong">
          <i class="bi bi-fast-forward-circle"></i>
        </div>
        <div @click="repeatSong = !repeatSong" :class="[repeatSong ? 'enabled' : '']" class="repeat">
          <i class="bi bi-arrow-repeat"></i>
        </div>
      </div>
      <div class="player-options">
        <div class="play-time">{{ songCurrentTime }}/{{ songFinalTime }}</div>
        <div class="options"><i class="bi bi-three-dots"></i></div>
        <div class="volume">
          <i :class="[songMuted ? 'bi-volume-mute' : 'bi-volume-up']" class="bi"></i>

          <div class="volume-controller-container">
            <div class="volume-controller">
              <input type="range" id="volume" name="volume" min="0" max="1" step="0.05" v-model="songVolume" />
            </div>
          </div>
        </div>
        <div class="expand">
          <i :class="[
            showMusicExpandedPage
              ? 'bi-arrows-angle-contract'
              : 'bi-arrows-angle-expand',
          ]" class="bi"></i>
        </div>
      </div>
    </div>
  </div>

  <!-- <MusicPageExpanded :aria-expanded="showExpandedPage" :songName="currentSong.songName"
    :songArtists="currentSong.songArtists" @close-page="closePage" :class="[showExpandedPage ? 'animate__slideInUp' : 'animate__slideOutDown']" /> -->

  <!-- <MobileMiniController @show-expanded-page="showMobileExpandedPage" :songName="currentSong.songName"
    :artists="currentSong.songArtists" :isPlaying="isPlaying" @play-song="playSong" /> -->
</template>

<script>
import MusicPageExpanded from "./MusicPageExpanded.vue";
import MobileMiniController from "./MobileMiniController.vue";

let audioFile = "";

export default {
  data() {
    return {
      isPlaying: false,
      repeatSong: false,
      shuffleSong: false,
      songCurrentTime: "0:00",
      songDuration: "0:00",
      showVolumeController: false,
      songVolume: 0.5,
      songMuted: false,
      songFile: "",
      currentSong: {},
      showMusicExpandedPage: false,
      showPlayer: true,
    };
  },
  props: {
    mobileView: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    async playSong() {
      //
      if (this.currentSong.songUrl != undefined) {
        // Set isPlaying value to true or false
        this.isPlaying = !this.isPlaying;
        // play audio
        if (this.isPlaying) {
          audio.play();
        } else {
          audio.pause();
        }

        let minutes = "0" + Math.floor(audio.duration / 60);
        let seconds = "0" + Math.floor(audio.duration - minutes * 60);
        this.songDuration = minutes.slice(-2) + ":" + seconds.slice(-2);

        // progress container
        // progress bar
        let progress_bar = document.querySelector(".song-progress");

        //   Update song progress
        audio.addEventListener("timeupdate", (event) => {
          const { duration, currentTime } = event.target;
          const progressPercent = (currentTime / duration) * 100;
          progress_bar.style.width = `${progressPercent}%`;
        });
      }
    },

    updateCurrentSongTime() {
      audio.addEventListener("timeupdate", (event) => {
        const { currentTime } = event.target;
        let minutes = "0" + Math.floor(currentTime / 60);
        let seconds = "0" + Math.floor(currentTime - minutes * 60);
        this.songCurrentTime = minutes.slice(-2) + ":" + seconds.slice(-2);
      });
    },

    setProgress(event) {
      if (this.songDuration != "0:00") {
        // progress bar
        let progress_bar = document.querySelector(".song-progress");
        let progress_container = document.querySelector(
          ".song-progress-container"
        );

        //   Set song progress on click
        const width = progress_container.clientWidth;
        const clickX = event.offsetX;
        const duration = audio.duration;
        let currentMusicTime = (clickX / width) * duration;
        // Set progress
        const progressPercent = (currentMusicTime / duration) * 100;
        progress_bar.style.width = `${progressPercent}%`;

        //   Set audio current time
        audio.currentTime = currentMusicTime;
      }
    },
    setInitialSongVolume() {
      audio.volume = this.songVolume;
    },
    nextSong() {
      this.$store.dispatch("song/getNextSong", this.currentSong.songId);
    },
    prevSong() {
      this.$store.dispatch("song/getPreviousSong", this.currentSong.songId);
    },
    shuffledSong() {
      this.$store.dispatch("song/getShuffledSong", this.currentSong.songId);
    },
    showMobileExpandedPage() {
      this.showMusicExpandedPage = !this.showMusicExpandedPage;
      document.querySelector("#music-player-bottom").style.visibility = "visible";
    },
    closePage() {
      this.showMusicExpandedPage = false;
      document.querySelector("#music-player-bottom").style.visibility = "hidden";
    }
  },
  computed: {
    changeSongData() {
      let song = this.$store.getters["song/song"];
      console.log("current song: ", song);
      this.songFile = song.file_url;
      let songData = {
        songId: song.id,
        songName: song.name,
        songUrl: song.file_url,
        songGenre: song.genre,
        songArtists: song.artists,
      };
      this.currentSong = songData;
      return songData;
    },
    setSongFile() {
      audioFile = document.querySelector("#audio").src;
      let audio = new Audio(audioFile);
      return audio;
    },
    songFinalTime() {
      let song = this.$store.getters["song/song"];

      // alert(audio.duration)
      if (Object.keys(song).length == 0) {
        // If song object is empty
        return "0:00";
      } else {
        let existingAudio = new Audio(song.file_url);

        existingAudio.onloadedmetadata = () => {
          this.songDuration = existingAudio.duration;
          // console.log("duration: ", duration);
        };

        let minutes = "0" + Math.floor(this.songDuration / 60);
        let seconds = "0" + Math.floor(this.songDuration - minutes * 60);
        return minutes.slice(-2) + ":" + seconds.slice(-2);
      }
    },
    showExpandedPage() {
      return this.showMusicExpandedPage;
    },
    showMusicPlayer() {
      return this.showPlayer
    }
  },
  mounted() {
    this.updateCurrentSongTime();
    this.setInitialSongVolume();
    window.addEventListener("resize", () => {
      if(innerWidth < 992 && !this.showMusicExpandedPage) {
        document.querySelector("#music-player-bottom").style.visibility = "hidden";
      }
      else {
        document.querySelector("#music-player-bottom").style.visibility = "visible";
      }
    })
  },

  watch: {
    songVolume(value) {
      value == 0 ? (this.songMuted = true) : (this.songMuted = false);
      audio.volume = parseFloat(value);
    },
    currentSong(data) {
      audioFile = data.songUrl;
      console.log("current song file: ", audioFile);

      // alert(this.$store.getters["song/playorPauseController"])

      if (audioFile != undefined) {
        console.log("playing: ", this.isPlaying);
        // If an audio is already playing then pause it and play the selected song
        if (this.isPlaying) {
          audio.pause();
          audio.currentTime = 0;
          audio.play();

        } else if (this.$store.getters["song/playorPauseController"]) {
          this.playSong();
        }

      } else {
        this.isPlaying = false;
      }
    },
    songCurrentTime(value) {
      // play next song
      if (Math.trunc(audio.duration) - 1 == Math.trunc(audio.currentTime)) {
        // Repeat song
        if (this.repeatSong) {
          audio.currentTime = 0;
          audio.play();
        } else if (this.shuffleSong) {
          audio.addEventListener("ended", this.shuffledSong());
        } else {
          audio.addEventListener("ended", this.nextSong());
        }
      }
    }
  },
  components: {
    MusicPageExpanded,
    MobileMiniController
  },
};

</script>

<style scoped>
.music-player-progress-bar {
  height: 5px;
  position: fixed;
  bottom: 100px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999998;
}

.music-player-progress-bar .song-progress-container {
  background: var(--dominant_color);
  width: 500px;
  height: 100%;
  border-radius: 50px;
  cursor: pointer;
  position: relative;
}

.music-player-progress-bar .song-progress-container .song-progress {
  background-color: var(--accent_color);
  height: 100%;
  width: 0%;
  border-radius: 50px;
  transition: width 0.1s linear;
  position: relative;
  padding-left: 0px;
}

/* .song-progress:after {
  position: absolute;
  content: "";
  width: 10px;
  height: 5px;
  border-radius: 15px;
  transition: width 0.1s linear;
  background: var(--accent_color);
  border: 2px solid var(--support_color);
  right: 0;
  top: -50%;
  z-index: 1;
} */

.music-player-bottom-wrapper {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding-inline: 30px;
  height: var(--music-player-bottom-height);
  background: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 999999;
}

.music-player-bottom-wrapper>* {
  width: 100%;
}

.music-player-bottom-wrapper>*:nth-child(1) {
  justify-content: start;
}

.music-player-bottom-wrapper>*:nth-child(2) {
  justify-content: center;
}

.music-player-bottom-wrapper>*:nth-child(3) {
  justify-content: end;
}

.music-player-bottom-wrapper .player-controls,
.music-player-bottom-wrapper .player-options,
.music-player-bottom-wrapper .song-details {
  display: flex;
  gap: 1.5em;
  align-items: center;
}

.player-controls>*,
.player-options>* {
  cursor: pointer;
}

.player-options>*:not(:first-child):hover {
  color: #7b7b7b;
}

.player-options .volume {
  position: relative;
}

.player-options .volume .volume-controller-container {
  position: absolute;
  z-index: 2;
  width: 150px;
  height: 30px;
  padding: 20px;
  background: rgb(255, 255, 255);
  -webkit-box-shadow: 1px 1px 14px -4px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 1px 1px 14px -4px rgba(0, 0, 0, 0.75);
  box-shadow: 1px 1px 14px -4px rgba(0, 0, 0, 0.75);
  bottom: 100%;
  right: -200%;
  border-radius: 15px;
  display: none;
}

.volume-controller-container .volume-controller {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.player-options .volume:hover .volume-controller-container {
  display: block;
}

.player-controls>*:first-child {
  margin-right: 20px;
}

.player-controls>*:first-child,
.player-controls>*:last-child {
  font-size: 24px;
  color: #adadad;
}

.player-controls>*:last-child {
  margin-left: 20px;
}

.player-controls .repeat.enabled,
.player-controls .shuffle.enabled {
  color: var(--support_color);
  text-shadow: 0 0 1px var(--dominant_color);
}

.player-controls .forward,
.player-controls .play,
.player-controls .rewind {
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--dominant_color_active);
  background: linear-gradient(145deg, #f2f2f2, #dadada);
  box-shadow: 5px 5px 10px #b8b8b8, -5px -5px 10px #ffffff;
}

.player-controls .forward,
.player-controls .rewind {
  font-size: 28px;
  width: 45px;
  height: 45px;
}

.player-controls .play {
  font-size: 36px;
  width: 60px;
  height: 60px;
}

.player-controls .play:active,
.player-controls .forward:active,
.player-controls .rewind:active {
  border-radius: 50%;
  background: linear-gradient(145deg, #cacaca, #f0f0f0);
  box-shadow: 5px 5px 10px #b8b8b8, -5px -5px 10px #ffffff;
}

.player-controls .play:hover,
.player-controls .forward:hover,
.player-controls .rewind:hover,
.player-controls>*:first-child:hover,
.player-controls>*:last-child:hover {
  color: var(--support_color);
}

.music-player-bottom-wrapper .song-details .image {
  position: relative;
}

.music-player-bottom-wrapper .song-details .image .middle-circle {
  position: absolute;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: white;
  /* left: 0;
    top: 0;
    bottom: 0;
    right: 0; */
  inset: 0 0 0 0;
  margin: auto;
  z-index: 1;
}

.music-player-bottom-wrapper .song-details .image img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: cover;

  animation: rotate 3s linear infinite;
  animation-play-state: paused;
}

.music-player-bottom-wrapper .song-details .image img.play {
  animation-play-state: running;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.music-player-bottom-wrapper .song-details .details .song-name {
  font-size: 14px;
  font-weight: bold;
  color: var(--support_color);
  width: 180px;
}

.music-player-bottom-wrapper .song-details .details .song-singers {
  font-size: 12px;
  font-weight: 600;
  color: var(--dominant_color);
}

.music-player-bottom-wrapper .player-options .play-time {
  font-size: 14px;
}

.music-player-bottom-wrapper .player-options .options,
.music-player-bottom-wrapper .player-options .volume {
  font-size: 28px;
}

.music-player-bottom-wrapper .player-options .expand {
  font-size: 24px;
}

.music-player-bottom-wrapper .song-progress-container {
  height: 2px;
}

@media screen and (max-width: 992px) {

  .music-player-bottom-wrapper .song-details,
  .music-player-bottom-wrapper .player-options {
    display: none;
  }

  .player-controls .forward,
  .player-controls .rewind {
    height: 35px;
  }

  .player-controls .play {
    height: 45px;
  }

  #music-player-bottom {
    visibility: hidden;
  }

}
</style>