
// Api services
import apiServices from "@/services/apiServices";

let _path = "/user"
// user.js
export default {
    namespaced: true,
    state: {
        // user state here
        users: [],
        user: null,
        menu_link: "/",
        theme: 0 /** 0 - dark ; 1 - light */
    },
    mutations: {
        // user mutations here
        getUsers: (state, users) => (state.users = users),
        getUser: (state, user) => (state.user = user),
        SET_USER: (state, user) => (state.user = user),
        registerUser: (state, newUser) => state.users.unshift(newUser),
        loginUser: (state, user) => (state.user = user),
        updateUser: (state, userData) => {
            const index = state.users.findIndex(user => user.id === userData.id);
            if (index !== -1) {
                state.users.splice(index, 1, userData)
            }
        },
        deleteuser: (state, id) => (state.users = state.users.findIndex(user => user.id !== id)),
        setMenu: (state, link) => (state.menu_link = link),
        setTheme: (state, theme) => (state.theme = theme),
    },
    actions: {
        // user actions here

        // Get users
        async getUsers({ commit }) {
            console.log("token: ", sessionStorage.getItem("token"));
            const users = apiServices.get(_path + "/users");
            commit("getUsers", users);
        },

        // Get user
        async getUser({ commit }, id) {
            const user = await apiServices.get(_path + "/users/" + id);
            commit("getUser", user);
        },

        // Get User Details
        async getUserDetails({ commit, dispatch }) {
            try {
                const user = await apiServices.get(_path + "/users/details");
                console.log(user);
                commit("SET_USER", user.data);
                return {
                    success: true,
                    message: "Fetched user details"
                }
            } catch (error) {
                console.log("error: ", error);
                if(error.response.status == 401) dispatch("auth/logout", null, {root: true})
                return {
                    success: false,
                    message: "Internal error",
                    error: error
                }
            }
        },

        // Register user
        async registerUser({ commit }, payload) {
            const newUser = apiServices.post(_path + "/register", payload);
            commit("registerUser", newUser)
        },

        // Login user
        async loginUser({ commit }, payload) {
            const user = apiServices.post(_path + "/login", payload);
            commit("loginUser", user)
        },

        // Update user
        async updateUser({ commit }, payload) {
            const user = apiServices.put(_path + "/update/" + payload.id)
            commit("updateUser", user)
        },

        // Delete user
        async deleteuser({ commit }, id) {
            const response = apiServices.delete(_path + "/users/" + id);
            commit("deleteUser", id);
        },

        // Send request to become creator
        async requestCreatorRole({ commit }) {

            try {
                let response = await apiServices.post(_path + "/request-creator-role");
                console.log("response :", response);
                return {
                    success: true,
                    message: "Successfully sent request"
                }
            }
            catch (error) {
                console.log("error :", error);
                return {
                    success: false,
                    message: "Failed to send request",
                    error: error
                }
            }

        },

        async updateDetails({ commit }, payload) {
            try {
                console.log("details: ", payload);
                await apiServices.put(_path + "/update/" + payload.id, payload);
                return {
                    success: true,
                    message: "Updated details successfully"
                }
            } catch (error) {
                return {
                    success: false,
                    message: "Failed to update details",
                    error: error
                }
            }
        }
    },
    getters: {
        // user getters here
        allUsers: state => state.users,
        user: state => state.user,
        theme: state => state.theme
    }
};