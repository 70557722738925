import { createStore } from 'vuex'
import VuexPersistence from 'vuex-persist'

//Import modules 

import admin from './modules/admin'
import album from './modules/album'
import artist from './modules/artist'
import auth from './modules/auth'
import flag from './modules/flag'
import history from './modules/history'
import playlist from './modules/playlist'
import rating from './modules/rating'
import song from './modules/song'
import trend from './modules/trend'
import user from './modules/user'
import music from './modules/music'

const vuexLocalStorage = new VuexPersistence({
  key: "vuex",
  storage: window.localStorage, // storage
  // Function that passes the state and returns the state with only the objects you want to store.
  reducer: (state) => ({
    music: {
      musics: state.music["musics"]
    },
    song: {
      songs: state.song["songs"],
      song: state.song["song"]
    },
    album: {
      albums: state.album["albums"]
    },
    auth: {
      user_status: state.auth["user_status"],
      admin_logged_in: state.auth["admin_logged_in"],
      currentUser: state.auth["currentUser"]
    },
    user: {
      user: state.user["user"],
      menu_link: state.user["menu_link"],
      theme: state.user["theme"]
    },
    playlist: {
      playlists: state.playlist["playlists"],
    },
    artist: {
      artists: state.artist["artists"]
    }
  }),
  // Function that passes a mutation and lets you decide if it should update the state in localStorage.
  // filter: mutation => (true)
})

export default createStore({
  // state: {
    
  // },
  // getters: {

  // },
  // mutations: {
  //   // Synchronous state mutations
  // },
  // actions: {
  //   // Asynchronous actions
  // },

  plugins: [vuexLocalStorage.plugin],

  modules: {
    // Modularize store if needed
    auth,
    user,
    song,
    album,
    artist,
    playlist,
    rating,
    flag,
    trend,
    admin,
    history,
    music
  }

})

// import Vuex from "vuex";
// import admin from './modules/admin'
// import album from './modules/album'
// import artist from './modules/artist'
// import auth from './modules/auth'
// import flag from './modules/flag'
// import history from './modules/history'
// import playlist from './modules/playlist'
// import rating from './modules/rating'
// import song from './modules/song'
// import trend from './modules/trend'
// import user from './modules/user'

// // Create store
// export default new Vuex.Store({
//   modules: {
//     auth,
//     user,
//     song,
//     album,
//     artist,
//     playlist,
//     rating,
//     flag,
//     trend,
//     admin,
//     history
//   }
// })