<template>
  <footer>
    <hr>
    <h5>Music App</h5>
    <h5>New releases</h5>
  </footer>
</template>

<script>
export default {};
</script>

<style scoped>
footer {
  padding: 30px 20px;
  /* background: white; */
}
</style>