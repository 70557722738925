// trend.js
export default {
    state: {
        // trend state here
    },
    mutations: {
        // trend mutations here
    },
    actions: {
        // trend actions here
    },
    getters: {
        // trend getters here
    }
};